import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Fragment, useCallback, useEffect, useState } from "react";
import { CurveSelectionDialog } from ".";
import { sumNumbers } from "../../../utilities/objectUtilities";
import { capitalizeFirstLetter } from "../../../utilities/textUtilities";
import { requiredValidator } from "../../../validators";
import { FormNumericTextBox } from "../../form";
import InfoIconAndTooltip from "../../utilities/InfoIconAndTooltip";
import InsuranceUtility from "./insuranceUtilities";

interface IInsuranceVolumesProps {
  formRenderProps: FormRenderProps;
  product: any;
  allocations: any[];
}

export const Volumes = ({ formRenderProps, product, allocations }: IInsuranceVolumesProps) => {
  const { commodity } = product;
  const units = commodity?.units;

  const volume = formRenderProps.valueGetter("totalVolume");
  const coverage = formRenderProps.valueGetter("totalCoverage");
  const selectedAllocation = formRenderProps.valueGetter("allocation") || 
    allocations.findIndex((a) => a.allocationId === 1);
  const months = InsuranceUtility.getCoverageData(formRenderProps);
  const [showCurveDialog, setShowCurveDialog] = useState(false);

  const allocateValues = (total: number, index: number) => {
    if (allocations.length === 0 || months.length === 0) return [];

    const allocs = months.map((m) => allocations[index].values[m.value.toString()] || 0);
    const denom = sumNumbers(allocs);
    const ratios = allocs.map((v) => (denom === 0 ? 0 : v / denom));
    const values = ratios.map((r) => Math.round(total * r));

    return values;
  };

  const allocatedData: any[] = allocations.map((a: any, i: number) => [
    { name: "Volume", data: allocateValues(volume, i) },
    { name: "Coverage", data: allocateValues(coverage, i) },
  ]);

  useEffect(() => {
    const originalCoverage = formRenderProps.valueGetter("original_totalCoverage");
    if (!originalCoverage) formRenderProps.onChange("original_totalCoverage", { value: coverage });
  }, []);

  // Set the monthly retention values when the user selects an allocation, or changes
  // total volume or coverage
  useEffect(() => {
    setFormFields();
  }, [selectedAllocation, volume, coverage]);

  const onApplyAllocations = (index: number) => {
    setShowCurveDialog(false);
    formRenderProps.onChange("allocation", { value: index });
  };

  const setFormFields = () => {
    if (allocatedData.length === 0 || selectedAllocation < 0) return;
    allocatedData[selectedAllocation].forEach((item: any) => {
      const name = item.name.toLowerCase();
      const values = item.data;
      let total = 0;
      for (let i = 0; i < months.length; i++) {
        total += values[i];
        formRenderProps.onChange(`${name}_${i}`, { value: values[i] });
      }
      if (!isNaN(total)) {
        formRenderProps.onChange(name, { value: total });
        formRenderProps.onChange(getTotalLabel(name), { value: total });
        formRenderProps.onChange(`original_${getTotalLabel(name)}`, { value: total });
      }
    });
  };

  const getTotalLabel = (label: string) => {
    return `total${capitalizeFirstLetter(label)}`;
  };

  const handleValueChange = useCallback(
    (event: any, label: string) => {
      let theValue = event.target.value;
      if (!theValue) {
        theValue = 0;
      }
      let sum = 0;
      for (let i = 0; i < months.length; i++) {
        let name = `${label}_${i}`;
        sum += name === event.target.name ? theValue : formRenderProps.valueGetter(name);
      }

      formRenderProps.onChange(event.target.name, { value: theValue });
      formRenderProps.onChange(getTotalLabel(label), { value: sum });
      formRenderProps.onChange(`original_${getTotalLabel(label)}`, { value: sum });
      formRenderProps.onChange("allocation", { value: -1 });
    },
    [formRenderProps],
  );

  const handleChangeTotal =
    (name: string) =>
    ({ target: { value } }: NumericTextBoxChangeEvent) => {
      if (selectedAllocation === -1) {
        formRenderProps.onChange("allocation", { value: 0 });
      }
      formRenderProps.onChange(name, { value });
    };

  const fieldStyle = { margin: 0 };

  return (
    <div className="stepper-form">
      <div className="float-right">
        <div className="utility-button-container">
          {volume > 0 && coverage > 0 && (
            <div className="utility-button">
              <Button
                size="small"
                icon="align-bottom-element"
                onClick={(e: any) => {
                  e.preventDefault();
                  setShowCurveDialog(true);
                }}
                themeColor="primary"
              />
            </div>
          )}
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <GridLayout align={{ vertical: "middle" }} gap={{ rows: 0, cols: 10 }}>
          <GridLayoutItem col={1} row={2}>
            <Label className="align-items-center" style={fieldStyle}>
              Total Policy
              <InfoIconAndTooltip>
                Values entered for Total Policy Volume and Coverage will be divided equally across
                all months. Total Coverage is often referred to as the{" "}
                <span style={{ fontStyle: "italic" }}>Policy Limit</span>.
              </InfoIconAndTooltip>
            </Label>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={2}>
            <Label style={fieldStyle}>Volume ({units})</Label>
          </GridLayoutItem>
          <GridLayoutItem col={3} row={2}>
            <Label style={fieldStyle}>Coverage / Limit ($)</Label>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={3}>
            <Field
              key={"totalVolume"}
              id={"totalVolume"}
              name={"totalVolume"}
              component={FormNumericTextBox}
              spinners={false}
              validator={requiredValidator}
              format="n0"
              min={0}
              onChange={handleChangeTotal("totalVolume")}
              style={fieldStyle}
            />
          </GridLayoutItem>
          <GridLayoutItem col={3} row={3}>
            <Field
              key={"totalCoverage"}
              id={"totalCoverage"}
              name={"totalCoverage"}
              component={FormNumericTextBox}
              spinners={false}
              validator={requiredValidator}
              format="c0"
              min={0}
              onChange={handleChangeTotal("totalCoverage")}
              style={fieldStyle}
            />
          </GridLayoutItem>
          <GridLayoutItem col={1} row={4}>
            <Label>&nbsp;</Label>
          </GridLayoutItem>

          {volume > 0 && coverage > 0 && (
            <>
              <GridLayoutItem col={1} row={6}>
                <Label style={fieldStyle}>Monthly Policy</Label>
              </GridLayoutItem>
              <GridLayoutItem col={2} row={6}>
                <Label style={fieldStyle}>Volume ({units})</Label>
              </GridLayoutItem>
              <GridLayoutItem col={3} row={6}>
                <Label style={fieldStyle}>Coverage / Limit ($)</Label>
              </GridLayoutItem>

              {months.map((month, i) => (
                <Fragment key={i}>
                  <GridLayoutItem col={1} row={i + 7}>
                    <span className="k-font-weight-bold">{month.label}</span>
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={i + 7} key={`volume_${i}`}>
                    <Field
                      id={`volume_${i}`}
                      name={`volume_${i}`}
                      component={FormNumericTextBox}
                      spinners={false}
                      validator={requiredValidator}
                      onChange={(e: any) => {
                        handleValueChange(e, "volume");
                      }}
                      format="n0"
                      min={0}
                      style={fieldStyle}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={i + 7} key={`coverage_${i}`}>
                    <Field
                      id={`coverage_${i}`}
                      name={`coverage_${i}`}
                      component={FormNumericTextBox}
                      spinners={false}
                      validator={requiredValidator}
                      onChange={(e: any) => {
                        handleValueChange(e, "coverage");
                      }}
                      format="c0"
                      min={0}
                      style={fieldStyle}
                    />
                  </GridLayoutItem>
                </Fragment>
              ))}
            </>
          )}
        </GridLayout>
      </div>

      {showCurveDialog && allocations.length && (
        <CurveSelectionDialog
          title="Allocations"
          chartData={allocatedData}
          selection={selectedAllocation}
          categories={months}
          units={units}
          options={allocations.map((a: any, i: number) => {
            return { label: a.name, value: i };
          })}
          onCancel={() => setShowCurveDialog(false)}
          onApply={onApplyAllocations}
        />
      )}
    </div>
  );
};
