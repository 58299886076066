import { Switch } from "@progress/kendo-react-inputs";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Label } from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CSSProperties, useState } from "react";
import { useUserContext } from "../../../hooks/useUserContext";
import { volumeHint } from "../../../text/coverageSummary";
import DateUtility from "../../../utilities/dateUtilities";
import { sumByProperty } from "../../../utilities/objectUtilities";
import { RoleUtility } from "../../../utilities/rolePermissionUtilities";

type Props = {
  quote: any;
  prices: any[];
  columns: any[];
  units: string;
  companyRole: any;
  isSwap: boolean;
  dragging?: boolean;
  hideUnitPrices?: boolean;
  unitPriceDecimals?: number;
  approvedBrokerPremium?: number | null;
  approvedPremium?: number | null;
  calendarMonthOffset?: number;
};

const VanillaQuoteSummary = ({
  quote,
  prices,
  columns,
  units,
  companyRole,
  isSwap,
  calendarMonthOffset = 0,
  dragging,
  hideUnitPrices,
  unitPriceDecimals: decimals = 2,
  approvedBrokerPremium = null,
  approvedPremium = null,
}: Props) => {
  const formatter = useInternationalization();
  const { context: user } = useUserContext();

  const headerStyle = { fontWeight: "bold" };
  const headerWithHintStyle: CSSProperties = {
    fontWeight: "bold",
    cursor: "pointer",
    textDecoration: "underline dotted",
  };
  const subHeaderStyle = { fontStyle: "italic", fontSize: "smaller" };

  const priceStyle = { color: dragging ? "lightgray" : "inherit" };

  const totalStyle = { borderTop: "double" };

  const approvedStyle = { color: "#4cbb17" };

  const isUserRiskTaker = user.roles.isRiskTaker;
  const isUserBroker = user.roles.isBroker;
  const isUserClient = user.roles.isClient || !(isUserRiskTaker || isUserBroker);

  const isCompanyRiskTaker = RoleUtility.isRiskTaker(companyRole);
  const isCompanyBroker = RoleUtility.isBroker(companyRole);
  const isCompanyClient =
    RoleUtility.isClient(companyRole) || !(isCompanyBroker || isCompanyRiskTaker);

  const startCol = 2;
  const startRow = 3;
  let priceCols = 1 + columns.length;

  const [showUnitPrices, setShowUnitPrices] = useState(!hideUnitPrices || isSwap);
  const totalVolume = sumByProperty(prices, "volume");

  const renderPricingColumns = () => {
    if (!prices?.length) return [];

    let col = startCol;
    let items: any[] = [];
    if (!isSwap) {
      // add strike column headers, ie put, call ,etc
      columns.forEach((column: any, i: number) => {
        items.push(
          <GridLayoutItem col={col} row={1} key={`${column.label}_${col}_${i}`}>
            <span style={headerStyle}>{column.label}</span>
          </GridLayoutItem>,
        );
        items.push(
          <GridLayoutItem col={col++} row={2} key={`${column.label}_${col}_${i + 1}`}>
            <span style={subHeaderStyle}>($/{units})</span>
          </GridLayoutItem>,
        );
      });
    }

    // Add volume column headers
    items.push(
      <GridLayoutItem col={col} row={1} key={"vol_1"}>
        <span title={volumeHint} style={headerWithHintStyle}>
          Volume
        </span>
      </GridLayoutItem>,
    );
    items.push(
      <GridLayoutItem col={col} row={2} key={"vol_2"}>
        <span style={subHeaderStyle}>({units}s)</span>
      </GridLayoutItem>,
    );

    // Add total volume
    items.push(
      <GridLayoutItem col={col++} row={startRow + prices.length} key={"vol_tot"} style={totalStyle}>
        <span style={headerStyle}>{formatter.formatNumber(totalVolume, "n0")}</span>
      </GridLayoutItem>,
    );

    // Add the actual monthly strikes and volumes
    prices.forEach((price, i) => {
      col = startCol;
      let row = i + startRow;
      if (prices.length > i) {
        if (!isSwap) {
          price.prices.forEach((strikeObject: any, j: number) => {
            items.push(
              <GridLayoutItem col={col++} row={row} key={`att_${row}_${j}`}>
                {formatter.formatNumber(strikeObject.strike, `n${decimals}`)}
              </GridLayoutItem>,
            );
          });
        }
        items.push(
          <GridLayoutItem col={col++} row={row} key={`vol_${row}`}>
            {formatter.formatNumber(price.volume, "n0")}
          </GridLayoutItem>,
        );
      }
    });

    return items;
  };

  const renderQuoteColumns = () => {
    if (!quote?.length) return [];
    let items: any[] = [];
    if (isSwap) priceCols = 1;
    let col = startCol + priceCols;

    const showPrice = isUserClient || isCompanyClient;
    const showBroker = isCompanyBroker || (!isUserClient && isCompanyClient);
    const showFairValue = isCompanyRiskTaker || isUserRiskTaker;

    const priceSubHeaders = showUnitPrices ? `($/${units})` : "($)";

    if (showFairValue) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"q2_1"}>
          <span style={headerStyle}>Fair Value</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col++} row={2} key={"q2_2"}>
          <span style={subHeaderStyle}>{priceSubHeaders}</span>
        </GridLayoutItem>,
      );
    }

    if (showBroker) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"q3_1"}>
          <span style={headerStyle}>Broker</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col++} row={2} key={"q3_2"}>
          <span style={subHeaderStyle}>{priceSubHeaders}</span>
        </GridLayoutItem>,
      );
    }

    if (showPrice) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"q4_1"}>
          <span style={headerStyle}>Price</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col++} row={2} key={"q4_2"}>
          <span style={subHeaderStyle}>{priceSubHeaders}</span>
        </GridLayoutItem>,
      );
    }

    if (isSwap) {
      quote = quote.map((quoteItem: any, i: number) => {
        const matchingPriceItem = prices[i];

        // Calculate prices
        const fairValuePrice = matchingPriceItem.prices[0].strike * matchingPriceItem.volume;
        const riskTakerPrice = fairValuePrice + quoteItem.riskTakerPrice;
        const brokerPrice = fairValuePrice + quoteItem.brokerPrice;

        return {
          ...quote,
          fairValuePrice,
          riskTakerPrice,
          brokerPrice,
        };
      });
    }

    const format = showUnitPrices || isSwap ? `n${decimals}` : "n2";

    quote.forEach((q: any, i: number) => {
      col = startCol + priceCols;
      let row = i + startRow;

      const vol = prices[i].volume;
      const factor = showUnitPrices && vol !== 0 ? vol : 1;

      if (showFairValue)
        items.push(
          <GridLayoutItem col={col++} row={row} key={`q2_${row}`}>
            <span style={priceStyle}>
              {formatter.formatNumber(q.fairValuePrice / factor, format)}
            </span>
          </GridLayoutItem>,
        );

      if (showBroker)
        items.push(
          <GridLayoutItem col={col++} row={row} key={`q3_${row}`}>
            <span style={priceStyle}>
              {formatter.formatNumber(q.riskTakerPrice / factor, format)}
            </span>
          </GridLayoutItem>,
        );

      if (showPrice)
        items.push(
          <GridLayoutItem col={col++} row={row} key={`q4_${row}`}>
            <span style={priceStyle}>{formatter.formatNumber(q.brokerPrice / factor, format)}</span>
          </GridLayoutItem>,
        );
    });

    col = startCol + priceCols;
    const totalBrokerPrice = sumByProperty(quote, "brokerPrice");
    const totalRiskTakerPrice = sumByProperty(quote, "riskTakerPrice");
    const totalFairValuePrice = sumByProperty(quote, "fairValuePrice");
    const factor = showUnitPrices ? totalVolume : 1;

    if (showFairValue)
      items.push(
        <GridLayoutItem
          col={col++}
          row={startRow + quote.length}
          key={"q2_tot"}
          style={{ ...totalStyle, ...priceStyle }}
        >
          <span style={{ ...headerStyle, ...priceStyle }}>
            {formatter.formatNumber(totalFairValuePrice / factor, format)}
          </span>
        </GridLayoutItem>,
      );

    if (showBroker) {
      items.push(
        <GridLayoutItem
          col={col++}
          row={startRow + quote.length}
          key={"q3_tot"}
          style={{ ...totalStyle, ...priceStyle }}
        >
          <span style={{ ...headerStyle, ...priceStyle }}>
            {formatter.formatNumber(totalRiskTakerPrice / factor, format)}
          </span>
        </GridLayoutItem>,
      );

      if (approvedBrokerPremium !== null) {
        if (isSwap) {
          approvedBrokerPremium += totalFairValuePrice;
        }
        items.push(
          <GridLayoutItem
            col={col - 1}
            row={startRow + quote.length + 1}
            style={{ ...priceStyle, ...approvedStyle }}
            key="q3_app"
          >
            <span style={{ ...headerStyle, ...priceStyle }}>
              {formatter.formatNumber(approvedBrokerPremium / factor, format)}
            </span>
          </GridLayoutItem>,
        );
      }
    }

    if (showPrice) {
      items.push(
        <GridLayoutItem
          col={col++}
          row={startRow + quote.length}
          key={"q4_tot"}
          style={{ ...totalStyle, ...priceStyle }}
        >
          <span style={{ ...headerStyle, ...priceStyle }}>
            {formatter.formatNumber(totalBrokerPrice / factor, format)}
          </span>
        </GridLayoutItem>,
      );
      if (approvedPremium !== null) {
        if (isSwap) {
          approvedPremium += totalFairValuePrice;
        }
        items.push(
          <GridLayoutItem
            col={col - 1}
            row={startRow + quote.length + 1}
            style={{ ...priceStyle }}
            key="q4_app"
          >
            <span style={{ ...headerStyle, ...priceStyle, ...approvedStyle }}>
              {formatter.formatNumber(approvedPremium / factor, format)}
            </span>
          </GridLayoutItem>,
        );
      }
    }

    return items;
  };

  const renderDateColumns = () => {
    const items = prices.map((price, i) => {
      const date = DateUtility.addOffsetToDateString(price.date, -1 * calendarMonthOffset);

      return (
        <GridLayoutItem col={1} row={i + startRow} key={`c1_${i + startRow}`}>
          <span style={headerStyle}>{DateUtility.formatDateMY(date)}</span>
        </GridLayoutItem>
      );
    });

    const showApproved = approvedPremium !== null || approvedBrokerPremium !== null;
    if (showApproved) {
      items.push(
        <GridLayoutItem col={1} row={startRow + prices.length + 1} key={"c1_app"}>
          <span style={{ ...headerStyle, ...approvedStyle }}>Approved:</span>
        </GridLayoutItem>,
      );
    }

    return items;
  };

  return (
    <div style={{ margin: "0 1rem" }}>
      {!hideUnitPrices && !isSwap && (
        <div style={{ display: "flex", justifyContent: "right", paddingBottom: "1rem" }}>
          <Label style={{ marginRight: 5 }}>Unit Prices</Label>
          <Switch
            size={"small"}
            checked={showUnitPrices}
            onChange={(e) => setShowUnitPrices(!showUnitPrices)}
          />
        </div>
      )}

      <Tooltip anchorElement="target" position="auto" showCallout={false}>
        <GridLayout align={{ vertical: "top", horizontal: "end" }}>
          {renderDateColumns()}

          {renderPricingColumns()}

          {renderQuoteColumns()}
        </GridLayout>
      </Tooltip>
    </div>
  );
};

export default VanillaQuoteSummary;
