export class CompanyFeatures {
  features: number[] = [];
  canSell: boolean = false;
  canShowFees: boolean = false;
  canShowAllProducts: boolean = false;
  canShowAnalytics: boolean = false;

  constructor(features: number[]) {
    this.features = features;
    this.canSell = CompanyUtility.canSell(features);
    this.canShowFees = CompanyUtility.canShowFees(features);
    this.canShowAllProducts = CompanyUtility.canShowAllProducts(features);
    this.canShowAnalytics = CompanyUtility.canShowAnalytics(features);
  }
}

const features = {
  canSell: 1,
  showFees: 2,
  showAllProducts: 3,
  showAnalytics: 4
};

export class CompanyUtility {
  static canSell = (companyFeatures: number[] | undefined): boolean =>
    this.hasFeature(companyFeatures, features.canSell);

  static canShowFees = (companyFeatures: number[] | undefined): boolean =>
    this.hasFeature(companyFeatures, features.showFees);

  static canShowAllProducts = (companyFeatures: number[] | undefined): boolean =>
    this.hasFeature(companyFeatures, features.showAllProducts);

  static canShowAnalytics = (companyFeatures: number[] | undefined): boolean =>
    this.hasFeature(companyFeatures, features.showAnalytics);

  private static hasFeature = (companyFeatures: number[] | undefined, feature: number): boolean =>
    companyFeatures?.some((f: number) => f === feature) || false;
}
