import { Icon } from "@progress/kendo-react-common";
import { Popover, PopoverPosition } from "@progress/kendo-react-tooltip";
import { PropsWithChildren, useRef, useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  link?: string,
  position?: PopoverPosition
}

const InfoIconAndTooltip = ({ link, position, children }: PropsWithChildren<Props>) => {
 const anchor = useRef<any | null>(null);
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <>
      &nbsp;
      <Icon
        ref={anchor}
        name="info"
        onClick={handleClick}
        className="info-icon"
      />
      <Popover
        show={show}
        anchor={anchor.current && anchor.current.element}
        className="info-popover"
        position={position}
      >
        {children}
        {link && (
          <>
            &nbsp;
            <Link
              to={link}
              target="_blank"
              className="k-color-primary"
            >
              Learn More <Icon name="launch" />
            </Link>
          </>
        )}
      </Popover>
    </>
  );
};

export default InfoIconAndTooltip;
