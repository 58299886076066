import { FilterDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { extendDataItem, mapTree } from "@progress/kendo-react-common";
import { filterBy, treeToFlat } from "@progress/kendo-react-data-tools";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownTree } from "@progress/kendo-react-dropdowns";
import { Field, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import { useCallback, useEffect, useMemo, useState } from "react";
import usersApi from "../../api/users";
import { useApi } from "../../hooks/useApi";
import { useUserContext } from "../../hooks/useUserContext";
import { emailValidator, phoneValidator, requiredValidator } from "../../validators";
import { FormInput, FormMaskedTextBox, FormSwitch, FormSwitchList, ListItem } from "../form";
import { LoadingIndicator } from "../LoadingIndicator";

interface AddEditUserFormProps {
  cancelEdit: () => void;
  onSubmit: () => void;
  user: any;
  companies: any[];
  permissions: ListItem[];
  rolePermissions: Record<string, number[]>;
}

export const AddEditUserDialog = (props: AddEditUserFormProps) => {
  const { user, companies, permissions, rolePermissions } = props;
  const { context } = useUserContext();
  const isSelf = user.externalId === context.externalId;
  const addUserApi = useApi(usersApi.create);
  const updateUserApi = useApi(usersApi.update);
  const [isLoading, setIsLoading] = useState(false);
  const [permissionsList, setPermissionsList] = useState<ListItem[]>([]);
  const [companyIds, setCompanyIds] = useState<number[]>(user.companyIds);

  useEffect(() => {
    if (!addUserApi.loading && (addUserApi.data || addUserApi.error))
      if (!addUserApi.error) props.onSubmit();
  }, [addUserApi.loading, addUserApi.data, addUserApi.error]);

  useEffect(() => {
    if (!updateUserApi.loading && (updateUserApi.data || updateUserApi.error))
      if (!updateUserApi.error) props.onSubmit();
  }, [updateUserApi.loading, updateUserApi.data, updateUserApi.error]);

  const onPermissionsChange = (event: any, formRenderProps: FormRenderProps) => {
    const { id } = selected;

    const newIds =
      event.value.length > 0 && !companyIds.includes(id)
        ? [...companyIds, id]
        : event.value.length === 0 && companyIds.includes(id)
          ? companyIds.filter((i) => i !== id)
          : companyIds;

    setCompanyIds(newIds);
    formRenderProps.onChange("selectedCompanies", {
      value: newIds.length > 0 ? JSON.stringify(newIds) : "",
    });
  };

  // Company tree code

  const treeFields = {
    dataItemKey: "id",
    subItemsField: "items",
    expandField: "expanded",
    selectField: "selected",
    textField: "text",
  };

  const [companyTree, setCompanyTree] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>(null);
  const [expanded, setExpanded] = useState<number[]>([]);
  const [filter, setFilter] = useState<FilterDescriptor>({
    value: "",
    operator: "contains",
  });

  const addTreeNode = (cur: any[], company: any) => {
    let childNodes: any[] = [];
    const childCompanies = companies?.filter((c) => company.children.includes(c.value)) || [];
    childCompanies.forEach((c: any) => addTreeNode(childNodes, c));

    cur.push({
      text: company.label,
      id: company.value,
      role: company.role,
      items: childNodes,
      expanded: true,
    });
  };

  useEffect(() => {
    let tree: any[] = [];

    companies
      .filter((c) => c.value === context.activeCompany.id)
      .forEach((c) => addTreeNode(tree, c));

    setCompanyTree(tree);

    // Expand all initially
    setExpanded(
      treeToFlat(tree, treeFields.expandField, treeFields.subItemsField).map((item) => item.id),
    );
  }, [companies]);

  const processTreeData = (data: any[], state: any, fields: any) => {
    const { selectField, expandField, dataItemKey, subItemsField } = fields;
    const { expanded, value, filter } = state;
    const filtering = filter?.value;
    return mapTree(
      filtering ? filterBy(data, [filter], subItemsField) : data,
      subItemsField,
      (item) => {
        const props = {
          [expandField]: expanded.includes(item[dataItemKey]),
          [selectField]: value && item[dataItemKey] === value[dataItemKey],
        };
        return filtering
          ? extendDataItem(item, subItemsField, props)
          : {
              ...item,
              ...props,
            };
      },
    );
  };

  const treeData = useMemo(
    () =>
      processTreeData(
        companyTree,
        {
          expanded,
          selected,
          filter,
        },
        treeFields,
      ),
    [expanded, selected, filter, companyTree],
  );

  const expandedState = (item: any, dataItemKey: string, expanded: any[]) => {
    const nextExpanded = expanded.slice();
    const itemKey = item[dataItemKey];
    const index = expanded.indexOf(itemKey);
    index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
    return nextExpanded;
  };

  const onExpandChange = useCallback(
    (e: any) => setExpanded(expandedState(e.item, treeFields.dataItemKey, expanded)),
    [expanded],
  );

  const onCompanyChange = (event: any, formRenderProps: FormRenderProps) => {
    const { value: item } = event;
    if (selected?.id === item?.id) return;

    const userPermissions = formRenderProps.valueGetter("permissions");
    const companyPermissions = formRenderProps.valueGetter("companyPermissions");

    if (selected) {
      userPermissions[selected.id] = companyPermissions;
      formRenderProps.onChange("permissions", { value: userPermissions });
    }

    item.selected = true;
    setSelected(item);
    formRenderProps.onChange("companyPermissions", {
      value: userPermissions[item.id] || [],
    });

    const perms = rolePermissions[item.role] ?? [];
    setPermissionsList(permissions.filter((p) => perms.includes(p.value as number)));
  };

  const TreeItem = (props: any) => (
    <span style={{ cursor: "pointer" }}>
      {companyIds.includes(props.item.id) && (
        <span className={`k-icon k-i-check`} style={{ color: "lightgreen" }} />
      )}
      <span
        style={{
          color: props.item.id === selected?.id ? "lightgreen" : "inherit",
        }}
      >
        {props.item.text}
      </span>
    </span>
  );

  // End company tree code

  const handleSubmitEdit = async (event: any) => {
    setIsLoading(true);
    const editUser = { ...event };

    if (selected) editUser.permissions[selected.id] = editUser.companyPermissions;

    Object.keys(editUser.permissions).forEach((k) => {
      if (editUser.permissions[k].length === 0) delete editUser.permissions[k];
    });

    editUser.companyIds = [...Object.keys(editUser.permissions)];
    delete editUser.companyPermissions;

    if (editUser.id === 0) await addUserApi.request(editUser);
    else await updateUserApi.request(editUser);

    setIsLoading(false);
  };

  return (
    <Dialog
      title={user.id > 0 ? `Edit ${user.fullName}` : "Add New User"}
      onClose={props.cancelEdit}
      minWidth={"40%"}
    >
      <div>
        <Form
          onSubmit={handleSubmitEdit}
          initialValues={user}
          render={(formRenderProps) => (
            <FormElement>
              <Field
                label="Full Name"
                name="fullName"
                component={FormInput}
                validator={requiredValidator}
                disabled={isLoading}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <div style={{ flexBasis: "55%" }}>
                  <Label>Company</Label>
                  <Field
                    key="selectedCompanies"
                    id="selectedCompanies"
                    name="selectedCompanies"
                    component={FormInput}
                    validator={requiredValidator}
                    showValidationMessage={true}
                    style={{ visibility: "hidden", display: "none" }}
                  />

                  <DropDownTree
                    data={treeData}
                    item={TreeItem}
                    {...treeFields}
                    onExpandChange={onExpandChange}
                    onChange={(e) => onCompanyChange(e, formRenderProps)}
                    filterable={true}
                    onFilterChange={(e) => setFilter(e.filter)}
                    filter={filter.value}
                    popupSettings={{ width: "auto", popupClass: "height-300px" }}
                  />
                </div>

                <div>
                  <Label>Permissions</Label>

                  {selected ? (
                    <Field
                      name="companyPermissions"
                      id="companyPermissions"
                      component={FormSwitchList}
                      data={permissionsList}
                      disabled={isLoading || !selected || isSelf}
                      size="small"
                      onChange={(e) => onPermissionsChange(e, formRenderProps)}
                    />
                  ) : (
                    <>
                      <div>Select a company to view</div>
                      <div>the user's permssions</div>
                    </>
                  )}
                </div>
              </div>

              <Field
                label="Email Address"
                name="email"
                component={FormInput}
                validator={emailValidator}
                disabled={isLoading}
              />
              <Field
                name="phone"
                label="Phone Number"
                mask="(999) 000-0000"
                required={false}
                component={FormMaskedTextBox}
                validator={phoneValidator}
                disabled={isLoading}
              />

              <div style={{ display: "flex" }}>
                <div style={{ float: "left" }}>
                  <Field
                    label="Active"
                    name="isActive"
                    component={FormSwitch}
                    defaultChecked={user.isActive}
                    disabled={isLoading || isSelf}
                    size="small"
                  />
                </div>
              </div>

              <div>
                {updateUserApi.error && <Error>{updateUserApi.error}</Error>}
                {addUserApi.error && <Error>{addUserApi.error}</Error>}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "2rem",
                }}
              >
                <LoadingIndicator loading={isLoading} />

                {!isLoading && (
                  <>
                    <Button themeColor="base" disabled={isLoading} onClick={props.cancelEdit}>
                      Cancel
                    </Button>

                    <Button
                      themeColor="success"
                      type="submit"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </FormElement>
          )}
        ></Form>
      </div>
    </Dialog>
  );
};
