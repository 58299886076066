import { SvgIcon } from "@progress/kendo-react-common";
import { Error, Label } from "@progress/kendo-react-labels";
import { Card, CardTitle, Avatar, CardBody } from "@progress/kendo-react-layout";
import { userIcon } from "@progress/kendo-svg-icons";
import { useState, useEffect } from "react";
import companiesApi from "../../api/company";
import usersApi from "../../api/users";
import { ICompany } from "../../components/admin/Company";
import { IUser } from "../../components/admin/User";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useFetch } from "../../hooks/useApi";

export const Profile = () => {
  const userResults = useFetch(usersApi.get);
  const companiesResults = useFetch(companiesApi.get);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [companies, setCompanies] = useState<string[]>([]);

  useEffect(() => {
    if (userResults.data?.user) setUser(userResults.data.user);
  }, [userResults.data]);

  useEffect(() => {
    if (user && companiesResults.data?.companies) {
      setCompanies(
        companiesResults.data.companies
          .filter((c: ICompany) => user.companyIds.includes(c.id))
          .map((c: ICompany) => c.name)
          .sort((a: string, b: string) => a.localeCompare(b)),
      );
    }
  }, [user, companiesResults.data]);

  useEffect(() => {
    setLoading(userResults.loading || companiesResults.loading);
  }, [userResults.loading, companiesResults.loading]);

  interface IUserField {
    label: string;
    text?: string;
    list?: string[];
  }

  const UserField = ({ label, text, list }: IUserField) => {
    return (
      <div style={{ marginBottom: "1rem" }}>
        <Label>{label}</Label>
        {text && <div>{text}</div>}
        {list && list.map((item) => <div>{item}</div>)}
      </div>
    );
  };

  return (
    <div className="profile-page" style={{ textAlign: "center" }}>
      <LoadingIndicator loading={loading} />
      {userResults.error && <Error>{userResults.error}</Error>}
      {companiesResults.error && <Error>{companiesResults.error}</Error>}

      {/* TODO: Allow user to edit / update profile */}

      {user && (
        <Card style={{ border: "none", margin: "1rem" }}>
          <CardTitle>
            <Avatar type="icon" size="large">
              <SvgIcon icon={userIcon} />
            </Avatar>
            {user.fullName}
          </CardTitle>

          <CardBody>
            <UserField label={"Email Address"} text={user.email} />

            {user.phone && <UserField label={"Phone"} text={user.phone} />}

            {companies.length > 0 && <UserField label={"Companies"} list={companies} />}
          </CardBody>
        </Card>
      )}
    </div>
  );
};
