import { State, process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import companyApi from "../../api/company";
import productsApi from "../../api/products";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useApi, useFetch } from "../../hooks/useApi";
import { objectToLookup } from "../../utilities/objectUtilities";
import { ListItem } from "../form";
import {
  BooleanGridCell,
  EditLinkCell,
} from "../grid";
import { AddEditCompanyDialog } from "./AddEditCompanyDialog";
import { Company, ICompany } from "./Company";

export const CompaniesGrid = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [roles, setRoles] = useState<ListItem[]>([]);
  const [businessTypes, setBusinessTypes] = useState<ListItem[]>([]);
  const [features, setFeatures] = useState<ListItem[]>([]);
  const [roleFeatures, setRoleFeatures] = useState<Record<string, Record<number, boolean>>>({});
  const [parentChildRoles, setparentChildRoles] = useState<Record<string, string>>({});
  const [searchText, setSearchText] = useState("");
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<Company>(Company.EMPTY);
  const [result, setResult] = useState<any>([]);
  const [dataState, setDataState] = useState<State>({
    sort: [{ field: "name", dir: "asc" }],
    skip: 0,
    take: 20,
  });

  const getCompaniesApi = useApi(companyApi.getLineage);
  const getBusinessTypesApi = useFetch(productsApi.getBusinessTypes);

  useEffect(() => {
    getCompaniesApi.request();
  }, []);

  useEffect(() => {
    const { data } = getCompaniesApi;

    setRoles(objectToLookup(data?.roles || [], true));
    setFeatures(objectToLookup(data?.features || []));
    setRoleFeatures(data?.roleFeatures ?? {});
    setparentChildRoles(data?.parentChildRoles || {});
    setCompanies(data?.companies.map((c: ICompany) => Company.create(c)) || []);
  }, [getCompaniesApi.data]);

  useEffect(() => {
    setBusinessTypes(objectToLookup(getBusinessTypesApi.data?.businessTypes || []));
  }, [getBusinessTypesApi.data]);

  useEffect(() => {
    const newState = createDataState(dataState);
    setResult(newState.result);
  }, [companies]);

  useEffect(() => {
    const newState = Object.assign({}, dataState, {
      filter: {
        logic: "or",
        filters: [
          { field: "name", operator: "contains", value: searchText },
          { field: "contactName", operator: "contains", value: searchText },
          { field: "roleDisplay", operator: "contains", value: searchText },
        ],
      },
    });
    applyDataStateChange(newState);
  }, [searchText]);

  const createDataState = (dataState: State) => {
    return {
      result: process(companies.slice(0), dataState),
      dataState: dataState,
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    applyDataStateChange(event.dataState);
  };

  const applyDataStateChange = (dataState: State) => {
    const updatedState = createDataState(dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const enterEdit = (item: any) => {
    setEditItem(item);
    setOpenForm(true);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleSubmitEdit = () => {
    setOpenForm(false);
    getCompaniesApi.request();
  };

  const CompanyNameCell = (props: GridCellProps) => (
    <EditLinkCell {...props} title="Edit Company" enterEdit={enterEdit} />
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Button
          title="Add Company"
          themeColor="primary"
          size="small"
          onClick={() => enterEdit(Company.EMPTY)}
          style={{ marginBottom: 10 }}
        >
          Add Company
        </Button>

        <div>
          <Label>Search</Label>
          <Input
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
            style={{ margin: 5, width: 200 }}
          />
        </div>
      </div>

      <LoadingIndicator loading={getCompaniesApi.loading} />
      {getCompaniesApi.error && <Error>{getCompaniesApi.error}</Error>}
      {!getCompaniesApi.loading && (
        <Grid
          data={result}
          {...dataState}
          pageable={true}
          sortable={true}
          filterable={false} // Using custom grid column menu filter
          onDataStateChange={dataStateChange}
        >
          <GridColumn
            field="name"
            title="Name"
            cell={CompanyNameCell}
          />
          <GridColumn field="contactName" title="Contact Name" />
          <GridColumn
            field="roleDisplay"
            title="Type"
            width={200}
          />
          <GridColumn
            field="isActive"
            title="Active"
            width={150}
            cell={BooleanGridCell}
          />
        </Grid>
      )}

      {openForm && (
        <AddEditCompanyDialog
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmitEdit}
          company={editItem}
          companies={companies.filter((c) => c.isActive)}
          roles={roles}
          businessTypes={businessTypes}
          features={features}
          roleFeatures={roleFeatures}
          parentChildRoles={parentChildRoles}
        />
      )}
    </>
  );
};
