import { Error } from "@progress/kendo-react-labels";
import { useState } from "react";
import { InsuranceQuoteSummary, InsuranceStepperHeader } from ".";
import { MarginsSummary } from "..";
import insuranceApi from "../../../api/insurance";
import quotesApi from "../../../api/quotes";
import { useFetch, useFetchWhen } from "../../../hooks/useApi";
import { sumByProperty } from "../../../utilities/objectUtilities";
import { LoadingIndicator } from "../../LoadingIndicator";
import { IntradayCurvesSlippageChart } from "../../charts";
import { HeaderTextSelector } from "../../utilities";
import { ICommodity } from "../Commodity";
import { IInstrument } from "../Instrument";
import { QuoteDetailsHeader, QuoteSummaryCard } from "../quoteDetails";
import { IInsuranceQuoteSummary } from "./Types";

type Props = {
  quote: any;
  refetch: () => void;
  commodity: ICommodity;
  instrument: IInstrument;
  isRiskTaker: boolean;
  onReject: () => void;
};

const QUOTE_VIEW = "Quote";
const FEES_VIEW = "Fees";
const MARKETS_VIEW = "Slippage";

export const InsuranceQuoteDetails = ({
  quote,
  refetch,
  commodity,
  instrument,
  isRiskTaker,
  onReject,
}: Props) => {
  const [selectedView, setSelectedView] = useState(QUOTE_VIEW);
  const [chartLoading, setChartLoading] = useState(false);

  const { pricingId, quoteId, quoteName, quoteDate, companyRole, transactionDetails } = quote;
  const { data: quoteDetails, loading, error } = useFetch(insuranceApi.getQuoteDetails, quoteId);
  const { data: slippage } = useFetchWhen(quotesApi.getSlippage, isRiskTaker, quoteId, pricingId);

  const { startDate, endDate, totalVolume, totalCoverage, retentionLevels } =
    quoteDetails?.quote || {};

  const { decimals } = commodity;
  const units = commodity?.units.slice(0, -1) ?? "";

  const customLevel =
    retentionLevels?.length > 0 ? retentionLevels[retentionLevels.length - 1] : {};

  const coverages =
    customLevel.pricing?.details.map((c: any) => {
      return { label: c.date, ...c };
    }) ?? [];

  const quoteSummary: IInsuranceQuoteSummary = {
    units: units,
    loading: loading,
    coverages: coverages,
    prices: coverages,
    quote: transactionDetails,
    companyRole: companyRole,
    unitPriceDecimals: decimals,
  };

  const fairValue = sumByProperty(transactionDetails, "fairValuePrice");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ flexBasis: "80%" }}>
        <LoadingIndicator loading={loading} />
        <Error>{error}</Error>

        {!loading && (
          <QuoteDetailsHeader
            quote={quote}
            commodity={commodity}
            instrumentName={instrument.name}
            isRiskTaker={isRiskTaker}
          >
            <InsuranceStepperHeader
              quoteId={quoteId}
              product={{ commodity, instrument }}
              quoteName={quoteName}
              startDate={startDate}
              endDate={endDate}
              totalVolume={totalVolume}
              totalCoverage={totalCoverage}
            />
          </QuoteDetailsHeader>
        )}

        <div style={{ marginTop: 20 }}>
          {isRiskTaker && (
            <HeaderTextSelector
              values={[QUOTE_VIEW, FEES_VIEW, MARKETS_VIEW]}
              onChange={setSelectedView}
              selected={selectedView}
            />
          )}
        </div>

        {selectedView === QUOTE_VIEW && (
          <QuoteSummaryCard
            quote={quote}
            onReject={onReject}
            getQuoteDetails={refetch}
            totalVolume={totalVolume}
          >
            <InsuranceQuoteSummary name="summary" {...quoteSummary} />
          </QuoteSummaryCard>
        )}

        {selectedView === FEES_VIEW && (
          <QuoteSummaryCard
            quote={quote}
            onReject={onReject}
            getQuoteDetails={refetch}
            totalVolume={totalVolume}
            hideActions={true}
          >
            <MarginsSummary
              quoteId={quoteId}
              quote={quote}
              pricingId={pricingId}
              fairValue={fairValue}
              totalVolume={totalVolume}
              unitPriceDecimals={decimals}
              units={units}
            />
          </QuoteSummaryCard>
        )}

        {selectedView === MARKETS_VIEW && (
          <>
            <LoadingIndicator loading={chartLoading} />
            <IntradayCurvesSlippageChart
              commodity={commodity}
              showTitle={true}
              quoteSlippage={slippage?.quoteSlippage}
              onLoading={setChartLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};
