import { getIconPath } from "../../utilities/textUtilities";

export interface ICommodity {
  id: number;
  name: string;
  abbreviation: string;
  units: string;
  type: string;
  companyIds: number[];
  decimals: number;
  hasDailySettlement: boolean;
}

export class Commodity implements ICommodity {
  id = 0;
  name = "";
  abbreviation = "";
  units = "";
  type = "";
  companyIds: number[] = [];
  decimals = 0;
  hasDailySettlement = false;

  private constructor(commodity: ICommodity | null | undefined) {
    if (commodity) {
      this.id = commodity.id;
      this.name = commodity.name;
      this.abbreviation = commodity.abbreviation;
      this.units = commodity.units;
      this.type = commodity.type;
      this.companyIds = commodity.companyIds;
      this.decimals = commodity.decimals;
      this.hasDailySettlement = commodity.hasDailySettlement;
    }
  }

  static get EMPTY(): Commodity {
    return Commodity.create(null);
  }

  static create(obj: ICommodity | null | undefined): Commodity {
    return new Commodity(obj);
  }

  public icon = () => getIconPath("commodity", this.type);

  public selectable = (id: number) => this.companyIds.includes(id);

  public get valid(): boolean {
    return this.id > 0;
  }
}
