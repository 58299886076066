import { FormRenderProps } from "@progress/kendo-react-form";
import { useEffect } from "react";
import { Configuration } from "./Configuration";
import { ProductMarginsSelector } from ".";

interface IProductMarginsSelectionStepProps {
  formRenderProps: FormRenderProps;
  companyId: number;
  configurations: Record<number, Configuration[]>;
  margins: any[];
  marginField: string;
  onMarginsUpdate: () => void;
}

export const ProductMarginsSelectionStep = ({
  formRenderProps,
  companyId,
  configurations,
  margins,
  marginField,
  onMarginsUpdate,
}: IProductMarginsSelectionStepProps) => {
  const configurationIds: number[] = formRenderProps.valueGetter("configurations") || [];
  const selectedConfigurations = (configurations[companyId] ?? []).filter((c) =>
    configurationIds.includes(c.configurationId),
  );

  useEffect(() => {
    selectedConfigurations.forEach((c: any, index: number) => {
      const key = `marginSelection_${c.productId}`;
      const selection = formRenderProps.valueGetter(key);

      const margin = margins.find(
        (m) => (!m.commodityId || m.commodityId === c.commodityId) && m.marginId === c[marginField],
      );

      const selectedMargin = selection ? margins.find(
        (m) => (!m.commodityId || m.commodityId === c.commodityId) && m.marginId === selection.value,
      ) : undefined;

      if ((!selection && margin) || (selection && !selectedMargin)) {
        formRenderProps.onChange(key, {
          value: margin ? { value: margin.marginId, label: margin.longName } : undefined,
        });
      }
    });
  }, []);

  return (
    <div className="stepper-form">
      <ProductMarginsSelector
        formRenderProps={formRenderProps}
        configurations={selectedConfigurations}
        margins={margins}
        onMarginsUpdate={onMarginsUpdate}
      />
    </div>
  );
};
