enum Roles {
  riskTaker = "risktaker",
  broker = "broker",
  client = "client",
  fronter = "fronting",
}

export class RoleUtility {
  static hasRiskTakerRole = (userRoles: string[] | undefined): boolean =>
    this.hasRole(userRoles, Roles.riskTaker);

  static hasBrokerRole = (userRoles: string[] | undefined): boolean =>
    this.hasRole(userRoles, Roles.broker);

  static hasClientRole = (userRoles: string[] | undefined): boolean =>
    this.hasRole(userRoles, Roles.client);

  static hasFronterRole = (userRoles: string[] | undefined): boolean =>
    this.hasRole(userRoles, Roles.fronter);

  static isRiskTaker = (userRole: string | undefined): boolean => this.isRole(userRole, Roles.riskTaker);
  static isBroker = (userRole: string | undefined): boolean => this.isRole(userRole, Roles.broker);
  static isClient = (userRole: string | undefined): boolean => this.isRole(userRole, Roles.client);
  static isFronter = (userRole: string | undefined): boolean => this.isRole(userRole, Roles.fronter);

  private static isRole = (userRole: string | undefined, role: string) =>
    userRole?.toLowerCase() === role.toLowerCase();

  private static hasRole = (userRoles: string[] | undefined, role: string): boolean =>
    userRoles?.some((r: string) => this.isRole(r, role)) || false;
}

enum Permissions {
  canSubmit = 2,
  canApprove = 6,
  corporateAdmin = 3,
  productAdmin = 7,
  marginAdmin = 5,
  userAdmin = 4,
}

export class PermissionUtility {
  static canConfigureProducts = (userPermissions: number[] | undefined): boolean =>
    this.hasPermission(userPermissions, Permissions.productAdmin);

  static canConfigureMargins = (userPermissions: number[] | undefined): boolean =>
    this.hasPermission(userPermissions, Permissions.marginAdmin);

  static canSubmitQuotes = (userPermissions: number[] | undefined): boolean =>
    this.hasPermission(userPermissions, Permissions.canSubmit);

  static canApproveTrades = (userPermissions: number[] | undefined): boolean =>
    this.hasPermission(userPermissions, Permissions.canApprove);

  static canAdminCompanies = (userPermissions: number[] | undefined): boolean =>
    this.hasPermission(userPermissions, Permissions.corporateAdmin);

  static canAdminUsers = (userPermissions: number[] | undefined): boolean =>
    this.hasPermission(userPermissions, Permissions.userAdmin);

  private static hasPermission = (
    userPermissions: number[] | undefined,
    permission: number,
  ): boolean => userPermissions?.some((p: number) => p === permission) || false;
}
