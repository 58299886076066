import { GetIntraDayPricesRequest } from "../components/quotes/vanilla/VanillaStructureContainer";
import { apiClient } from "./apiClient";

const endpoint = "contract";

const getRecentPrices = (tickerCode: string, months: number) =>
  apiClient(`${endpoint}/prices`).fetchWithParams(`?tickerCode=${tickerCode}&months=${months}`);

const getDateBoundaries = (
  tickerCode: string,
  configurationId: number,
  isFuture: boolean = false,
) =>
  apiClient(`${endpoint}/dates`).fetchWithParams(
    `?tickerCode=${tickerCode}&isFuture=${isFuture}&configurationId=${configurationId}`,
  );

const getMarketStatistics = (commodityId: number) =>
  apiClient(`${endpoint}/stats`).fetchWithParams(`?commodityId=${commodityId}`);

const getIntraDayPrices = (data: GetIntraDayPricesRequest) =>
  apiClient(`${endpoint}/intraPrices`).post(data);

const getSettlementData = (commodityId: number) =>
  apiClient(`${endpoint}/settlement`).fetchWithParams(`?commodityId=${commodityId}`);

const getBidAskData = (commodityId: number) =>
  apiClient(`${endpoint}/bidAsk`).fetchWithParams(`?commodityId=${commodityId}`);

export default {
  getRecentPrices,
  getDateBoundaries,
  getMarketStatistics,
  getIntraDayPrices,
  getSettlementData,
  getBidAskData,
};
