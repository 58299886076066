import { addMonths, durationInMonths } from "@progress/kendo-date-math";
import DateUtility from "../dateUtilities";
import { sumNumbers } from "../objectUtilities";
import { DerivativeUtil } from "./util";
import { VanillaComponent } from "./vanillaComponent";
import { ComponentType, StrikeType, TransactionDirection } from "./vanillaEnums";

export const DEFAULT_ALLOCATION: any = {
  1: 1,
  2: 1,
  3: 1,
  4: 1,
  5: 1,
  6: 1,
  7: 1,
  8: 1,
  9: 1,
  10: 1,
  11: 1,
  12: 1,
};
export type MonthlyValues = number[];

export class VanillaUtil {
  _structureDirection: TransactionDirection;
  set direction(newDirection: TransactionDirection) {
    this._columns.forEach((column) => {
      column.structureDirection = newDirection;
    });
    this._errors = {};
    this.validateAll();
  }

  _errors: { [key: string]: Set<number> } = {};

  get errors() {
    const strings = Object.keys(this._errors);
    const errorMessages = strings.map((error) => {
      const monthIndices = this._errors[error];
      let months = [...monthIndices].map((i) => this.months[i]);
      const monthStrings = months.map((month) => DateUtility.formatDateMY(month));
      const monthString = monthStrings.join(", ");
      const errorString = `${error} (${monthString})`;
      return errorString;
    });

    return errorMessages;
  }

  _volumes: number[];
  setVolumes(newVolumes: number[]) {
    this._columns.forEach((column) => {
      column.setVolumes(newVolumes);
    });
    this._volumes = newVolumes;
  }
  get volumes() {
    return this._volumes;
  }

  get totalVolume() {
    const total = sumNumbers(this.volumes);
    return total;
  }
  setTotalVolume(newTotalVolume: number) {
    if (newTotalVolume < 0) newTotalVolume = 0;
    const values = this.allocate(newTotalVolume);
    this.setVolumes(values);
  }

  private _months: Date[] = [];
  get months() {
    return this._months;
  }

  private _allocation: any = DEFAULT_ALLOCATION;
  get allocation() {
    return this._allocation;
  }

  setAllocation(newAllocation: any) {
    this._allocation = newAllocation;
    const newVolumes = this.allocateVolumes(newAllocation, this.totalVolume);
    this.setVolumes(newVolumes);
    this.updateAvgValues();
  }

  updateAvgAdders() {
    this._columns.forEach((column) => {
      column.updateAvgAdder();
    });
  }

  private _columns: VanillaComponent[];
  get columns() {
    return this._columns;
  }

  set prices(newPrices: number[]) {
    this._columns.forEach((column) => {
      column.prices = newPrices;
    });
  }

  set swapLevels(newSwapLevels: number[]) {
    this._columns.forEach((column) => {
      column.prices = newSwapLevels;
      column.fixedStrikes = newSwapLevels;
    });
  }

  set strikes(newStrikes: number[][]) {
    this._columns.forEach((column, i) => {
      const strikes = newStrikes[i];
      column.fixedStrikes = strikes;
    });
  }

  set isCommonStrike(isCommonStrike: boolean) {
    this._columns.forEach((column) => {
      column.isCommonStrike = isCommonStrike;
    });
  }

  get isCommonStrike() {
    return this._columns[0].isCommonStrike;
  }

  constructor(
    components: any[],
    startMonth: Date,
    endMonth: Date,
    prices: number[],
    structureDirection: TransactionDirection,
    isCommonStrike: boolean,
  ) {
    const months = VanillaUtil.createMonths(startMonth, endMonth);
    this._months = months;

    this._structureDirection = structureDirection;

    this._volumes = new Array(months.length).fill(0);

    const columns = VanillaUtil.createColumns(
      components,
      prices,
      this._structureDirection,
      this._volumes,
      isCommonStrike,
    );
    this._columns = columns;
  }

  setStrike(
    value: number,
    columnIndex: number,
    strikeType: StrikeType,
    month: number | null = null,
  ) {
    // If this column is linked to the next column, then we must update the values of the next column as well
    const isNextColumnLinked = this.isNextColumnLinked(columnIndex);
    const isLinked = this.columns[columnIndex].isLinked;

    switch (strikeType) {
      case StrikeType.Fixed:
        this.setFixedStrike(value, columnIndex, month);
        if (isLinked && isNextColumnLinked) {
          this.setFixedStrike(value, columnIndex + 1, month);
        }
        break;
      case StrikeType.Adder:
        this.setAdder(value, columnIndex, month);
        if (isLinked && isNextColumnLinked) {
          const nextValue = this.isAdderReversed(columnIndex) ? value * -1 : value;
          this.setAdder(nextValue, columnIndex + 1, month);
        }
        break;
      default:
        throw new Error("Specify a valid strike type");
    }
    this.validateMonths(month, isLinked && isNextColumnLinked, columnIndex);
  }

  private volumeWeightStrikes() {
    this.columns.forEach((column, i) => {
      column.volumeWeightStrikes();
    });
    this.validateAll();
  }

  private validateAll() {
    this.columns.forEach((column, i) => {
      this.validateMonths(null, column.isLinked, i);
    });
  }

  private validateMonths(month: number | null, isLinked: boolean, columnIndex: number) {
    this.validateColumn(month, columnIndex);
    if (isLinked) {
      this.validateColumn(month, columnIndex + 1);
    }
  }

  private validateColumn(month: number | null, columnIndex: number) {
    if (month !== null) {
      this.validate(columnIndex, month);
    } else {
      this.months.forEach((_month, i) => this.validate(columnIndex, i));
    }
  }

  private isAdderReversed(columnIndex: number) {
    const nextColumn = this.columns[columnIndex + 1];
    const nextType = nextColumn?.columnType;
    const thisColumn = this.columns[columnIndex];
    const thisType = thisColumn.columnType;
    const reverseAdder = nextType !== thisType;
    return reverseAdder;
  }

  private isNextColumnLinked(columnIndex: number) {
    const nextColumn = this.columns[columnIndex + 1];
    const isNextColumnLinked = nextColumn?.isLinked;
    return isNextColumnLinked;
  }

  setAdder(value: number, columnIndex: number, month: number | null) {
    const column = this.columns[columnIndex];
    column.setAdder(value, month);
  }

  private validate(columnIndex: number, monthIndex: number): void {
    const column = this.columns[columnIndex];
    if (!column) return;
    const prev = this.columns[columnIndex - 1];
    const next = this.columns[columnIndex + 1];

    const isCall = column.columnType === ComponentType.Call;
    const isPut = column.columnType === ComponentType.Put;
    const hasPrev = !!prev;
    const hasNext = !!next;
    const isCap = column.isCap;

    const strike = column.fixedStrikes[monthIndex];
    const nextStrike = next?.fixedStrikes[monthIndex];
    const prevStrike = prev?.fixedStrikes[monthIndex];

    const nextLabel = next?.label;
    const prevLabel = prev?.label;
    const label = column.label;

    const isCappedCall = isCall && !isCap && hasNext;
    const isCappedPut = isPut && !isCap && hasPrev;
    const isCallCap = isCall && isCap;
    const isPutCap = isPut && isCap;

    if (isCallCap) {
      return this.validate(columnIndex - 1, monthIndex);
    }

    if (isPutCap) {
      return this.validate(columnIndex + 1, monthIndex);
    }

    if (isCappedCall) {
      const error = `The strike for ${label} must be less than the strike for ${nextLabel}`;
      if (strike > nextStrike) {
        this.addError(error, monthIndex);
      } else {
        this.deleteError(error, monthIndex);
      }
    }

    if (isCappedPut) {
      const error = `The strike for ${label} must be greater than the strike for ${prevLabel}`;
      if (strike < prevStrike) {
        this.addError(error, monthIndex);
      } else {
        this.deleteError(error, monthIndex);
      }
    }
  }

  private addError(error: string, monthIndex: number) {
    if (!this._errors[error]) {
      this._errors[error] = new Set([monthIndex]);
    } else {
      this._errors[error].add(monthIndex);
    }
  }

  private deleteError(error: string, monthIndex: number) {
    // Check if the error key exists in the errors object
    if (this._errors.hasOwnProperty(error)) {
      // Remove the monthIndex from the Set
      this._errors[error].delete(monthIndex);

      // If the Set is empty after removal, delete the key from the errors object
      if (this._errors[error].size === 0) {
        delete this._errors[error];
      }
    }
  }

  getMonthlyAdders(columnIndex: number) {
    const column = this.columns[columnIndex];
    const adders = column.adders;
    return adders;
  }

  setFixedStrike(value: number, columnIndex: number, month: number | null) {
    const column = this.columns[columnIndex];
    column.setFixedStrike(value, month);
    column.updateAvgAdder();
  }

  getMonthlyFixedStrikes(columnIndex: number) {
    const column = this.columns[columnIndex];
    const fixedStrikes = column.fixedStrikes;
    return fixedStrikes;
  }

  setMonthlyVolume(newVolume: number, index: number) {
    if (newVolume < 0) newVolume = 0;
    let newVolumes = [...this.volumes];
    newVolumes[index] = newVolume;
    this.setVolumes(newVolumes);
    this.updateAvgValues();
  }

  allocate(totalVolume: number) {
    if (this._allocation === null) this._allocation = DEFAULT_ALLOCATION;
    const volumes = this.allocateVolumes(this._allocation as MonthlyValues, totalVolume);
    return volumes;
  }

  allocateVolumes(allocation: MonthlyValues, totalVolume = this.totalVolume) {
    const weights = this.getWeights(allocation);

    const total = sumNumbers(weights);
    const ratios = weights.map((weight) => (total === 0 ? 0 : weight / total));
    const volumes = ratios.map((ratio) => Math.round(totalVolume * ratio));

    return volumes;
  }

  private getWeights(allocation: MonthlyValues) {
    const weights = this.months.map((month) => {
      const monthNumber = DerivativeUtil.extractMonthNumber(month);
      const weight = allocation[monthNumber] || 0;
      return weight;
    });

    return weights;
  }

  private updateAvgValues() {
    if (this.isCommonStrike) {
      this.volumeWeightStrikes();
    } else {
      this.updateAvgAdders();
    }
  }

  // Private static methods

  private static createMonths(startMonth: Date, endMonth: Date) {
    const duration = durationInMonths(startMonth, endMonth);
    const months = [];
    for (let i = 0; i <= duration; i++) {
      const month = addMonths(startMonth, i);
      months.push(month);
    }

    return months;
  }

  private static createColumns(
    components: any[],
    prices: number[],
    structureDirection: TransactionDirection,
    volumes: number[],
    isCommonStrike: boolean,
  ) {
    const columns: VanillaComponent[] = components.map((component, i) => {
      const { componentTypeId, directionId, componentId, isLinked, isCap } = component;
      const label = ComponentType[componentTypeId];

      const componentDirection = directionId as TransactionDirection;

      const columnIsLinked = isLinked || components[i + 1]?.isLinked;

      const column = new VanillaComponent(
        componentId,
        componentTypeId,
        componentDirection,
        structureDirection,
        label,
        prices,
        columnIsLinked,
        isCap,
        volumes,
        isCommonStrike,
      );
      return column;
    });

    return columns;
  }
}
