import { useEffect, useRef, useState } from "react";
import { Button, ChipList } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridCellProps, GridColumn, GridItemChangeEvent } from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import marginsApi from "../../../api/margins";
import { useApi } from "../../../hooks/useApi";
import DateUtility from "../../../utilities/dateUtilities";
import {
  averageByProperty,
  maxByProperty,
  minByProperty,
} from "../../../utilities/objectUtilities";
import { CustomGridCell } from "../../grid";
import { LoadingIndicator } from "../../LoadingIndicator";
import { BetaValue, BetaValueType } from "./BetaValue";
import { NumberEditGridCell } from ".";
import InfoIconAndTooltip from "../../utilities/InfoIconAndTooltip";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

interface IBetaValuesEditorProps {
  marginId: number;
  disabled: boolean;
  onDataUpdate?: (data: BetaValue[]) => void;
}

export const BetaValuesEditor = ({ marginId, disabled, onDataUpdate }: IBetaValuesEditorProps) => {
  const getBetaCubesApi = useApi(marginsApi.getBetaCubes);
  const [loading, setLoading] = useState(false);
  const [displayField, setDisplayField] = useState(BetaValueType.TwoWay);
  const [betas, setBetas] = useState<Record<number, any>>({});
  const [updatedData, setUpdatedData] = useState<BetaValue[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [gridData, setGridData] = useState<any[]>([]);
  const [editId, setEditId] = useState<number>();

  const [betasUsed, setBetasUsed] = useState<number[]>([]);
  const [betasToAdd, setBetasToAdd] = useState<any[]>([]);
  const [selectedBeta, setSelectedBeta] = useState<any>();

  const _export: any = useRef(null);
  const _grid: any = useRef();
  const singleFeeColumnHeader = " Constant";

  useEffect(() => {
    setEditId(0);
    getBetaCubesApi.request(marginId);
  }, [marginId]);

  useEffect(() => {
    setLoading(getBetaCubesApi.loading);
    setUpdatedData(getBetaCubesApi.data?.betaCubes ?? []);

    const sortedBetas = (getBetaCubesApi.data?.betas ?? []).sort((a: any, b: any) =>
      a.hasTermStructure === b.hasTermStructure
        ? a.name.localeCompare(b.name)
        : a.hasTermStructure === false
          ? 1
          : -1,
    );

    setBetas(
      sortedBetas.reduce(
        (acc: any, { betaId, ...others }: any, index: number) => {
          acc[betaId] = { ...others, rank: index + 1 };
          return acc;
        },
        {} as Record<number, { name: string; hasTermStructure: boolean; units: string }>,
      ),
    );
  }, [getBetaCubesApi.loading, getBetaCubesApi.data]);

  useEffect(() => {
    if (onDataUpdate) onDataUpdate(updatedData);
  }, [updatedData]);

  useEffect(() => {
    if (updatedData) {
      const groupedValues: Record<string, any> = {};
      const colData: Record<string, any> = {};
      const betaIdList = new Set<number>();

      updatedData.forEach((betaValue) => {
        const { betaId, betaCubeId, contractMonth } = betaValue;
        const key = contractMonth ?? singleFeeColumnHeader;
        const { name, shortName, hasTermStructure, rank, units } = betas[betaId];
        colData[shortName] = { betaId, name, shortName, rank, units };
        betaIdList.add(betaId);

        if (!groupedValues[key]) groupedValues[key] = { contractMonth: key };
        groupedValues[key][shortName] = {
          betaId,
          betaCubeId,
          isEditable:
            (hasTermStructure && key !== singleFeeColumnHeader) ||
            (!hasTermStructure && key === singleFeeColumnHeader),
          isEditing: betaId === editId,
          value: betaValue[displayField],
        };
      });

      setBetasUsed([...betaIdList]);
      setColumns(Object.values(colData).sort((a, b) => a.rank - b.rank));
      setGridData(Object.values(groupedValues).sort((a, b) => a.contractMonth.localeCompare(b.contractMonth))
      );
    }

    // HIDING FOR NOW. Will show again once the data grid is transposed properly.
    // const averages: any = {},
    //   minima: any = {},
    //   maxima: any = {};
    // cols.forEach((c) => {
    //   const validItems = items.filter((i) => i[c] != null);
    //   averages[c] = averageByProperty(validItems, c);
    //   minima[c] = minByProperty(validItems, c);
    //   maxima[c] = maxByProperty(validItems, c);
    // });

    // items.push(Object.assign({ name: "Minimum", isAggregate: true, ...minima }, { format: null }));
    // items.push(
    //   Object.assign({ name: "Average", isAggregate: true, ...averages }, { format: null }),
    // );
    // items.push(Object.assign({ name: "Maximum", isAggregate: true, ...maxima }, { format: null }));
  }, [updatedData, displayField, editId]);

  const betaTypeList = [
    { text: "Bid", value: BetaValueType.Bid },
    { text: "Ask", value: BetaValueType.Ask },
    { text: "Two-Way", value: BetaValueType.TwoWay },
  ];

  const handleItemChange = ({ dataItem, field, value, syntheticEvent }: GridItemChangeEvent) => {
    if (syntheticEvent?.type === "keydown" && typeof value === "string") {
      // This is a hack because the grid's onKeyDown event isn't useful
      processKeyDown(dataItem, field, value?.toLowerCase() || "");
      return;
    }
    if (!field) return;

    const numValue = typeof value === "number" ? value : parseFloat(value) || null;
    const validMessage = valueValidator(numValue);

    const itemCopy = { ...dataItem };
    itemCopy[field].value = numValue;
    if (validMessage) itemCopy[field + "Valid"] = validMessage;
    else delete itemCopy[field + "Valid"];

    let dataCopy = [...gridData];
    const index = dataCopy.findIndex((d) => d.contractMonth === dataItem.contractMonth);
    if (index >= 0) {
      dataCopy[index] = itemCopy;
      setGridData(dataCopy);
    }

    const searchContract = dataItem.contractMonth === singleFeeColumnHeader ? null : dataItem.contractMonth;

    if (!validMessage) {
      const newData: BetaValue[] = updatedData.map((item: BetaValue) => {
        return item.contractMonth === searchContract &&
          item.betaId === dataItem[field].betaId
          ? { ...item, [displayField]: numValue, isModified: true }
          : item;
      });

      setUpdatedData(newData);
    }
  };

  const processKeyDown = (dataItem: any, field: string | undefined, keyPress: string) => {
    const obj = dataItem[field || ""];
    const { betaId, value } = obj;
    const index = columns.findIndex((c) => c.betaId === betaId);
    if (!betaId || index < 0) return;

    if (keyPress === "alt+enter") {
      const validMessage = valueValidator(value);
      if (validMessage.length > 0) return;

      const newData: BetaValue[] = updatedData.map((item: BetaValue) =>
        item.betaId === betaId && item.contractMonth && item.contractMonth >= dataItem.contractMonth
          ? { ...item, [displayField]: value, isModified: true }
          : item,
      );
      setUpdatedData(newData);
    } else if (keyPress === "enter") {
      const newIndex = index < columns.length - 1 ? index + 1 : 0;
      setEditId(columns[newIndex].betaId);
    } else if (keyPress === "shift+enter") {
      const newIndex = index > 0 ? index - 1 : columns.length - 1;
      setEditId(columns[newIndex].betaId);
    }
  };

  const valueValidator = (value: number | null) =>
    value != null && value < 0 ? "Must be non-negative" : "";

  const onClickAddFee = (e: any) => {
    e.preventDefault();

    const betaList = Object.keys(betas)
      .map((str) => parseInt(str))
      .filter((k) => !betasUsed.includes(k))
      .map((k) => ({ id: k, value: betas[k] }))
      .sort((a, b) => a.value.name.localeCompare(b.value.name));

    setBetasToAdd(betaList);
  };

  const addNewBeta = () => {
    const dataCopy = [...updatedData];

    if (selectedBeta.value.hasTermStructure) {
      gridData
        .map((g) => g.contractMonth)
        .filter((m) => m !== singleFeeColumnHeader)
        .forEach((m) =>
          dataCopy.push({
            betaCubeId: 0,
            betaId: selectedBeta.id,
            contractMonth: m,
            bidValue: 0,
            askValue: 0,
            twoWayValue: 0,
            isModified: true,
          }),
        );
    } else {
      dataCopy.push({
        betaCubeId: 0,
        betaId: selectedBeta.id,
        contractMonth: null,
        bidValue: 0,
        askValue: 0,
        twoWayValue: 0,
        isModified: true,
      });
    }

    setUpdatedData(dataCopy);
    setBetasToAdd([]);
    setSelectedBeta("");
  };

  const excelExport = () => {
    if (_export.current && _grid.current)
      _export.current.save(gridData.slice(0, -3), _grid.current.columns);
  };

  const CustomRowHeaderCell = (props: GridCellProps) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const format = props.format || "g";

    const dateValue = DateUtility.format(value, format);

    return (
      <CustomGridCell {...props}>
        <span style={{ fontWeight: "bolder" }}>{dateValue.length ? dateValue : value}</span>
      </CustomGridCell>
    );
  };

  const HelpHeaderCell = () => {
    return (
      <Label>
        <InfoIconAndTooltip position="right">
          <div>Click on a column header to edit the fee values</div>

          <GridLayout style={{ marginTop: "0.5rem" }}>
            <GridLayoutItem col={1} row={1}>Tab</GridLayoutItem>
            <GridLayoutItem col={2} row={1}>Edit the next value down the column</GridLayoutItem>
            <GridLayoutItem>Shift+Tab</GridLayoutItem>
            <GridLayoutItem>Edit the previous value in the column</GridLayoutItem>
            <GridLayoutItem>Enter</GridLayoutItem>
            <GridLayoutItem>Edit the next column</GridLayoutItem>
            <GridLayoutItem>Shift+Enter</GridLayoutItem>
            <GridLayoutItem>Edit the previous column</GridLayoutItem>
            <GridLayoutItem>Alt+Enter</GridLayoutItem>
            <GridLayoutItem>Repeat the current value down the remainder of the column</GridLayoutItem>
          </GridLayout>
        </InfoIconAndTooltip>
      </Label>
    );
  };

  const CustomColumnHeaderCell = (props: any) => {
    const { title, units, isEditing, onClick } = props;
    return (
      <th style={{ color: isEditing ? "green" : "inherit" }}>
        <span style={{ textWrap: "wrap", textDecoration: "underline" }}>
          <a href="#" title="Click to Edit" onClick={onClick}>
            <span style={{ fontWeight: "bold" }}>{title}</span>
          </a>
        </span>
        <div>{units ? ` (${units})` : ""}</div>
      </th>
    );
  };

  return (
    <div>
      <LoadingIndicator loading={loading} />

      {!loading && (
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <ExcelExport ref={_export} />

          <div style={{ display: "flex", margin: "0.5rem" }}>
            <div style={{ flexBasis: "35%" }}>
              <Button
                fillMode="outline"
                iconClass="k-icon k-i-plus"
                size="small"
                themeColor="primary"
                title="Add Fee"
                onClick={onClickAddFee}
              />

              <Button
                fillMode="outline"
                iconClass="k-icon k-i-download"
                size="small"
                themeColor="primary"
                title="Export"
                onClick={excelExport}
              />

              {/* <Button
                  fillMode="outline"
                  iconClass="k-icon k-i-upload"
                  size="small"
                  themeColor="primary"
                  title="Import"
                  onClick={}
                /> */}
            </div>
            <div style={{ flexBasis: "35%" }}>
              <ChipList
                data={betaTypeList}
                selection={"single"}
                value={displayField}
                disabled={disabled}
                onChange={(e) => setDisplayField(e.value)}
              />
            </div>
          </div>

          {columns.length && gridData.length ? (
            <Grid ref={_grid} data={gridData} resizable={true} onItemChange={handleItemChange}>
              <GridColumn
                field={"contractMonth"}
                width={110}
                title={" "}
                format={"MMM yyyy"}
                cell={CustomRowHeaderCell}
                headerCell={HelpHeaderCell}
              />

              {columns.map((c, i) => (
                <GridColumn
                  key={i + 1}
                  field={c.shortName}
                  width={120}
                  title={c.name}
                  editable={!disabled}
                  cell={NumberEditGridCell}
                  headerCell={(props) => (
                    <CustomColumnHeaderCell
                      title={props.title}
                      units={c.units}
                      isEditing={editId === c.betaId}
                      onClick={() => setEditId(c.betaId)}
                    />
                  )}
                />
              ))}
            </Grid>
          ) : (
            <div>No margin values to display</div>
          )}
        </Tooltip>
      )}

      {betasToAdd.length > 0 && (
        <Dialog title={"Add Fee"} onClose={() => setBetasToAdd([])}>
          <Label style={{ paddingRight: 5 }}>Fee Type</Label>
          <DropDownList
            data={betasToAdd}
            textField="value.name"
            dataItemKey="id"
            style={{ width: 350 }}
            onChange={(e) => setSelectedBeta(e.target.value)}
          />

          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              gap: 10,
              marginTop: 5,
            }}
          >
            <Button size="small" onClick={() => setBetasToAdd([])}>
              Cancel
            </Button>
            <Button size="small" themeColor="primary" disabled={!selectedBeta} onClick={addNewBeta}>
              Add
            </Button>
          </div>
        </Dialog>
      )}
    </div>
  );
};
