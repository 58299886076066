import { useEffect, useRef, useState } from "react";
import { StrikeType, TransactionDirection } from "../utilities/vanilla/vanillaEnums";
import { VanillaUtil } from "../utilities/vanilla/vanillaUtil";

// See: https://stackoverflow.com/questions/66062826/is-there-a-react-way-to-store-a-mutable-class-instance-objects-in-state

// Persist calculator between renders
function useVanillaRef(
  components: any[],
  startMonth: Date | null,
  endMonth: Date | null,
  pricesArray: number[],
  structureDirection: TransactionDirection,
  isCommonStrike: boolean,
) {
  const vanillaRef = useRef<VanillaUtil>();
  if (
    !vanillaRef.current &&
    components.length &&
    pricesArray.length &&
    startMonth !== null &&
    endMonth !== null
  ) {
    vanillaRef.current = new VanillaUtil(
      components,
      startMonth,
      endMonth,
      pricesArray,
      structureDirection,
      isCommonStrike,
    );
  }
  return vanillaRef.current;
}

// trigger re-renders and get updated values when needed
export function useVanillaUtil(
  components: any[],
  startMonth: Date | null,
  endMonth: Date | null,
  pricesArray: number[],
  structureDirection: TransactionDirection,
  isCommonStrike: boolean = true,
) {
  const vanillaUtil = useVanillaRef(
    components,
    startMonth,
    endMonth,
    pricesArray,
    structureDirection,
    isCommonStrike,
  );
  const [isLoaded, setIsLoaded] = useState(false);

  const [values, setValues] = useState<any>(null);

  useEffect(() => {
    if (vanillaUtil !== undefined && !isLoaded) {
      updateValues(vanillaUtil);
      setIsLoaded(true);
    }
  }, [vanillaUtil]);

  if (!vanillaUtil || values === null || Object.keys(values).length == 0) {
    return null;
  }

  return {
    ...values,
    setTotalVolume: (value: number) => {
      vanillaUtil.setTotalVolume(value);
      updateValues(vanillaUtil);
    },
    setVolume: (value: number, index: number) => {
      vanillaUtil.setMonthlyVolume(value, index);
      updateValues(vanillaUtil);
    },
    setStrike: (
      value: number,
      columnIndex: number,
      strikeType: StrikeType,
      month: number | null = null,
    ) => {
      vanillaUtil.setStrike(value, columnIndex, strikeType, month);
      updateValues(vanillaUtil);
    },
    setAllocation: (newAllocation: any) => {
      vanillaUtil.setAllocation(newAllocation);
      updateValues(vanillaUtil);
    },
    allocateValues: (newAllocation: any) => {
      return vanillaUtil.allocateVolumes(newAllocation);
    },
    setDirection(newDirection: TransactionDirection) {
      vanillaUtil.direction = newDirection;
      updateValues(vanillaUtil);
    },
    setIsCommonStrike(isCommonStrike: boolean) {
      vanillaUtil.isCommonStrike = isCommonStrike;
      updateValues(vanillaUtil);
    },
    setPrices(prices: number[]) {
      vanillaUtil.prices = prices;
      updateValues(vanillaUtil);
    },
    setStrikes(strikes: number[][]) {
      vanillaUtil.strikes = strikes;
      updateValues(vanillaUtil);
    },
    setSwapLevels(swapLevels: number[]) {
      vanillaUtil.swapLevels = swapLevels;
      updateValues(vanillaUtil);
    },
    update: () => {
      updateValues(vanillaUtil);
    },
  };

  function updateValues(vanillaUtil: VanillaUtil) {
    if (!vanillaUtil) return {};
    const newValues = getValues(vanillaUtil);
    setValues(newValues);
  }
}

function getValues(vanillaUtil: VanillaUtil) {
  return {
    totalVolume: vanillaUtil.totalVolume,
    volumes: vanillaUtil.volumes,
    months: vanillaUtil.months,
    columns: vanillaUtil.columns,
    errors: vanillaUtil.errors,
  };
}
