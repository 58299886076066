// Converts an object with key-value pairs to an array of
// objects each with value and label properties
export const objectToLookup = (obj: any, asIs?: boolean) => {
  return Object.keys(obj || {})
    .map((key) => ({
      value: asIs ? key : parseInt(key),
      label: obj[key],
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const sumNumbers = (arr: number[]): number => {
  if (!arr) return 0;

  return arr.reduce((sum: number, num: number) => sum + num, 0);
};

export const averageNumbers = (arr: number[]): number => {
  if (!arr || arr.length === 0) return 0;

  return sumNumbers(arr) / arr.length;
};

export const sumByProperty = (arr: any[], property: string): number => {
  if (!arr || !property || arr.length === 0) return 0;

  return arr.reduce((sum: number, current: any) => sum + (current[property] || 0), 0);
};

export const minByProperty = (arr: any[], property: string): number => {
  if (!arr || !property || arr.length === 0) return 0;

  const max = arr.reduce((prev: any, current: any) =>
    prev && prev[property] < current[property] ? prev : current,
  );
  return max[property];
};

export const maxByProperty = (arr: any[], property: string): number => {
  if (!arr || !property || arr.length === 0) return 0;

  const max = arr.reduce((prev: any, current: any) =>
    prev && prev[property] > current[property] ? prev : current,
  );
  return max[property];
};

export const averageByProperty = (arr: any[], property: string): number => {
  const sum = sumByProperty(arr, property);
  const count = arr.length;

  return count === 0 ? 0 : sum / count;
};

export const groupByProperty = (arr: any[], property: string): any => {
  if (!arr || !property) return {};

  return arr.reduce((prev, curr) => {
    return {
      ...prev,
      [curr[property]]: [...(prev[curr[property]] || []), curr],
    };
  }, {});
};

export const intersection = (arr1: any[], arr2: any[]): any[] => {
  return arr1 && arr2 ? arr1.filter((item) => arr2.includes(item)) : [];
};

export const includesAll = (arr: any[], values: any[]) => {
  return arr && values ? values.every((v) => arr.includes(v)) : false;
};

export const getValueFromPath = (obj: any, path: string): any => {
  const keys = path.split(".");
  let result = obj;

  for (const key of keys) {
    if (result && result.hasOwnProperty(key)) result = result[key];
    else return undefined;
  }

  return result;
};

export const filterObjectByKeys = (
  obj: Record<string, any>,
  keysToKeep: string[],
): Record<string, any> => {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => keysToKeep.includes(key)));
};
