import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useState } from "react";
import { IconToggleButton } from "../utilities";
import CanopyDateRangePicker from "../functional/CanopyDateRangePicker";
import { QuoteStatus } from "../../types/quoteStatus";

export const FilterComponent = ({
  maxDate,
  commodityTypes,
  productTypes,
  selectedDates,
  selectedCommodityTypes,
  selectedProducts,
  selectedStatuses,
  searchText,
  onCommodityClick,
  onProductClick,
  onClearFilters,
  onDateChange,
  onStatusCheck,
  onTextChange,
}: any) => {
  const [hoveredCommodity, setHoveredCommodity] = useState("");
  const [hoveredProduct, setHoveredProduct] = useState("");

  const hasCommodityTypes = commodityTypes && Object.keys(commodityTypes).length > 1;
  const hasProductTypes = productTypes && Object.keys(productTypes).length > 1;

  const rowStyle = { marginTop: ".5rem" };

  return (
    <Tooltip anchorElement="target" parentTitle={true}>
      <div style={{ margin: ".25rem" }}>
        <div>
          <CanopyDateRangePicker
            max={maxDate}
            value={selectedDates}
            startDateInputSettings={{ label: "Date Range" }}
            endDateInputSettings={{ label: "" }}
            onChange={onDateChange}
          />
        </div>

        <div style={rowStyle}>
          {(hasCommodityTypes || hasProductTypes) && <Label>Product</Label>}
        </div>

        {hasCommodityTypes && (
          <div>
            {Object.keys(commodityTypes).map((type: string, index) => {
              const title = commodityTypes[type];

              return (
                <IconToggleButton
                  key={index}
                  item={type}
                  type={"commodity"}
                  title={title}
                  hoveredItem={hoveredCommodity}
                  selectedItemTypes={selectedCommodityTypes}
                  onClick={onCommodityClick}
                  setHoveredItem={setHoveredCommodity}
                />
              );
            })}
          </div>
        )}

        {hasProductTypes && (
          <div>
            {Object.keys(productTypes).map((key: any, index) => {
              const title = productTypes[key];

              return (
                <IconToggleButton
                  key={index}
                  item={key}
                  type={"instrument"}
                  title={title}
                  hoveredItem={hoveredProduct}
                  selectedItemTypes={selectedProducts}
                  onClick={onProductClick}
                  setHoveredItem={setHoveredProduct}
                />
              );
            })}
          </div>
        )}

        <div style={rowStyle}>
          <Label>Status</Label>
          <div style={{ display: "flex" }}>
            {Object.values(QuoteStatus).map((item: string, index: number) => (
              <span style={{ paddingRight: "1rem" }} key={index}>
                <Checkbox
                  id={`status_${index}`}
                  name={"status"}
                  label={item}
                  value={item}
                  checked={selectedStatuses.includes(item)}
                  onChange={(e) => onStatusCheck(e, item)}
                />
              </span>
            ))}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: 5,
            paddingTop: "1rem",
          }}
        >
          <Label>Search</Label>
          <Input value={searchText} onChange={(e: any) => onTextChange(e.target.value)} />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            ...rowStyle,
          }}
        >
          <div>
            <Button
              iconClass="k-icon k-i-filter-clear"
              themeColor="primary"
              fillMode="outline"
              title="Clear Filters"
              size="small"
              onClick={onClearFilters}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
