import { PermissionUtility, RoleUtility } from "./rolePermissionUtilities";

export class UserPermissions {
  canAdminCompanies: boolean = false;
  canAdminUsers: boolean = false;
  canApproveTrades: boolean = false;
  canConfigureMargins: boolean = false;
  canConfigureProducts: boolean = false;
  canSubmitQuotes: boolean = false;

  constructor(permissions: number[]) {
    this.canAdminCompanies = PermissionUtility.canAdminCompanies(permissions);
    this.canAdminUsers = PermissionUtility.canAdminUsers(permissions);
    this.canApproveTrades = PermissionUtility.canApproveTrades(permissions);
    this.canConfigureMargins = PermissionUtility.canConfigureMargins(permissions);
    this.canConfigureProducts = PermissionUtility.canConfigureProducts(permissions);
    this.canSubmitQuotes = PermissionUtility.canSubmitQuotes(permissions);
  }
}

export class UserRoles {
  isRiskTaker: boolean = false;
  isBroker: boolean = false;
  isClient: boolean = false;
  isFronter: boolean = false;

  constructor(roles: string[]) {
    this.isRiskTaker = RoleUtility.hasRiskTakerRole(roles);
    this.isBroker = RoleUtility.hasBrokerRole(roles);
    this.isClient = RoleUtility.hasClientRole(roles);
    this.isFronter = RoleUtility.hasFronterRole(roles);
  }

  public canSubmitQuotes = (companyRole: string): boolean =>
    this.isClient ||
    (this.isBroker && RoleUtility.isClient(companyRole)) ||
    (this.isRiskTaker && RoleUtility.isClient(companyRole));
}
