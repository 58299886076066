import { PropsWithChildren, createContext, useEffect, useState } from "react";
import companyApi from "../../api/company";
import { useFetch } from "../../hooks/useApi";
import { UserCompany } from "./UserCompanyContextProvider";

interface ICompaniesContext {
  context: UserCompany[];
  setContext: (obj: any) => void;
}

export const CompaniesContext = createContext<ICompaniesContext>({} as ICompaniesContext);

export const CompaniesContextProvider = ({ children }: PropsWithChildren) => {
  const { data } = useFetch(companyApi.get);
  const [context, setContext] = useState<UserCompany[]>([]);

  useEffect(() => {
    setContext(data?.companies?.map((c: any) => UserCompany.create(c)) || []);
  }, [data]);

  return (
    <CompaniesContext.Provider value={{ context, setContext }}>
      {children}
    </CompaniesContext.Provider>
  );
};
