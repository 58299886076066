import { Button } from "@progress/kendo-react-buttons";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { Company } from "../Company";
import { Configuration } from "./Configuration";
import { ConfigWorkflow } from "./ConfigurationEnums";
import {
  CompanySelectionStep,
  ConfigurationsStartStep,
  MarginSelectionStep,
  ProductMarginsSelectionStep,
  ProductsSelectionStep,
  SummaryStep,
} from ".";
import { StepsInterface } from "../../../pages/quotes/workflow";
import { Commodity } from "../../quotes";

interface IConfigFormProps {
  step: number;
  formRenderProps: FormRenderProps;
  steps: StepsInterface[];
  isSubmitStep: boolean;
  isPreviousStepsValid: boolean;
  businessTypes: Record<number, string>;
  companies: Company[];
  companyId: number;
  commodities: Commodity[];
  commodityTypes: Record<string, string>;
  configurations: Record<number, Configuration[]>;
  margins: any[];
  marginField: string;
  productTypes: Record<string, string>;
  onMarginsUpdate: () => void;
  onPrevClick: (event: any) => void;
  onResetForm: (workflow: string) => void;
}

export const ConfigurationFormRender = (props: IConfigFormProps) => {
  const {
    step,
    formRenderProps,
    steps,
    isSubmitStep,
    isPreviousStepsValid,
    onPrevClick,
    onResetForm,
  } = props;
  const isSubmitDisabled = !(isPreviousStepsValid && formRenderProps.valid);
  const stepsCount = steps.filter((s) => s.visible).length;
  const workflow = formRenderProps.valueGetter("workflow");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: 50,
        marginLeft: 50,
      }}
    >
      {step === 0 && <ConfigurationsStartStep onWorkflowChange={onResetForm} />}

      <FormElement>
        {workflow === ConfigWorkflow.Products && (
          <>
            {step === 1 && <ProductsSelectionStep {...props} />}
            {step === 2 && <CompanySelectionStep {...props} showAction={true} selectMultiple={true} />}
            {step === 3 && stepsCount === 5 && <ProductMarginsSelectionStep {...props} />}
            {step === stepsCount - 1 && <SummaryStep {...props} />}
          </>
        )}

        {workflow === ConfigWorkflow.Companies && (
          <>
            {step === 1 && <CompanySelectionStep {...props} showAction={true} />}
            {step === 2 && <ProductsSelectionStep {...props} />}
            {step === 3 && stepsCount === 5 && <ProductMarginsSelectionStep {...props} />}
            {step === stepsCount - 1 && <SummaryStep {...props} />}
          </>
        )}

        {workflow === ConfigWorkflow.Margins && (
          <>
            {step === 1 && <MarginSelectionStep {...props} />}
            {step === 2 && <ProductsSelectionStep {...props} />}
            {step === 3 && <CompanySelectionStep {...props} selectMultiple={true} />}
            {step === 4 && <SummaryStep {...props} />}
          </>
        )}

        <span style={{ marginTop: 20 }} className={"k-form-separator"} />
        <div className="multi-step-form-footer" style={{ paddingTop: 10 }}>
          <span className="form-step-label k-button-text">
            Step {step + 1} of {steps.length}
          </span>
          <div>
            {step !== 0 ? (
              <Button style={{ marginRight: "16px" }} onClick={onPrevClick}>
                Previous
              </Button>
            ) : undefined}
            <Button
              themeColor={"primary"}
              disabled={isSubmitDisabled}
              onClick={formRenderProps.onSubmit}
            >
              {isSubmitStep ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </FormElement>
    </div>
  );
};
