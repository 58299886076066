import { Error } from "@progress/kendo-react-labels";
import { useState } from "react";
import { AccumulatorQuoteSummary, AccumulatorStepperHeader } from ".";
import accumulatorApi from "../../../api/accumulator";
import quotesApi from "../../../api/quotes";
import { useFetch, useFetchWhen } from "../../../hooks/useApi";
import DateUtility from "../../../utilities/dateUtilities";
import { objectToLookup, sumByProperty } from "../../../utilities/objectUtilities";
import { LoadingIndicator } from "../../LoadingIndicator";
import { IntradayCurvesSlippageChart } from "../../charts";
import { HeaderTextSelector } from "../../utilities";
import { ICommodity } from "../Commodity";
import { IInstrument } from "../Instrument";
import { QuoteDetailsHeader, QuoteSummaryCard } from "../quoteDetails";

type Props = {
  quote: any;
  refetch: () => void;
  commodity: ICommodity;
  instrument: IInstrument;
  isRiskTaker: boolean;
  onReject: () => void;
};

const QUOTE_VIEW = "Quote";
const MARKETS_VIEW = "Slippage";

export const AccumulatorQuoteDetails = ({
  quote,
  refetch,
  commodity,
  instrument,
  isRiskTaker,
  onReject,
}: Props) => {
  const [product] = useState<any>({ instrument, commodity });
  const [selectedView, setSelectedView] = useState(QUOTE_VIEW);
  const [chartLoading, setChartLoading] = useState(false);

  const { quoteId, quoteName, quoteDate, pricingId, transactionDetails } = quote;
  const {
    data: quoteDetails,
    loading: quoteLoading,
    error,
  } = useFetch(accumulatorApi.getQuoteDetails, quoteId);
  const { data: pricingDetails, loading: pricingLoading } = useFetch(
    accumulatorApi.getPricingDetails,
    quoteId,
    pricingId,
  );
  const { data: lookupData, loading: lookupsLoading } = useFetch(
    accumulatorApi.getLookups,
    instrument.id,
  );
  const { data: slippage } = useFetchWhen(quotesApi.getSlippage, isRiskTaker, quoteId, pricingId);

  const isLoading = quoteLoading || lookupsLoading || pricingLoading;
  const fairValue = sumByProperty(transactionDetails, "fairValuePrice");
  const details = Object.assign({}, quoteDetails?.quote || {}, pricingDetails?.details || {}, {
    fairValuePrice: fairValue,
  });
  const contract = DateUtility.formatDateMY(details?.contractDate);

  const totalVolume = details.volume;

  //TODO: Refactor this repetitive lookup code
  const formatLookupData = (data: any[]) => {
    return objectToLookup(data, false)
      .map((d: any) => {
        return { ...d, text: d.label };
      })
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  };

  const lookups = {
    doubleUpTypes: formatLookupData(lookupData?.doubleUpTypes),
    knockOutTypes: formatLookupData(lookupData?.knockOutTypes),
    types: formatLookupData(lookupData?.types),
    settlementTypes: formatLookupData(lookupData?.settlementTypes).sort((a, b) =>
      a.text > b.text ? -1 : 1,
    ),
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ flexBasis: "80%" }}>
        <LoadingIndicator loading={isLoading} />
        <Error>{error}</Error>

        {!isLoading && (
          <QuoteDetailsHeader
            quote={quote}
            commodity={commodity}
            instrumentName={instrument.name}
            isRiskTaker={isRiskTaker}
          >
            <AccumulatorStepperHeader
              quoteId={quoteId}
              quoteName={quoteName}
              product={product}
              contract={contract}
              lookups={lookups}
              values={details}
            />
          </QuoteDetailsHeader>
        )}

        <div style={{ marginTop: 20 }}>
          {isRiskTaker && (
            <HeaderTextSelector
              values={[QUOTE_VIEW, MARKETS_VIEW]}
              onChange={setSelectedView}
              selected={selectedView}
            />
          )}
        </div>

        {selectedView === QUOTE_VIEW && (
          <QuoteSummaryCard
            quote={quote}
            onReject={onReject}
            getQuoteDetails={refetch}
            totalVolume={totalVolume}
          >
            <LoadingIndicator loading={isLoading} />

            {!isLoading && (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <AccumulatorQuoteSummary
                  quote={details}
                  units={commodity.units}
                  showInputs={true}
                />
              </div>
            )}
          </QuoteSummaryCard>
        )}

        {selectedView === MARKETS_VIEW && (
          <>
            <LoadingIndicator loading={chartLoading} />
            <IntradayCurvesSlippageChart
              commodity={commodity}
              showTitle={true}
              quoteSlippage={slippage?.quoteSlippage}
              onLoading={setChartLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};
