import { averageNumbers, sumNumbers } from "../objectUtilities";
import { ComponentType, StrikeType, TransactionDirection } from "./vanillaEnums";

// TODO: StrikeType becomes a property of VanillaComponent
// Any setters that have more than one parameter should have non-primary parameters becomes properties of the class?
// I could have a monthly strike class that stores one vol, strike, and adder for each month. It could compute it's own adder, etc
export class VanillaComponent {
  prices: number[];
  componentId: number;
  _label: string;
  columnType: ComponentType;
  fixedStrikes: number[];
  isLinked: boolean;
  isCap: boolean;
  _componentDirection: TransactionDirection;
  _structureDirection: TransactionDirection;
  volumes: number[] = [];
  _isCommonStrike: boolean;

  get isCommonStrike(): boolean {
    return this._isCommonStrike;
  }
  set isCommonStrike(isCommonStrike: boolean) {
    this._isCommonStrike = isCommonStrike;
    if (isCommonStrike) {
      this.volumeWeightStrikes();
    }
  }

  get avgFixedStrike(): number {
    const avg = VanillaComponent.getVolumeWeightedAverage(this.volumes, this.fixedStrikes);
    return avg;
  }
  set avgFixedStrike(newValue: number) {
    this.setAllMonthlyFixedStrikes(newValue);
    this.updateAvgAdder();
  }

  get avgPrice(): number {
    return averageNumbers(this.prices);
  }

  get avgFixedStrikeAsPercent(): number {
    const avgPrice = this.avgPrice;
    if (avgPrice === 0) return 0;

    const ratio = this.avgFixedStrike / avgPrice;
    switch (this.columnType) {
      case ComponentType.Call:
        return ratio - 1;
      case ComponentType.Put:
        return 1 - ratio;
      default:
        return 0;
    }
  }

  get label(): string {
    return `${TransactionDirection[this.direction]} ${this._label}`;
  }

  get adders(): number[] {
    const adders = this.prices.map((price, index) => {
      const fixedStrike = this.fixedStrikes[index];
      let adder = 0;
      if (this.columnType === ComponentType.Call) {
        adder = fixedStrike - price;
      } else if (this.columnType === ComponentType.Put) {
        adder = price - fixedStrike;
      }
      return adder;
    });
    return adders;
  }

  _avgAdder: number;
  get avgAdder(): number {
    return this._avgAdder;
  }

  set avgAdder(adder: number) {
    this._avgAdder = adder;
    this.prices.forEach((price, index) => {
      let fixedStrike = 0;
      if (this.columnType === ComponentType.Call) {
        fixedStrike = price + adder;
      } else {
        fixedStrike = price - adder;
      }
      this.setFixedStrike(fixedStrike, index);
    });
    if (this.isCommonStrike) {
      this.volumeWeightStrikes();
    }
  }

  get direction(): TransactionDirection {
    if (this._structureDirection === TransactionDirection.Long) {
      return this._componentDirection;
    } else {
      if (this._componentDirection === TransactionDirection.Long) {
        return TransactionDirection.Short;
      } else {
        return TransactionDirection.Long;
      }
    }
  }

  set structureDirection(direction: TransactionDirection) {
    this._structureDirection = direction;
  }

  constructor(
    componentId: number,
    columnType: ComponentType,
    componentDirection: TransactionDirection,
    structureDirection: TransactionDirection,
    label: string,
    prices: number[],
    isLinked: boolean,
    isCap: boolean,
    volumes: number[],
    isCommonStrike: boolean,
  ) {
    this.componentId = componentId;
    this.columnType = columnType;
    this._componentDirection = componentDirection;
    this._structureDirection = structureDirection;
    this._label = label;
    this.prices = prices;
    this.fixedStrikes = [...prices];
    this.isLinked = isLinked;
    this.isCap = isCap;
    this.volumes = volumes;
    this._isCommonStrike = isCommonStrike;
    this._avgAdder = VanillaComponent.getVolumeWeightedAverage(this.volumes, this.adders);
  }

  updateAvgAdder() {
    this._avgAdder = VanillaComponent.getVolumeWeightedAverage(this.volumes, this.adders);
  }

  getValue(strikeType: StrikeType, month: number | null = null) {
    if (strikeType === StrikeType.Fixed) {
      return this.getFixedStrike(month);
    } else {
      return this.getAdder(month);
    }
  }

  getAdder(month: number | null = null) {
    if (month === null) return this.avgAdder;

    const monthlyValue = this.adders[month];
    return monthlyValue;
  }

  getFixedStrike(month: number | null = null) {
    if (month === null) return this.avgFixedStrike;

    const monthlyValue = this.fixedStrikes[month];
    return monthlyValue;
  }

  setFixedStrike(value: number, month: number | null = null) {
    if (value <= 0) value = 0;
    if (month === null) {
      this.avgFixedStrike = value;
    } else {
      this.setMonthlyFixedStrike(value, month);
    }
  }

  setAdder(value: number, month: number | null) {
    if (month === null) {
      this.avgAdder = value;
    } else {
      month = month as number;
      this.setMonthlyAdder(value, month);
    }
  }

  setMonthlyFixedStrike(value: number, month: number) {
    this.fixedStrikes[month] = value;
    this.updateAvgAdder();
  }

  setMonthlyAdder(value: number, month: number) {
    const price = this.prices[month];

    let fixedStrike;
    if (this.columnType === ComponentType.Call) {
      fixedStrike = price + value;
    } else {
      fixedStrike = price - value;
    }
    this.fixedStrikes[month] = fixedStrike;
    this.updateAvgAdder();
  }

  setVolumes(newVolumes: number[]) {
    this.volumes = newVolumes;
  }

  volumeWeightStrikes() {
    // TODO: Can I find a way to simplify this using algebra? Perhaps some of the steps repeat themselves.

    // Preserve the current avg adder, set all monthly adders to this value.
    // Volume weight the fixed strikes.
    const originalAvgAdder = this.avgAdder;
    this.setAllMonthlyAdders(originalAvgAdder);

    const avg = VanillaComponent.getVolumeWeightedAverage(this.volumes, this.fixedStrikes);
    this.setAllMonthlyFixedStrikes(avg);
    this._avgAdder = VanillaComponent.getVolumeWeightedAverage(this.volumes, this.adders);
  }

  private setAllMonthlyFixedStrikes(newValue: number) {
    this.prices.forEach((_, i) => {
      this.setMonthlyFixedStrike(newValue, i);
    });
  }

  private setAllMonthlyAdders(newValue: number) {
    this.prices.forEach((_, i) => {
      this.setMonthlyAdder(newValue, i);
    });
  }

  private static getVolumeWeightedAverage(volumes: number[], values: number[]) {
    let totalVolume = sumNumbers(volumes);
    if (totalVolume === 0) {
      volumes = volumes.map(() => 1);
      totalVolume = sumNumbers(volumes);
    }
    const weightedValues = volumes.map((volume, i) => {
      return volume * values[i];
    });
    const totalWeightedValue = sumNumbers(weightedValues);
    return totalWeightedValue / totalVolume;
  }
}
