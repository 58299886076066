import { Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { Grid, GridCellProps, GridColumn, GridItemChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Error } from "@progress/kendo-react-labels";
import { useState } from 'react';
import useAllocations from '../../hooks/useAllocations';
import { LoadingIndicator } from '../LoadingIndicator';
import { Commands, GridCommandCell } from '../utilities/GridCommandCell';
import StringListGridCell from '../utilities/StringListGridCell';
import Allocation from './Allocation';
import { default as buildAllocationColumnProps } from './buildAllocationColumnProps';

type Props = {}

const AllocationsGrid = (props: Props) => {
  const {
    initialEditableAllocations,
    editableAllocations,
    setEditableAllocations,
    upsertAllocation,
    deleteAllocation,
    readOnlyAllocations,
    businessTypes,
    commodityTypes,
    companyId,
    isLoading,
    isError,
  } = useAllocations();

  const showBusinessTypes = businessTypes.length > 1;

  const [errorMessage, setErrorMessage] = useState("");

  // Fires when edit button is clicked. Just make the row editable.
  const handleEdit = (dataItem: Allocation) => {
    const newGridData = editableAllocations.map((row: Allocation) => {
      let newRow = row;
      if (row.allocationId === dataItem.allocationId) {
        newRow = { ...row, inEdit: true };
      }

      return newRow;
    });

    setEditableAllocations(newGridData);
  };

  const handleCreate = () => {
    const newAllocation: Allocation = {
      businessTypeIds: [],
      commodityTypes: [],
      businessTypes,
      commodityTypeObjects: commodityTypes,
      companyId,
      name: "New Allocation",
      inEdit: true,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    };

    const newGridData = [newAllocation].concat(editableAllocations);
    setEditableAllocations(newGridData);
  };

  const handleItemChange = ({
    dataItem,
    field,
    value,
  }: GridItemChangeEvent) => {
    dataItem = dataItem as Allocation;
    const newGridData = editableAllocations.map((allocation) => {
      if (allocation.allocationId === dataItem.allocationId) {
        const editedAllocation = { ...allocation, [field as string]: value };
        return editedAllocation;
      } else {
        return allocation;
      }
    });

    setEditableAllocations(newGridData);
  };

  const handleClickNew = () => {
    handleCreate();
  };

  const handleUpdate = (dataItem: Allocation) => {
    upsertAllocation(dataItem);
  };

  // Fires when canceling creation of new item
  const handleDiscard = (dataItem: Allocation) => {
    const newGridData = editableAllocations.filter(allocation => !!allocation.allocationId);
    setErrorMessage("");
    setEditableAllocations(newGridData);
  };

  // Fires when canceling edit of existing allocation
  const handleCancel = (dataItem: Allocation) => {
    const newGridData = editableAllocations.map((allocation) => {
      if (allocation.allocationId === dataItem.allocationId) {
        allocation = initialEditableAllocations.find((originalAllocation: Allocation) => originalAllocation.allocationId === allocation.allocationId);
      }
      return { ...allocation, inEdit: false };
    });
    setEditableAllocations(newGridData);
  };

  // Click the trashcan to delete an existing allocation
  const handleRemove = (allocationId: number) => {
    deleteAllocation(allocationId);
  };

  const handleAdd = (dataItem: Allocation) => {
    if (dataItem.businessTypes.length === 0) {
      setErrorMessage("Please select one or more business areas to assign to the new allocation");
    } else {
      setErrorMessage("");
      handleUpdate(dataItem);
    }
  };

  const commands: Commands = {
    onAdd: handleAdd,
    onUpdate: handleUpdate,
    onDiscard: handleDiscard,
    onCancel: handleCancel,
    onEdit: handleEdit,
    onRemove: handleRemove,
  };

  const GridCommandCellRender = (props: GridCellProps) => {
    return (
      <GridCommandCell {...props} {...commands} idField='allocationId' />
    );
  };

  const CommodityTypesGridCellRender = (props: GridCellProps) => {
    return (
      <StringListGridCell allValues={commodityTypes} {...props} />
    );
  };

  const BusinessTypesGridCellRender = (props: GridCellProps) => {
    return <StringListGridCell allValues={businessTypes} {...props} />;
  };

  const columnProps = buildAllocationColumnProps(
    showBusinessTypes,
    CommodityTypesGridCellRender,
    BusinessTypesGridCellRender
  );

  const columns = columnProps.map((props, index) => {
    return (
      <GridColumn key={index} {...props} />
    );
  });

  return (
    <>
      <LoadingIndicator loading={isLoading} />
      {isError && <Error>{"error"}</Error>}
      <Grid
        data={editableAllocations}
        editField="inEdit"
        onItemChange={handleItemChange}
      >
        <GridToolbar>
          <Typography.h6>Editable Allocations</Typography.h6>
          <Button size="small" icon="plus" title="Create New Allocation" themeColor="primary" onClick={handleClickNew}></Button>
          {!!errorMessage && <Error>{errorMessage}</Error>}
        </GridToolbar>
        <GridColumn cell={GridCommandCellRender} width={80} />
        {columns}
      </Grid>
      <div style={{ marginTop: 20 }}>
        <Typography.h6>Other Allocations</Typography.h6>
        <Grid data={readOnlyAllocations}>{columns}</Grid>
      </div>
    </>
  );
};

export default AllocationsGrid;
