import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Error, Label } from "@progress/kendo-react-labels";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RetentionCard } from ".";
import { ChartExpander, PricingHistoryDialog, QuoteExpiry } from "..";
import insuranceApi from "../../../api/insurance";
import { IApiResults, useApi } from "../../../hooks/useApi";
import DateUtility from "../../../utilities/dateUtilities";
import { getHelpUrl } from "../../../utilities/helpUtilities";
import {
  averageByProperty,
  averageNumbers,
  sumByProperty,
} from "../../../utilities/objectUtilities";
import { requiredValidator } from "../../../validators";
import { MarketStatisticsChart } from "../../charts";
import { FormInput } from "../../form";
import { Slider } from "../../slider/Slider";
import InfoIconAndTooltip from "../../utilities/InfoIconAndTooltip";
import { IInsurancePrice } from "./Types";
import InsuranceUtility from "./insuranceUtilities";

interface IInsuranceQuoteProps {
  formRenderProps: FormRenderProps;
  product: any;
  retentionLevels: any;
  marketStatsResults: IApiResults;
  showAnalytics: boolean;
}

const RetentionToolTip = () => (
  <InfoIconAndTooltip link={getHelpUrl(17197172739469)}>
    Retention (a deductible) is specified as a percentage relative to the underlying commodity.
    <i> Example:</i> If crude oil is trading at $100 and you select a retention of 10%, your
    high-price retention is $110, and your low-price retention is $90.
  </InfoIconAndTooltip>
);

const RetentionSliderToolTip = () => (
  <InfoIconAndTooltip>
    This slider adjusts your policy retention level. You can alternately enter a new retention level
    in the input box to the left.
  </InfoIconAndTooltip>
);

const CoverageSliderToolTip = () => (
  <InfoIconAndTooltip>
    This slider adjusts your policy total coverage, or limit.
    <i> Example:</i> If your original coverage was $100,000, you can set this slider to 200% to
    double the coverage to $200,000. Your original coverage and new coverage amounts are shown to
    the right.
  </InfoIconAndTooltip>
);

export const Quote = ({
  formRenderProps,
  product,
  retentionLevels,
  marketStatsResults,
  showAnalytics,
}: IInsuranceQuoteProps) => {
  const { commodity, instrument, company } = product;

  const commodityName = commodity.name;
  const ticker = commodity.abbreviation;
  const isHPP = instrument.instrumentType === "HighPriceProtection";

  const formatter = useInternationalization();
  const getPricingApi = useApi(insuranceApi.getPricing);
  const getQuoteApi = useApi(insuranceApi.getQuote);
  const saveDetailsApi = useApi(insuranceApi.saveQuoteDetails);

  const contractStart = DateUtility.formatDateYMD(formRenderProps.valueGetter("contract.start"));
  const contractEnd = DateUtility.formatDateYMD(formRenderProps.valueGetter("contract.end"));
  const [coverages, setCoverages] = useState(InsuranceUtility.getCoverageData(formRenderProps));

  const customLevel = "custom";
  const currentLevel = formRenderProps.valueGetter("selectedLevel");

  const [quoteId, setQuoteId] = useState(formRenderProps.valueGetter("quoteId") || 0);

  const [attachLevel, setAttachLevel] = useState(0);
  const [exhaustLevel, setExhaustLevel] = useState(0);

  const [chartExpanded, setChartExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dragging, setDragging] = useState(false);
  const [pricing, setPricing] = useState<any>([]);
  const [quotes, setQuotes] = useState<any[]>([]);
  const [showChart, setShowChart] = useState(false);
  const [sizes, setSizes] = useState<any>({});
  const [levels, setLevels] = useState<number[]>([]);
  const [fixedLevels, setFixedLevels] = useState<number[]>([]);
  const [levelsString, setLevelsString] = useState("");
  const [selectedLevel, setSelectedLevel] = useState(currentLevel);
  const [previousLevel, setPreviousLevel] = useState(currentLevel);
  const [showCompare, setShowCompare] = useState(currentLevel !== customLevel);
  const [expiration, setExpiration] = useState(new Date());

  const getFieldName = (size: string): string => {
    return `retentionLevels.${size}.value`;
  };

  useEffect(() => {
    const value = formRenderProps.valueGetter("retentionLevels");

    const keys = value ? Object.keys(value) : [];
    if (keys.length) {
      Object.keys(retentionLevels)
        .filter((x) => !keys.includes(x))
        .forEach((key: string) => (value[key] = retentionLevels[key]));

      setSizes(value);

      setFixedLevels(
        keys.filter((k) => k !== customLevel).map((k) => Math.floor(value[k].value * 100)),
      );
    } else {
      retentionLevels[customLevel].value = 0.2; //TODO
      formRenderProps.onChange("retentionLevels", { value: retentionLevels });
      setSizes(retentionLevels);
    }
  }, []);

  useEffect(() => {
    const levels = Object.values(sizes).map((s: any) => s.value);
    setNewLevels(levels, true);
  }, [sizes]);

  useEffect(() => {
    Object.keys(sizes).forEach((size, index) => {
      if (levels[index]) formRenderProps.onChange(getFieldName(size), { value: levels[index] });
    });
  }, [levels]);

  useEffect(() => {
    if (!getPricingApi.data?.summary) return;

    const summary = getPricingApi.data.summary;
    const retLevels = Object.assign({}, sizes);

    Object.keys(summary).forEach((level: string) => {
      retLevels[level].pricing = summary[level];
    });

    formRenderProps.onChange("retentionLevels", { value: retLevels });
    setSizes(retLevels);

    const factor = isHPP ? 1 : -1;

    Object.keys(summary).forEach((level: string) => {
      const p = retLevels[level].value;
      summary[level].details.forEach((d: IInsurancePrice) => {
        d.ul = d.attach / (1 + factor * p);
      });
    });

    setPricing(summary);

    saveDetailsApi.request({
      companyId: company.id,
      configurationId: formRenderProps.valueGetter("configurationId"),
      quoteId: quoteId,
      quoteName: formRenderProps.valueGetter("quoteName"),
      startDate: contractStart,
      endDate: contractEnd,
      totalVolume: formRenderProps.valueGetter("totalVolume"),
      totalCoverage: customCoverage[0],
      retentionLevels: Object.values(retLevels),
    });
  }, [getPricingApi.data, getPricingApi.loading]);

  useEffect(() => {
    setLoading(saveDetailsApi.loading);
    if (saveDetailsApi.data?.quoteId) {
      const value = saveDetailsApi.data?.quoteId;
      formRenderProps.onChange("quoteId", { value: value });
      setQuoteId(value);
      getQuote(value);
    }
  }, [saveDetailsApi.data, saveDetailsApi.loading]);

  const getQuote = useCallback(async (value: number) => {
    if (value) {
      setLoading(true);
      getQuoteApi.request(value);
    }
  }, []);

  useEffect(() => {
    if (!getQuoteApi.data?.quoteExpiry) return;

    setExpiration(new Date(Date.parse(getQuoteApi.data.quoteExpiry)));

    const quotes = (getQuoteApi.data?.quotes || []).sort((a: any, b: any) =>
      a.retentionLevelId > b.retentionLevelId
        ? 1
        : a.retentionlevelId < b.retentionlevelId
          ? -1
          : a.contract > b.contract
            ? 1
            : -1,
    );

    const quoteData: any = {};

    Object.values(sizes).forEach((level: any) => {
      const levelQuotes = quotes.filter((q: any) => q.retentionLevelId === level.id);
      if (levelQuotes.length) {
        levelQuotes.forEach((item: any) => {
          const { pricingId, retentionLevelId, ...others } = item;

          if (level.field in quoteData) quoteData[level.field].prices.push(others);
          else
            quoteData[level.field] = {
              value: level.field,
              label: level.displayName,
              units: commodity.units.slice(0, -1),
              pricingId: pricingId,
              retentionLevelId: retentionLevelId,
              retentionLevel: level.value,
              prices: [others],
            };
        });
      }
    });

    setQuotes(Object.values(quoteData));

    if (Object.keys(quoteData).length === 0) return;

    Object.keys(sizes).forEach((l) => (sizes[l].quote = quoteData[l].prices));
    setSizes(sizes);
    formRenderProps.onChange("retentionLevels", { value: sizes });

    if (selectedLevel)
      formRenderProps.onChange("pricingId", { value: quoteData[selectedLevel].pricingId });

    setLoading(getQuoteApi.loading);
  }, [getQuoteApi.data, getQuoteApi.loading]);

  useEffect(() => {
    formRenderProps.onChange("selectedLevel", { value: selectedLevel });
  }, [selectedLevel]);

  useEffect(() => {
    setExhaustLevel(pricing[selectedLevel]?.averageExhaustPercent || 0);
  }, [pricing, selectedLevel]);

  useEffect(() => {
    setAttachLevel(sizes[selectedLevel]?.value || 0);

    const quote = selectedLevel ? quotes.find((q) => q.value === selectedLevel) : null;

    formRenderProps.onChange("pricingId", { value: quote?.pricingId });
  }, [selectedLevel, sizes]);

  useEffect(() => {
    if (!showCompare) {
      setPreviousLevel(selectedLevel);
      setSelectedLevel(customLevel);
    }
    if (showCompare && selectedLevel === customLevel)
      setSelectedLevel(previousLevel !== customLevel ? previousLevel : "");
  }, [showCompare]);

  const onExpiryChanged = (expiry: string) => {
    formRenderProps.onChange("expiry", { value: expiry });
  };

  const handleCheck = (level: string) => {
    if (level === selectedLevel) {
      setSelectedLevel("");
      setShowCompare(true);
      setFixedLevels([]);
      return;
    }

    setPreviousLevel(selectedLevel);
    setSelectedLevel(level);
    formRenderProps.onChange("selectedLevel", { value: level });

    const retLevels = Object.assign({}, sizes);
    retLevels[customLevel].value = sizes[level].value;
    retLevels[customLevel].pricing = sizes[level].pricing;
    formRenderProps.onChange("retentionLevels", { value: retLevels });

    handleExpand(level);
  };

  const handleExpand = (level: string) => {
    const isCustomCollapse = level === customLevel;
    const value = sizes[level].value;
    let sliderValues = levels.slice(0, -1);

    if (isCustomCollapse) {
      setFixedLevels([]);
      setSelectedLevel("");
    } else {
      setFixedLevels(sliderValues.map((v) => Math.floor(v * 100)));
      sliderValues.push(value);
      const hasChanged = JSON.stringify(levels) !== JSON.stringify(sliderValues);
      setNewLevels(sliderValues, hasChanged);
    }

    setShowCompare(isCustomCollapse);
  };

  // Sort the levels in the onBlur event of the numeric inputs
  // The ranger will sort values automatically
  const handleBlur = useCallback(
    (event: any) => {
      const { name, value } = event.target;

      let decimalLevels = levels.slice(0, -1);
      const index = Object.keys(sizes).indexOf(previousLevel);
      if (index >= 0) decimalLevels[index] = value;
      decimalLevels.sort((a, b) => a - b);
      decimalLevels.push(value);

      setNewLevels(decimalLevels, true);
    },
    [formRenderProps],
  );

  // Retention level slider code

  const setNewLevels = (newLevels: number[], isChange?: boolean) => {
    setAttachLevel(sizes[selectedLevel]?.value || 0);

    if (isChange) {
      setLevelsString(JSON.stringify(newLevels));
      setDragging(false);
    }
    if (
      newLevels.length !== levels.length ||
      levels.some((element, index) => element !== newLevels[index])
    ) {
      setLevels(newLevels);
    }
  };

  // Retention levels are stored as decimals between 0 and 1
  // The ranger needs percent values from 0 to 100
  const percentLevels = levels.map((level) => Math.floor(level * 100));
  const handleChangeLevelRanger = (newLevels: number[]) => {
    const value = newLevels[newLevels.length - 1];
    let sliderValues = percentLevels.slice(0, -1);

    const index = Object.keys(sizes).indexOf(previousLevel);
    if (index >= 0) sliderValues[index] = value;
    sliderValues.sort((a, b) => a - b);
    sliderValues.push(value);

    const decimalLevels = sliderValues.map((v) => v / 100);
    setNewLevels(decimalLevels, true);
  };

  const handleDragLevelRanger = (newLevels: number[]) => {
    const value = newLevels[newLevels.length - 1];
    let sliderValues = percentLevels.slice(0, -1).concat([value]);

    const decimalLevels = sliderValues.map((v) => v / 100);

    const delta = decimalLevels[decimalLevels.length - 1] - levels[levels.length - 1];
    setExhaustLevel(exhaustLevel + delta);

    const factor = isHPP ? delta : -delta;
    Object.keys(pricing).forEach((level: string) => {
      pricing[level].details.forEach((d: IInsurancePrice) => {
        d.attach += factor * d.ul;
        d.exhaust += factor * d.ul;
      });
    });

    setNewLevels(decimalLevels);
    setDragging(true);
  };

  // End retention level slider code

  // Coverage slider code

  const totalCoverage = formRenderProps.valueGetter("totalCoverage");
  const orig = formRenderProps.valueGetter("original_totalCoverage");
  const [customCoverage, setCustomCoverage] = useState<number[]>([totalCoverage]);
  const [customCoverageString, setCustomCoverageString] = useState("");
  const [newCoverages, setNewCoverages] = useState<number[]>(coverages.map((c) => c.coverage));

  const percentCoverage = customCoverage.map((coverage: any) =>
    Math.floor((coverage * 100) / orig),
  );
  const handleChangeCoverageRanger = (newValues: number[]) => {
    const newCoverages = newValues.map((coverage) => Math.floor((orig * coverage) / 100));
    setNewCoverage(newCoverages, true);
  };

  const handleDragCoverageRanger = (newValues: number[]) => {
    const newCoverages = newValues.map((coverage) => Math.floor((orig * coverage) / 100));

    setNewCoverage(newCoverages);
    setDragging(true);
  };

  const setNewCoverage = (newTotals: number[], isChange?: boolean) => {
    if (
      newTotals.length != customCoverage.length ||
      customCoverage.some((element: number, index: number) => element !== newTotals[index])
    ) {
      setCustomCoverage(newTotals);
    }
    if (isChange) {
      setCustomCoverageString(JSON.stringify(customCoverage));
      setDragging(false);
    }
  };

  useEffect(() => {
    if (customCoverage.length > 0) {
      const value = customCoverage[0];
      allocateValues(value);
    }
  }, [customCoverage]);

  const allocateValues = (newTotal: number) => {
    const oldTotal = sumByProperty(coverages, "coverage");
    const ratios = coverages.map((c) => c.coverage / oldTotal);
    const values = ratios.map((r) => Math.round(newTotal * r));

    if (oldTotal != newTotal && Object.keys(pricing).length > 0) {
      const factor = isHPP ? 1 : -1;

      const deltas = coverages.map((c, index) => {
        const diff = values[index] - c.coverage;
        return (factor * diff) / c.volume;
      });

      Object.keys(pricing).forEach((level: string) => {
        pricing[level].details.forEach(
          (d: IInsurancePrice, index: number) => (d.exhaust += deltas[index]),
        );
      });

      const exhaustDelta = averageNumbers(deltas);
      const ulDelta = averageByProperty(pricing[customLevel].details, "ul");
      setExhaustLevel(exhaustLevel + (factor * exhaustDelta) / ulDelta);
    }

    coverages.forEach((c: any, index: number) => (c.coverage = values[index]));
    setNewCoverages(values);
  };

  // End coverage slider code

  const getPricing = useCallback(async () => {
    if (Object.keys(sizes).length === 0 || levelsString.length === 0) return;

    setLoading(true);

    const retLevels = Object.keys(sizes).reduce((acc: any, key: string, index: number) => {
      const value = levels[index];
      if (value) acc[key] = value;
      return acc;
    }, {});

    if (Object.keys(retLevels).length === 0) return;

    if (newCoverages.length > 0) coverages.forEach((c, i) => (c.coverage = newCoverages[i]));

    formRenderProps.onChange("totalCoverage", { value: customCoverage[0] });
    newCoverages.forEach((c, i) => formRenderProps.onChange(`coverage_${i}`, { value: c }));

    await getPricingApi.request({
      tickerCode: ticker,
      instrumentType: instrument.id,
      retentionLevels: retLevels,
      coverages: coverages,
    });
  }, [newCoverages, levelsString]);

  useMemo(() => {
    getPricing();
  }, [levelsString, customCoverageString]);

  const customQuoteName = sizes[customLevel]?.displayName;

  const premiumSolverTooltip = `The Risk Canopy ${customQuoteName} allows you to interactively and quickly alter the retention level and total coverage of your custom insurance policy.
    Use the slider bars or enter a specific retention level in the box to the right, and your policy details will automatically update.
    Once you have a policy that fits your specific needs, click on Next.`;

  return (
    <div className="stepper-form">
      {getQuoteApi.error && <Error>{getQuoteApi.error}</Error>}
      {getPricingApi.error && <Error>{getPricingApi.error}</Error>}
      {saveDetailsApi.error && <Error>{saveDetailsApi.error}</Error>}

      <div className="utility-button-container">
        <Label>
          Select and customize your policy premium.
          <RetentionToolTip />
        </Label>

        <div style={{ alignSelf: "flex-end" }}>
          <Tooltip anchorElement="target" position="bottom" parentTitle={true}>
            <Button
              size="small"
              icon="refresh"
              themeColor="primary"
              title="Refresh"
              onClick={(e: any) => {
                e.preventDefault();
                getPricing();
              }}
              disabled={loading}
              style={{ marginLeft: "5px" }}
            />

            {showAnalytics && (
              <Button
                togglable={true}
                size="small"
                icon="align-bottom-element"
                themeColor="primary"
                title="View Chart"
                onClick={(e: any) => {
                  e.preventDefault();
                  setShowChart(true);
                }}
              />
            )}
          </Tooltip>
        </div>
      </div>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          {showCompare && (
            <>
              {Object.keys(sizes).map((size: string, index: number) => {
                const field = getFieldName(size);
                return levels[index] && size !== customLevel ? (
                  <div style={{ flexBasis: "32%", flexShrink: 0, flexGrow: 1 }} key={index}>
                    <RetentionCard
                      key={`retentionCard${index}`}
                      name={size}
                      fieldName={field}
                      title={`${sizes[size].displayName} Retention`}
                      isSelectable={true}
                      isSelected={size === selectedLevel}
                      isExpandable={size === selectedLevel}
                      isExpanded={false}
                      isReadOnly={true}
                      onCheck={handleCheck}
                      onExpand={handleExpand}
                      quoteSummary={{
                        loading: loading,
                        units: commodity.units.slice(0, -1),
                        coverages: coverages,
                        prices: pricing[size]?.details || [],
                        quote: quotes.length > index ? quotes[index].prices : [],
                        companyRole: company.role,
                        hideAttachExhaust: true,
                        hideFullStack: true,
                        hideUnitPrices: true,
                        unitPriceDecimals: commodity.decimals,
                      }}
                    />
                  </div>
                ) : (
                  <></>
                );
              })}
            </>
          )}

          {!showCompare && (
            <>
              {Object.keys(sizes).map((size: string, index: number) => {
                const field = getFieldName(size);
                return levels[index] && size === customLevel ? (
                  <div style={{ flexBasis: "60%", flexShrink: 0 }} key={index}>
                    <RetentionCard
                      key={index}
                      name={customLevel}
                      fieldName={field}
                      title={customQuoteName}
                      tooltip={premiumSolverTooltip}
                      tooltipLink={getHelpUrl(20336317407885)}
                      isSelectable={false}
                      isSelected={size === selectedLevel}
                      isExpandable={size === selectedLevel}
                      isExpanded={true}
                      onBlur={handleBlur}
                      onCheck={handleCheck}
                      onExpand={handleExpand}
                      quoteSummary={{
                        loading: loading,
                        dragging: dragging,
                        units: commodity.units.slice(0, -1),
                        coverages: coverages,
                        prices: pricing[customLevel]?.details || [],
                        quote: quotes.length > 3 ? quotes[3].prices : [],
                        companyRole: company.role,
                        hideUnitPrices: true,
                        unitPriceDecimals: commodity.decimals,
                      }}
                    />
                  </div>
                ) : (
                  <></>
                );
              })}

              {!loading && (
                <div style={{ flexBasis: "38%", flexShrink: 0 }}>
                  <div>
                    <Label>
                      Adjust Retention Level
                      <RetentionSliderToolTip />
                      &nbsp;(%)
                    </Label>
                    <Slider
                      options={{
                        name: "levelSlider",
                        values: showCompare ? percentLevels.slice(0, -1) : percentLevels.slice(-1),
                        min: 0,
                        max: 100,
                        staticValues: fixedLevels,
                        staticLabels: ["S", "M", "L"],
                      }}
                      onChange={handleChangeLevelRanger}
                      onDrag={handleDragLevelRanger}
                    />
                  </div>

                  <div style={{ paddingTop: 50 }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Label>
                        Adjust Coverage
                        <CoverageSliderToolTip />
                        &nbsp;(%)
                      </Label>
                      {percentCoverage[0] !== 100 && (
                        <div style={{ fontSize: "small" }}>
                          {formatter.formatNumber(orig, "c0")} to
                          <span
                            className={`k-icon k-i-sort-${
                              percentCoverage[0] > 100 ? "asc-sm" : "desc-sm"
                            }`}
                          ></span>
                          {formatter.formatNumber(customCoverage[0], "c0")}
                        </div>
                      )}
                    </div>
                    <Slider
                      options={{
                        name: "coverageSlider",
                        values: percentCoverage,
                        min: 25,
                        max: 500,
                        stepSize: 1,
                        ticks: [25, 100, 200, 300, 400, 500],
                      }}
                      onChange={handleChangeCoverageRanger}
                      onDrag={handleDragCoverageRanger}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {selectedLevel && showAnalytics && (
        <ChartExpander
          isExpanded={chartExpanded}
          text={"Market Statistics Chart"}
          setIsExpanded={setChartExpanded}
        >
          <Card>
            <CardBody>
              <MarketStatisticsChart
                commodity={commodityName}
                structure={instrument.instrumentType}
                plotLines={[
                  { label: "Exhaust", value: exhaustLevel, color: "red" },
                  { label: "Attach", value: attachLevel, color: "green", labelAbove: true },
                ]}
                apiResults={marketStatsResults}
              />
            </CardBody>
          </Card>
        </ChartExpander>
      )}

      <div style={{ paddingTop: 15 }}>
        <QuoteExpiry
          expiration={expiration}
          loading={loading}
          onExpire={onExpiryChanged}
          onRefresh={getPricing}
        >
          <InfoIconAndTooltip>
            Because market prices are constantly changing, your quote premium will eventually
            expire. Once your premium expires you will be asked to refresh the quote before you can
            submit it.
          </InfoIconAndTooltip>
        </QuoteExpiry>
      </div>

      <Field
        key="selectedLevel"
        id="selectedLevel"
        name="selectedLevel"
        component={FormInput}
        validator={requiredValidator}
        showValidationMessage={false}
        style={{ visibility: "hidden", display: "none" }}
      />

      {showChart && (
        <PricingHistoryDialog
          commodityName={commodityName}
          apiResults={marketStatsResults}
          onCancel={() => setShowChart(false)}
        />
      )}
    </div>
  );
};
