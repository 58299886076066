import { apiClient } from "./apiClient";

const endpoint = "product";

const getCommodityTypes = () => {
  return apiClient(`${endpoint}/types`).fetch();
};

const getCommodityTypesByCompany = (companyId: number) => {
  return apiClient(`${endpoint}/typesByCompany`).fetchWithParamsObject({
    companyId,
  });
};

const getCommodities = () => {
  return apiClient(`${endpoint}/commodities`).fetch();
};

const getCommoditiesByCompany = (companyId: number) => {
  return apiClient(`${endpoint}/commoditiesByCompany`).fetchWithParamsObject({
    companyId,
  });
};

const getProductTypes = () => {
  return apiClient(endpoint).fetch();
};

const getBusinessTypes = () => {
  return apiClient(`${endpoint}/businessTypes`).fetch();
};

const getBusinessTypesByCompany = (companyId: number) => {
  return apiClient(`${endpoint}/businessTypesByCompany`).fetchWithParamsObject({
    companyId,
  });
};

const getInstruments = (commodityId: number, companyId: number) => {
  return apiClient(`${endpoint}/instruments`).fetchWithParams(
    `?commodityId=${commodityId || 0}&companyId=${companyId || 0}`,
  );
};

const getCompanies = (commodityId: number, instrumentId: number, productType: string) => {
  const params = `?commodityId=${commodityId}&instrumentId=${instrumentId}&productType=${productType}`;
  return apiClient(`${endpoint}/companies`).fetchWithParams(params);
};

const getProducts = () => {
  return apiClient(`${endpoint}/productTypes`).fetch();
};

export default {
  getCommodityTypes,
  getCommodityTypesByCompany,
  getCommodities,
  getCommoditiesByCompany,
  getProductTypes,
  getBusinessTypes,
  getBusinessTypesByCompany,
  getInstruments,
  getCompanies,
  getProducts,
};
