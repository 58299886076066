import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { loginApiRequest } from "../config/authConfig";

export const Login = () => {
  const { instance, inProgress } = useMsal();
  let activeAccount: any | null = null;

  const getActiveAccount = async () => {
    const accounts = instance.getAllAccounts();
    activeAccount = accounts?.length > 0 ? instance.getActiveAccount() || accounts[0] : undefined;

    if (!activeAccount) {
      await instance.loginRedirect(loginApiRequest).catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (instance && inProgress === InteractionStatus.None) getActiveAccount();
  }, [instance, inProgress]);

  return activeAccount ? <Navigate to={"/"} /> : <></>;
};
