import { useInternationalization } from "@progress/kendo-react-intl";
import { PropsWithChildren } from "react";
import { clientDeltaHint, riskTakerDeltaHint } from "../../../text/coverageSummary";
import { ICommodity } from "../Commodity";
import { IDetailItem, QuoteDetailsSubHeader } from "./QuoteDetailsSubHeader";

interface IQuoteDetailsHeaderProps {
  quote: any;
  commodity: ICommodity;
  instrumentName: string;
  isRiskTaker: boolean;
  unitPriceDecimals?: number;
  volume?: number;
}

const Separator = () => {
  return <span className={"k-form-separator"} style={{ margin: "10px 0" }} />;
};

export const QuoteDetailsHeader = ({
  quote,
  commodity,
  instrumentName,
  children,
  isRiskTaker,
}: PropsWithChildren<IQuoteDetailsHeaderProps>) => {
  const formatter = useInternationalization();
  let { buyerName, brokerName, sellerName, createdBy, comments, totalDelta } = quote;

  const subHeaderDetails1: IDetailItem[] = [
    { label: "Buyer (Pays)", content: buyerName },
    { label: "Broker", content: brokerName },
    { label: "Seller (Receives)", content: sellerName },
  ];
  const subHeaderDetails2: IDetailItem[] = [
    { label: "Quoted By", content: createdBy },
    { label: "Commodity", content: commodity.name },
    { label: "Product", content: instrumentName },
  ];

  if (comments) subHeaderDetails1.push({ label: "Comments", content: comments });

  let deltaLabel = "Total Delta";
  let deltaHint = clientDeltaHint;
  if (isRiskTaker) {
    totalDelta *= -1;
    deltaLabel = "Risk Taker Delta";
    deltaHint = riskTakerDeltaHint;
  }

  const deltaItem = {
    label: deltaLabel,
    content: `${formatter.formatNumber(Math.round(totalDelta), "n0")} ${commodity.units}`,
    hint: deltaHint,
  };

  subHeaderDetails2.push(deltaItem);
  return (
    <>
      {children}

      <Separator />
      <QuoteDetailsSubHeader details={subHeaderDetails1} />

      <Separator />
      <QuoteDetailsSubHeader details={subHeaderDetails2} />

      <Separator />
    </>
  );
};
