import { parseDate } from "@progress/kendo-intl";
import { FormRenderProps } from "@progress/kendo-react-form";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Card, CardBody, CardHeader, CardTitle } from "@progress/kendo-react-layout";
import { useCallback, useEffect, useState } from "react";
import { SubmitDisclaimer } from "..";
import documentsApi from "../../../api/documents";
import quotesApi from "../../../api/quotes";
import { getQuote, getQuoteDetails, getStructures } from "../../../api/vanilla";
import { useApi, useFetch } from "../../../hooks/useApi";
import { useUserContext } from "../../../hooks/useUserContext";
import DateUtility from "../../../utilities/dateUtilities";
import { sumByProperty } from "../../../utilities/objectUtilities";
import { RoleUtility } from "../../../utilities/rolePermissionUtilities";
import { VanillaComponent } from "../../../utilities/vanilla/vanillaComponent";
import { Structure } from "../../../utilities/vanilla/vanillaEnums";
import { LoadingIndicator } from "../../LoadingIndicator";
import { IDetailItem, QuoteDetailsSubHeader } from "../quoteDetails";
import PdfDownloadButton from "../quoteDetails/PdfDownloadButton";
import { QuoteExpiry } from "../QuoteExpiry";
import VanillaQuoteSummary from "./VanillaQuoteSummary";
import { VanillaSubmitSummary } from "./VanillaSubmitSummary";

type Props = {
  formRenderProps: FormRenderProps;
  calculator: any;
  product: any;
};

const VanillaSubmit = ({ formRenderProps, calculator, product }: Props) => {
  const { context } = useUserContext();
  const isUserRiskTaker = context.roles.isRiskTaker;

  const { commodity, company, instrument } = product;
  const isCompanyRiskTaker = RoleUtility.isRiskTaker(company.role);

  const { decimals, units } = commodity;
  const unit = units.slice(0, -1);

  const [pricingId, setPricingId] = useState(formRenderProps.valueGetter("pricingId"));
  const [quote, setQuote] = useState(formRenderProps.valueGetter("quote"));
  const [expiration, setExpiration] = useState(
    new Date(Date.parse(formRenderProps.valueGetter("expiry"))),
  );
  const [paymentSummary, setPaymentSummary] = useState<IDetailItem[]>([]);

  const { totalVolume, columns, months, volumes } = calculator;

  const prices = months.map((month: Date, i: number) => {
    const volume = volumes[i];
    const date = DateUtility.formatDateYMD(month);
    const prices = columns.map((column: VanillaComponent) => {
      const strike = column.fixedStrikes[i];
      const componentId = column.componentId;
      return { strike, componentId };
    });
    return { date, volume, prices };
  });

  const quoteId = formRenderProps.valueGetter("quoteId");
  const direction = formRenderProps.valueGetter("direction");
  const structureId = formRenderProps.valueGetter("structureId");
  const isSwap = structureId === Structure.Swap;

  const { data: structuresData } = useFetch(getStructures, instrument.id);
  const structureName = structuresData?.structures.find((structure: any) => {
    return structure.structureId === structureId;
  }).longName;

  const getQuoteApi = useApi(getQuote);
  const getQuoteDetailsApi = useApi(quotesApi.getDetails);

  const refreshQuote = useCallback(async () => {
    setPricingId(0);
    await getQuoteApi.request(quoteId);
  }, [getQuoteApi, quoteId]);

  useEffect(() => {
    if (pricingId > 0) getQuoteDetailsApi.request(quoteData.id);
  }, [pricingId]);

  useEffect(() => {
    if (!getQuoteDetailsApi.data) return;

    const {
      quote: { buyerName, brokerName, sellerName, transactionDetails },
    } = getQuoteDetailsApi.data;
    const format = `c${decimals}`;
    const isLong = direction === 1;

    const formatPrice = (value: number, unit: string, format: string) =>
      `${formatter.formatNumber(value, format)} / ${unit}`;

    const payments = [
      sumByProperty(transactionDetails, "fairValuePrice") / totalVolume,
      sumByProperty(transactionDetails, "riskTakerPrice") / totalVolume,
      sumByProperty(transactionDetails, "brokerPrice") / totalVolume,
    ].sort((a, b) => (isLong ? a - b : b - a));

    const items = [];
    if (sellerName.toLowerCase() !== "no seller name")
      items.push({ label: sellerName, content: formatPrice(payments[0], unit, format) });

    if (brokerName.toLowerCase() !== "no broker name")
      items.push({ label: brokerName, content: formatPrice(payments[1], unit, format) });

    if (buyerName.toLowerCase() !== "no buyer name")
      items.push({ label: buyerName, content: formatPrice(payments[2], unit, format) });

    const payer = items[items.length - 1];

    items.push({
      label: "Payment",
      content: `${payer.label} (${isLong ? "Long" : "Short"})`,
    });

    setPaymentSummary(items);
  }, [getQuoteDetailsApi.data]);

  useEffect(() => {
    if (getQuoteApi.data) setExpiration(new Date(Date.parse(getQuoteApi.data.quoteExpiry)));
  }, [getQuoteApi.data, getQuoteApi.loading]);

  const onExpiryChanged = (expiry: string) => {
    formRenderProps.onChange("expiry", { value: expiry });
  };

  const formatter = useInternationalization();
  const totalPrice = isSwap ? 0 : sumByProperty(getQuoteApi.data?.quotes || [], "brokerPrice");

  const quoteData = {
    units: units,
    totalVolume,
    startDate: DateUtility.formatDateMY(parseDate(formRenderProps.valueGetter("contract.start"))),
    endDate: DateUtility.formatDateMY(parseDate(formRenderProps.valueGetter("contract.end"))),
    id: quoteId,
    name: formRenderProps.valueGetter("quoteName"),
    price: formatter.formatNumber(totalPrice, "c2"),
    structure: structureName,
  };

  const { context: user } = useUserContext();
  const canSubmit = user.roles.canSubmitQuotes(company.role);
  const checkboxFieldName = "isConfirmed";

  const getVanillaDetailsApi = useApi(getQuoteDetails);
  useEffect(() => {
    if (isSwap && getVanillaDetailsApi.data) {
      const newPrices = getVanillaDetailsApi.data.quote.prices.map((price: any) => {
        const newStrike = price.prices[0].strike;
        return newStrike;
      });
      calculator.setPrices(newPrices);
    }
  }, [getVanillaDetailsApi.data]);

  useEffect(() => {
    if (getQuoteApi.data) {
      const { quotes } = getQuoteApi.data;
      const id = quotes[0].pricingId;
      formRenderProps.onChange("pricingId", { value: id });
      setPricingId(id);
      setQuote(quotes);
      if (isSwap) {
        getVanillaDetailsApi.request(quoteId);
      }
    }
  }, [getQuoteApi.data, getQuoteApi.loading]);

  const handleAgree = () => {
    formRenderProps.onChange(checkboxFieldName, { value: true });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Quote Details</CardTitle>
        </CardHeader>
        <CardBody>
          <LoadingIndicator loading={getQuoteApi.loading} />
          {!getQuoteApi.loading && (
            <VanillaQuoteSummary
              quote={quote}
              prices={prices}
              columns={columns}
              units={unit}
              companyRole={company.role}
              isSwap={isSwap}
              unitPriceDecimals={decimals}
            />
          )}
          <PdfDownloadButton
            quoteId={quoteId}
            pricingId={pricingId}
            api={documentsApi.getQuoteSummaryPdf}
          />
        </CardBody>
      </Card>

      {isUserRiskTaker && (
        <div style={{ margin: "1rem 0" }}>
          <QuoteDetailsSubHeader details={paymentSummary} />
        </div>
      )}

      <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
        {isCompanyRiskTaker ? (
          <div>&nbsp;</div>
        ) : (
          <SubmitDisclaimer fieldName={checkboxFieldName} handleAgree={handleAgree}>
            <VanillaSubmitSummary
              quoteData={quoteData}
              quoteDetailsResults={getQuoteDetailsApi}
              canSubmit={canSubmit}
            />
          </SubmitDisclaimer>
        )}

        <QuoteExpiry
          expiration={expiration}
          loading={getQuoteApi.loading}
          onExpire={onExpiryChanged}
          onRefresh={refreshQuote}
        />
      </div>
    </>
  );
};

export default VanillaSubmit;
