import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { State, process } from "@progress/kendo-data-query";
import { BooleanGridCell, CustomGridCell, EditLinkCell } from "../grid";
import { AddEditUserDialog } from "./AddEditUserDialog";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ListItem } from "../form";
import { objectToLookup } from "../../utilities/objectUtilities";
import { ICompany } from "./Company";
import { IUser, User } from "./User";
import usersApi from "../../api/users";
import { useApi } from "../../hooks/useApi";

export const UsersGrid = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<ListItem[]>([]);
  const [rolePermissions, setRolePermissions] = useState<Record<string, number[]>>({});
  const [searchText, setSearchText] = useState("");
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<any>(User.EMPTY);
  const [result, setResult] = useState<any>([]);
  const [dataState, setDataState] = useState<State>({
    sort: [{ field: "fullName", dir: "asc" }],
    skip: 0,
    take: 20,
  });

  const getUsersApi = useApi(usersApi.getAll);
  useEffect(() => {
    getUsersApi.request();
  }, []);

  useEffect(() => {
    const { data } = getUsersApi;

    const comps: ICompany[] = data?.companies ?? [];

    const gridUsers = (data?.users ?? []).map((u: IUser) => ({
      ...User.create(u),
      companyList: comps
        .filter((c) => u.companyIds.includes(c.id))
        .map((c) => c.name)
        .sort()
        .join(", "),
    }));

    setUsers(gridUsers);
    setPermissions(objectToLookup(data?.permissions || []));
    setRolePermissions(data?.rolePermissions ?? {});
    setCompanies(
      comps.map((c) => ({
        value: c.id,
        label: c.name,
        role: c.role,
        children: comps.filter((ch) => ch.parentCompanyId === c.id).map((ch) => ch.id),
      })),
    );
  }, [getUsersApi.data]);

  useEffect(() => {
    const newState = createDataState(dataState);
    setResult(newState.result);
  }, [users]);

  useEffect(() => {
    const newState = Object.assign({}, dataState, {
      filter: {
        logic: "or",
        filters: [
          { field: "fullName", operator: "contains", value: searchText },
          { field: "companyList", operator: "contains", value: searchText },
          { field: "email", operator: "contains", value: searchText },
        ],
      },
    });
    applyDataStateChange(newState);
  }, [searchText]);

  const createDataState = (dataState: State) => {
    return {
      result: process(users.slice(0), dataState),
      dataState: dataState,
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    applyDataStateChange(event.dataState);
  };

  const applyDataStateChange = (dataState: State) => {
    const updatedState = createDataState(dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const enterEdit = (item: any) => {
    setEditItem(item);
    setOpenForm(true);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleSubmitEdit = () => {
    setOpenForm(false);
    getUsersApi.request();
  };

  const UserNameCell = (props: GridCellProps) => (
    <EditLinkCell {...props} title="Edit User" enterEdit={enterEdit} />
  );

  const LongTextCell = (props: GridCellProps) => {
    const { field, dataItem } = props;
    const value = dataItem[field || ""] || [];

    return (
      <CustomGridCell {...props}>
        <div className={"ellipsis-cell"}>{value}</div>
      </CustomGridCell>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Button
          title="Add User"
          themeColor="primary"
          size="small"
          onClick={() => enterEdit(User.EMPTY)}
          style={{ marginBottom: 10 }}
        >
          Add User
        </Button>

        <div>
          <Label>Search</Label>
          <Input
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
            style={{ margin: 5, width: 200 }}
          />
        </div>
      </div>

      <LoadingIndicator loading={getUsersApi.loading} />
      {getUsersApi.error && <Error>{getUsersApi.error}</Error>}
      {!getUsersApi.loading && (
        <Grid
          data={result}
          {...dataState}
          pageable={true}
          sortable={true}
          filterable={false} // Using custom grid column menu filter
          onDataStateChange={dataStateChange}
        >
          <GridColumn field="fullName" title="Name" cell={UserNameCell} />
          <GridColumn
            field="companyList"
            title="Companies"
            cell={LongTextCell}
            filterable={true}
          />
          <GridColumn field="email" title="Email" />
          <GridColumn field="phone" title="Phone" />
          <GridColumn field="isActive" title="Active" cell={BooleanGridCell} width={150} />
        </Grid>
      )}

      {openForm && (
        <AddEditUserDialog
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmitEdit}
          user={editItem}
          companies={companies}
          permissions={permissions}
          rolePermissions={rolePermissions}
        />
      )}
    </>
  );
};
