import { useInternationalization } from "@progress/kendo-react-intl";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { Switch } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CSSProperties, Fragment, useState } from "react";
import {
  attachHint,
  coverageHint,
  exhaustHint,
  retentionHint,
  volumeHint,
} from "../../../text/coverageSummary";
import { useUserContext } from "../../../hooks/useUserContext";
import DateUtility from "../../../utilities/dateUtilities";
import { sumByProperty } from "../../../utilities/objectUtilities";
import { RoleUtility } from "../../../utilities/rolePermissionUtilities";
import { LoadingIndicator } from "../../LoadingIndicator";
import { IInsuranceQuoteSummary } from "./Types";

export const InsuranceQuoteSummary = (props: IInsuranceQuoteSummary) => {
  const {
    name,
    loading,
    dragging,
    units,
    coverages,
    prices,
    quote,
    companyRole,
    hideRetention,
    hideCoverage,
    hideVolume,
    hideAttachExhaust,
    hideFullStack,
    hideUnitPrices,
    unitPriceDecimals: decimals = 2,
  } = props;
  const formatter = useInternationalization();
  const { context: user } = useUserContext();

  const headerStyle = { fontWeight: "bold" };
  const headerWithHintStyle: CSSProperties = {
    fontWeight: "bold",
    cursor: "pointer",
    textDecoration: "underline dotted",
  };
  const subHeaderStyle = { fontStyle: "italic", fontSize: "smaller" };
  const priceStyle = { color: dragging ? "lightgray" : "inherit" };
  const totalStyle = { borderTop: "double" };

  const isUserRiskTaker = user.roles.isRiskTaker;
  const isUserBroker = user.roles.isBroker;
  const isUserClient = user.roles.isClient || !(isUserRiskTaker || isUserBroker);

  const isCompanyRiskTaker = RoleUtility.isRiskTaker(companyRole);
  const isCompanyBroker = RoleUtility.isBroker(companyRole);
  const isCompanyClient =
    RoleUtility.isClient(companyRole) || !(isCompanyBroker || isCompanyRiskTaker);

  const startCol = 2;
  const startRow = 3;
  const priceCols = prices
    ? 5 -
      (hideRetention ? 1 : 0) -
      (hideCoverage ? 1 : 0) -
      (hideVolume ? 1 : 0) -
      (hideAttachExhaust ? 2 : 0)
    : 0;

  const [showUnitPrices, setShowUnitPrices] = useState(!hideUnitPrices);
  const totalVolume = sumByProperty(coverages, "volume");

  const renderPricingColumns = () => {
    if (!prices?.length) return [];

    const totalCoverage = sumByProperty(coverages, "coverage");
    const totalRetention = sumByProperty(prices, "retention");
    const priceSubHeader = "($)";
    const format = "n0";

    let col = startCol;
    let items: any[] = [];

    if (!hideAttachExhaust) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"att_1"}>
          <span style={headerWithHintStyle} title={attachHint}>
            Attach
          </span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col++} row={2} key={"att_2"}>
          <span style={subHeaderStyle}>($/{units})</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col} row={1} key={"exh_1"}>
          <span style={headerWithHintStyle} title={exhaustHint}>
            Exhaust
          </span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col++} row={2} key={"exh_2"}>
          <span style={subHeaderStyle}>($/{units})</span>
        </GridLayoutItem>,
      );
    }

    if (!hideVolume) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"vol_1"}>
          <span title={volumeHint} style={headerWithHintStyle}>
            Volume
          </span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col} row={2} key={"vol_2"}>
          <span style={subHeaderStyle}>({units}s)</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem
          col={col++}
          row={startRow + coverages.length}
          key={"vol_tot"}
          style={totalStyle}
        >
          <span style={headerStyle}>{formatter.formatNumber(totalVolume, format)}</span>
        </GridLayoutItem>,
      );
    }

    if (!hideRetention) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"ret_1"}>
          <span style={headerWithHintStyle} title={retentionHint}>
            Retention
          </span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col} row={2} key={"ret_2"}>
          <span style={subHeaderStyle}>{priceSubHeader}</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem
          col={col++}
          row={startRow + prices.length}
          key={"ret_tot"}
          style={totalStyle}
        >
          <span style={headerStyle}>{formatter.formatNumber(totalRetention, format)}</span>
        </GridLayoutItem>,
      );
    }

    if (!hideCoverage) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"cov_1"}>
          <span style={headerWithHintStyle} title={coverageHint}>
            Coverage
          </span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col} row={2} key={"cov_2"}>
          <span style={subHeaderStyle}>{priceSubHeader}</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem
          col={col++}
          row={startRow + coverages.length}
          key={"cov_tot"}
          style={totalStyle}
        >
          <span style={headerStyle}>{formatter.formatNumber(totalCoverage, format)}</span>
        </GridLayoutItem>,
      );
    }

    coverages.forEach((coverage, i) => {
      col = startCol;
      let row = i + startRow;
      if (prices.length > i) {
        if (!hideAttachExhaust) {
          items.push(
            <GridLayoutItem col={col++} row={row} key={`att_${row}`}>
              {formatter.formatNumber(prices[i].attach, `n${decimals}`)}
            </GridLayoutItem>,
          );

          items.push(
            <GridLayoutItem col={col++} row={row} key={`exh_${row}`}>
              {formatter.formatNumber(prices[i].exhaust, `n${decimals}`)}
            </GridLayoutItem>,
          );
        }

        if (!hideVolume)
          items.push(
            <GridLayoutItem col={col++} row={row} key={`vol_${row}`}>
              {formatter.formatNumber(coverage.volume, "n0")}
            </GridLayoutItem>,
          );

        if (!hideRetention)
          items.push(
            <GridLayoutItem col={col++} row={row} key={`ret_${row}`}>
              {formatter.formatNumber(prices[i].retention, "n0")}
            </GridLayoutItem>,
          );

        if (!hideCoverage)
          items.push(
            <GridLayoutItem col={col++} row={row} key={`cov_${row}`}>
              {formatter.formatNumber(coverage.coverage, "n0")}
            </GridLayoutItem>,
          );
      }
    });

    return items;
  };

  const renderQuoteColumns = () => {
    if (!quote?.length) return [];

    const totalBrokerPrice = sumByProperty(quote, "brokerPrice");
    const totalRiskTakerPrice = sumByProperty(quote, "riskTakerPrice");
    const totalFairValuePrice = sumByProperty(quote, "fairValuePrice");
    let items: any[] = [];
    let col = startCol + priceCols;

    const showPrice = isUserClient || isCompanyClient;
    const showBroker = isCompanyBroker || (!isUserClient && isCompanyClient && !hideFullStack);
    const showFairValue = isCompanyRiskTaker || (isUserRiskTaker && !hideFullStack);
    const priceSubHeader = showUnitPrices ? `($/${units})` : "($)";
    const format = showUnitPrices ? `n${decimals}` : "n2";

    if (showFairValue) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"q2_1"}>
          <span style={headerStyle}>Fair Value</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col++} row={2} key={"q2_2"}>
          <span style={subHeaderStyle}>{priceSubHeader}</span>
        </GridLayoutItem>,
      );
    }

    if (showBroker) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"q3_1"}>
          <span style={headerStyle}>Broker</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col++} row={2} key={"q3_2"}>
          <span style={subHeaderStyle}>{priceSubHeader}</span>
        </GridLayoutItem>,
      );
    }

    if (showPrice) {
      items.push(
        <GridLayoutItem col={col} row={1} key={"q4_1"}>
          <span style={headerStyle}>Price</span>
        </GridLayoutItem>,
      );
      items.push(
        <GridLayoutItem col={col++} row={2} key={"q4_2"}>
          <span style={subHeaderStyle}>{priceSubHeader}</span>
        </GridLayoutItem>,
      );
    }

    quote.forEach((q, i) => {
      col = startCol + priceCols;
      let row = i + startRow;
      const factor = showUnitPrices ? coverages[i]?.volume ?? 1 : 1;

      if (showFairValue)
        items.push(
          <GridLayoutItem col={col++} row={row} key={`q2_${row}`}>
            <span style={priceStyle}>
              {formatter.formatNumber(q.fairValuePrice / factor, format)}
            </span>
          </GridLayoutItem>,
        );

      if (showBroker)
        items.push(
          <GridLayoutItem col={col++} row={row} key={`q3_${row}`}>
            <span style={priceStyle}>
              {formatter.formatNumber(q.riskTakerPrice / factor, format)}
            </span>
          </GridLayoutItem>,
        );

      if (showPrice)
        items.push(
          <GridLayoutItem col={col++} row={row} key={`q4_${row}`}>
            <span style={priceStyle}>{formatter.formatNumber(q.brokerPrice / factor, format)}</span>
          </GridLayoutItem>,
        );
    });

    col = startCol + priceCols;
    const factor = showUnitPrices ? totalVolume : 1;

    if (showFairValue)
      items.push(
        <GridLayoutItem
          col={col++}
          row={startRow + quote.length}
          key={"q2_tot"}
          style={{ ...totalStyle, ...priceStyle }}
        >
          <span style={{ ...headerStyle, ...priceStyle }}>
            {formatter.formatNumber(totalFairValuePrice / factor, format)}
          </span>
        </GridLayoutItem>,
      );

    if (showBroker)
      items.push(
        <GridLayoutItem
          col={col++}
          row={startRow + quote.length}
          key={"q3_tot"}
          style={{ ...totalStyle, ...priceStyle }}
        >
          <span style={{ ...headerStyle, ...priceStyle }}>
            {formatter.formatNumber(totalRiskTakerPrice / factor, format)}
          </span>
        </GridLayoutItem>,
      );

    if (showPrice)
      items.push(
        <GridLayoutItem
          col={col++}
          row={startRow + quote.length}
          key={"q4_tot"}
          style={{ ...totalStyle, ...priceStyle }}
        >
          <span style={{ ...headerStyle, ...priceStyle }}>
            {formatter.formatNumber(totalBrokerPrice / factor, format)}
          </span>
        </GridLayoutItem>,
      );

    return items;
  };

  return (
    <>
      {loading && <LoadingIndicator loading={loading} />}

      {!loading && (
        <div key={`${name}summary`} style={{ margin: "0 1rem" }}>
          {!hideUnitPrices && (
            <div style={{ display: "flex", justifyContent: "right", paddingBottom: "1rem" }}>
              <Label style={{ marginRight: 5 }}>Unit Prices</Label>
              <Switch
                size={"small"}
                checked={showUnitPrices}
                onChange={(e) => setShowUnitPrices(!showUnitPrices)}
              />
            </div>
          )}

          <Tooltip anchorElement="target" position="auto" showCallout={false}>
            <GridLayout align={{ vertical: "top", horizontal: "end" }}>
              {coverages.map((coverage, i) => (
                <Fragment key={i}>
                  <GridLayoutItem col={1} row={i + startRow} key={`c1_${i + startRow}`}>
                    <span style={headerStyle}>{DateUtility.formatDateMY(coverage.label)}</span>
                  </GridLayoutItem>
                </Fragment>
              ))}

              {renderPricingColumns()}

              {renderQuoteColumns()}
            </GridLayout>
          </Tooltip>
        </div>
      )}
    </>
  );
};
