import { JSXElementConstructor, ReactElement, cloneElement, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Icon, IconThemeColor } from "@progress/kendo-react-common";
import { Grid, GridCellProps, GridColumn, GridRowProps } from "@progress/kendo-react-grid";
import { Error } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AddEditAlertDialog } from ".";
import { Alert } from "./Alert";
import { BooleanGridCell, CustomGridCell } from "../grid";
import { LoadingIndicator } from "../LoadingIndicator";
import { Severity } from "../layout/Notifications";
import notificationsApi from "../../api/notifications";
import { useApi } from "../../hooks/useApi";
import DateUtility from "../../utilities/dateUtilities";
import { capitalizeFirstLetter } from "../../utilities/textUtilities";

const SeverityCell = (props: GridCellProps) => {
  const { field, dataItem } = props;
  const severity: string = dataItem[field || ""] || Severity.None;

  return (
    <CustomGridCell {...props} style={{ textAlign: "center" }}>
      {severity.length > 0 && (
        <span title={capitalizeFirstLetter(severity)}>
          <Icon name={severity as Severity} themeColor={severity as IconThemeColor} />
        </span>
      )}
    </CustomGridCell>
  );
};

const DateDisplayCell = (props: GridCellProps) => {
  const field = props.field || "";
  const value = props.dataItem[field];

  return (
    <CustomGridCell {...props}>
      {value ? (
        <span>{DateUtility.formatDateTime(new Date(value))}</span>
      ) : (
        <span style={{ fontStyle: "italic" }}>Indefinite</span>
      )}
    </CustomGridCell>
  );
};

export const AlertsGrid = () => {
  const [gridData, setGridData] = useState<any[]>();
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const getNotificationsApi = useApi(notificationsApi.getAll);
  const saveNotificationApi = useApi(notificationsApi.save);

  useEffect(() => {
    getNotificationsApi.request();
  }, []);

  useEffect(() => {
    setIsLoading(getNotificationsApi.loading);
    setGridData(getNotificationsApi.data?.notifications || []);
  }, [getNotificationsApi.data, getNotificationsApi.loading]);

  useEffect(() => {
    if (saveNotificationApi.loading) setIsLoading(saveNotificationApi.loading);

    if (!saveNotificationApi.loading && (saveNotificationApi.data || saveNotificationApi.error)) {
      if (!saveNotificationApi.error) handleSubmitEdit();
      else setIsLoading(false);
    }
  }, [saveNotificationApi.data, saveNotificationApi.loading]);

  const onEdit = (item: any) => {
    setEditItem(item);
    setOpenForm(true);
  };

  const onStop = async (item: any) => {
    item.endDate = new Date();
    await saveNotificationApi.request(item);
  };

  const onStart = async (item: any) => {
    item.startDate = new Date();
    item.endDate = null;
    await saveNotificationApi.request(item);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleSubmitEdit = () => {
    setOpenForm(false);
    getNotificationsApi.request();
  };

  const rowRender = (
    trElement: ReactElement<HTMLTableRowElement, string | JSXElementConstructor<any>>,
    props: GridRowProps,
  ) => {
    const isActive = props.dataItem.isActive;
    const trProps: any = {
      style: { backgroundColor: isActive ? "lightyellow" : "inherit" },
    };

    return cloneElement(
      trElement,
      {
        ...trProps,
      },
      props.children,
    );
  };

  const ActionsCell = (props: GridCellProps) => {
    const field = props.field || "";
    const value = props.dataItem[field];

    return (
      <td>
        <div style={{ display: "flex" }}>
          <Button
            icon="edit"
            themeColor="primary"
            fillMode="outline"
            title="Edit Alert"
            size="medium"
            disabled={isLoading}
            onClick={() => onEdit(props.dataItem)}
          />
          {value ? (
            <Button
              icon="stop"
              themeColor="primary"
              fillMode="outline"
              title="Stop Alert"
              size="small"
              disabled={isLoading}
              onClick={() => onStop(props.dataItem)}
            />
          ) : (
            <Button
              icon="play"
              themeColor="primary"
              fillMode="outline"
              title="Start Alert"
              size="small"
              disabled={isLoading}
              onClick={() => onStart(props.dataItem)}
            />
          )}
        </div>
      </td>
    );
  };

  return (
    <>
      <LoadingIndicator loading={isLoading} />
      {getNotificationsApi.error && <Error>{getNotificationsApi.error}</Error>}
      {saveNotificationApi.error && <Error>{saveNotificationApi.error}</Error>}

      <Tooltip anchorElement="target" parentTitle={true}>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "right",
          }}
        >
          <Button
            themeColor="primary"
            size="small"
            style={{ marginBottom: 10 }}
            disabled={isLoading}
            onClick={() => onEdit(Alert.EMPTY)}
          >
            Add Alert
          </Button>
        </div>

        <Grid
          data={gridData}
          pageable={true}
          resizable={true}
          sortable={true}
          rowRender={rowRender}
        >
          <GridColumn field="severity" title="Severity" cell={SeverityCell} width={100} />
          <GridColumn field="isSystem" title="System" cell={BooleanGridCell} width={100} />
          <GridColumn field="message" title="Message" />
          <GridColumn field="startDate" title="Display Start" cell={DateDisplayCell} />
          <GridColumn field="endDate" title="Display End" cell={DateDisplayCell} />
          <GridColumn field="isActive" title=" " cell={ActionsCell} width={100} />
        </Grid>
      </Tooltip>

      {openForm && (
        <AddEditAlertDialog
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmitEdit}
          item={editItem}
        />
      )}
    </>
  );
};
