import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import {
  NumericTextBox,
  NumericTextBoxBlurEvent,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Fragment, useEffect, useState } from "react";
import useTransactionDirections from "../../../hooks/useTransactionDirections";
import DateUtility from "../../../utilities/dateUtilities";
import { getLastChar } from "../../../utilities/textUtilities";
import { STRIKE_TYPES } from "../../../utilities/vanilla/vanillaData";
import { StrikeType, Structure } from "../../../utilities/vanilla/vanillaEnums";
import { requiredValidator } from "../../../validators";
import { FormInput, FormSwitch } from "../../form";
import { FormChipList } from "../../form/FormChipList";
import { ICommodity } from "../Commodity";
import { CurveSelectionDialog } from "../insurance";

interface Props {
  formRenderProps: FormRenderProps;
  allocations: any[];
  calculator: any;
  commodity: ICommodity;
  product: any;
}

const VanillaStructure = ({
  formRenderProps,
  allocations,
  calculator,
  commodity,
  product,
}: Props) => {
  const { instrument, company } = product;
  const units = commodity?.units;
  const isFixed = formRenderProps.valueGetter("strikeType") === "Fixed";

  const decimals = commodity?.decimals || 2;
  const format = `c${decimals}`;
  const strikeType = isFixed ? StrikeType.Fixed : StrikeType.Adder;

  useEffect(() => {
    calculator.update();
  }, [isFixed]);

  const commonStrike = formRenderProps.valueGetter("commonStrike");

  useEffect(() => {
    calculator.setIsCommonStrike(commonStrike);
  }, [commonStrike]);

  useEffect(() => {
    if (allocations.length) {
      const formAllocationIndex = allocations.findIndex(
        (a) => a.allocationId === formRenderProps.valueGetter("allocationId"),
      );
      setAllocationIndex(formAllocationIndex);
    }
  }, [allocations.length]);

  const [allocationIndex, setAllocationIndex] = useState(1);

  const [showCurveDialog, setShowCurveDialog] = useState(false);

  const onApplyAllocations = (index: number) => {
    setShowCurveDialog(false);
    setAllocationIndex(index);
    const { values, allocationId } = allocations[index];

    calculator.setAllocation(values);
    formRenderProps.onChange("allocationId", { value: allocationId });
  };

  const handleChangeVolume = ({ target: { value, name } }: NumericTextBoxBlurEvent) => {
    if (!value) {
      value = 0;
    }
    const char = getLastChar(name as string);
    const index = parseInt(char);

    calculator.setVolume(value, index);
    setAllocationIndex(-1);
    formRenderProps.onChange("allocationId", { value: null });
  };

  const handleChangeTotalVolume = ({ target: { value } }: NumericTextBoxChangeEvent) => {
    if (value === null) return;
    calculator.setTotalVolume(value);
    const flatAllocationIndex = allocations.findIndex(
      (allocation: any) => allocation.allocationId === 1,
    );
    setAllocationIndex(flatAllocationIndex);
    formRenderProps.onChange("allocationId", { value: 1 });
  };

  const fieldStyle = { margin: 0 };

  const dollarsPerUnit = `($/${units.slice(0, -1)})`;

  const handleChangeStrike =
    (columnIndex: number, strikeType: StrikeType, month: number | null = null) =>
    ({ target: { value } }: NumericTextBoxChangeEvent) => {
      if (value === null) return;
      calculator.setStrike(value, columnIndex, strikeType, month);
    };

  const [allocatedData, setAllocatedData] = useState<any>([]);

  useEffect(() => {
    if (calculator) {
      const allocatedData = allocations.map((allocation: any) => {
        const { values } = allocation;
        const data = calculator.allocateValues(values);
        return [{ name: "Volume", data }];
      });
      setAllocatedData(allocatedData);
    }
  }, [calculator.totalVolume, allocations]);

  const monthLabels = calculator.months.map((month: Date) => DateUtility.formatDateMY(month));

  useEffect(() => {
    formRenderProps.onChange("totalVolume", { value: calculator.totalVolume });
  }, [calculator.totalVolume]);

  useEffect(() => {
    formRenderProps.onChange("errors", { value: calculator.errors.length ? 0 : 1 });
  }, [calculator.errors]);

  const showAdders = strikeType === StrikeType.Adder;

  const structureId = formRenderProps.valueGetter("structureId");

  const isSwap = structureId === Structure.Swap;

  const strikeTypeFieldStyle = isSwap ? { visibility: "hidden", display: "none" } : { margin: 0 };

  const strikeTypeLabel = isSwap ? "" : "Strikes";

  const canCompanySell = company.features?.canSell;
  const requiresCanSellToTransact = instrument.requiresCanSell;
  const handleResetTransactionDirections = () =>
    formRenderProps.onChange("direction", { value: null });

  const transactionDirections = useTransactionDirections(
    canCompanySell,
    requiresCanSellToTransact,
    handleResetTransactionDirections,
  );

  const isMonthlyDisabled = commonStrike;
  const showMonthlyAdders = showAdders && !commonStrike;

  const columnComponents = calculator.columns.map((column: any, columnIndex: number) => {
    const monthlyValues = showMonthlyAdders ? column.adders : column.fixedStrikes;
    const avgValue = showAdders ? column.avgAdder : column.avgFixedStrike;
    const nextColumnIsLinked = calculator.columns[columnIndex + 1]?.isLinked;
    const isDisabled = column.isLinked && !nextColumnIsLinked;
    return (
      <Fragment key={columnIndex}>
        <GridLayoutItem col={6 + columnIndex} row={1}>
          <Label className="align-items-center" style={fieldStyle}>
            {column.label} {dollarsPerUnit}
          </Label>
        </GridLayoutItem>
        <GridLayoutItem col={6 + columnIndex} row={2}>
          <NumericTextBox
            spinners={false}
            format={format}
            onChange={handleChangeStrike(columnIndex, strikeType)}
            value={avgValue}
            style={fieldStyle}
            disabled={isDisabled}
          />
        </GridLayoutItem>
        {monthlyValues.map((value: any, monthIndex: number) => {
          return (
            <GridLayoutItem col={6 + columnIndex} row={6 + monthIndex} key={monthIndex}>
              <NumericTextBox
                spinners={false}
                format={format}
                onChange={handleChangeStrike(columnIndex, strikeType, monthIndex)}
                value={value}
                style={fieldStyle}
                disabled={isDisabled || isMonthlyDisabled}
              />
            </GridLayoutItem>
          );
        })}
      </Fragment>
    );
  });

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 120,
            marginRight: 20,
          }}
        >
          <Field
            name="strikeType"
            label={strikeTypeLabel}
            component={FormChipList}
            data={STRIKE_TYPES}
            selection="single"
            style={strikeTypeFieldStyle}
          />
          <Field
            name="direction"
            label="Direction"
            component={FormChipList}
            selection="single"
            data={transactionDirections}
            validator={requiredValidator}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 120,
            marginRight: 20,
          }}
        >
          {!isSwap && (
            <Field
              checked={commonStrike}
              name="commonStrike"
              label="Common Strike"
              component={FormSwitch}
              vertical
            />
          )}
          <Label style={{ marginTop: 20 }}>Allocations</Label>
          <div>
            <Button
              size="small"
              disabled={calculator.totalVolume === 0}
              icon="align-bottom-element"
              onClick={(e: any) => {
                e.preventDefault();
                setShowCurveDialog(true);
              }}
              themeColor="primary"
            />
          </div>
        </div>
        <div className="stepper-form">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <GridLayout
              align={{ vertical: "middle" }}
              gap={{ rows: 0, cols: 10 }}
              style={{ marginTop: 16 }}
            >
              <GridLayoutItem col={2} row={3}>
                <Label>&nbsp;</Label>
              </GridLayoutItem>
              {monthLabels.map((label: string, i: number) => (
                <GridLayoutItem col={2} row={i + 6} key={i}>
                  <span className="k-font-weight-bold">{label}</span>
                </GridLayoutItem>
              ))}
              <GridLayoutItem col={5} row={1}>
                <Label style={fieldStyle}>Volume ({units})</Label>
              </GridLayoutItem>
              <GridLayoutItem col={5} row={2}>
                <NumericTextBox
                  spinners={false}
                  format="n0"
                  min={0}
                  onChange={handleChangeTotalVolume}
                  value={calculator.totalVolume}
                  style={fieldStyle}
                />
              </GridLayoutItem>
              {calculator.volumes.map((volume: number, i: number) => {
                const name = `volume_${i}`;
                return (
                  <GridLayoutItem col={5} row={i + 6} key={i}>
                    <NumericTextBox
                      name={name}
                      spinners={false}
                      format="n0"
                      onChange={handleChangeVolume}
                      value={volume}
                      min={0}
                    />
                  </GridLayoutItem>
                );
              })}
              {!isSwap && columnComponents}
            </GridLayout>
            <div style={{ display: "flex", alignSelf: "center" }}>
              <ul>
                {calculator.errors.map((error: string, i: number) => {
                  return (
                    <li key={i}>
                      <Error>{error}</Error>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        {showCurveDialog && allocations.length && (
          <CurveSelectionDialog
            title="Allocations"
            chartData={allocatedData}
            selection={allocationIndex}
            categories={calculator.months}
            units={units}
            options={allocations.map((a: any, i: number) => {
              return { label: a.name, value: i };
            })}
            onCancel={() => setShowCurveDialog(false)}
            onApply={onApplyAllocations}
          />
        )}
      </div>

      <Field
        name="totalVolume"
        component={FormInput}
        validator={requiredValidator}
        showValidationMessage={false}
        style={{ visibility: "hidden", display: "none" }}
      />

      <Field
        name="errors"
        component={FormInput}
        validator={requiredValidator}
        showValidationMessage={false}
        style={{ visibility: "hidden", display: "none" }}
      />
    </>
  );
};

export default VanillaStructure;
