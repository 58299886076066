import { PropsWithChildren, createContext, useContext } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { CompanyFeatures } from "../../utilities/companyUtilities";
import { IBaseCompany } from "../admin/Company";
import { CompaniesContext } from "./CompaniesContextProvider";

export interface IUserCompany {
  id: number;
  name: string;
  role: string;
  features: CompanyFeatures;
}

export class UserCompany implements IUserCompany {
  id = 0;
  name = "";
  role = "";
  features = new CompanyFeatures([]);

  private constructor(company: IBaseCompany | null) {
    if (company) {
      this.id = company.id;
      this.name = company.name;
      this.role = company.role;
      this.features = new CompanyFeatures(company.features ?? []);
    }
  }

  static get EMPTY(): UserCompany {
    return UserCompany.create(null);
  }

  static create(company: IBaseCompany | null): UserCompany {
    return new UserCompany(company);
  }

  static clone(company: IUserCompany): UserCompany {
    const { id, name, role, features } = company;
    return UserCompany.create({ id, name, role, ...features });
  }
}

interface IUserCompanyContext {
  context: UserCompany;
  setContext: (obj: any) => void;
}

export const UserCompanyContext = createContext<IUserCompanyContext>({} as IUserCompanyContext);

export const UserCompanyContextProvider = ({ children }: PropsWithChildren) => {
  const { context: companies } = useContext(CompaniesContext);

  const [context, setContext] = useLocalStorage("context", UserCompany.EMPTY);

  companies.sort((a, b) => a.id - b.id);

  if (companies.length > 0) {
    const activeCompany = companies.find((c) => c.id === context.id) ?? UserCompany.clone(companies[0]);
    if (JSON.stringify(context) !== JSON.stringify(activeCompany)) setContext(activeCompany);
  }

  return (
    <UserCompanyContext.Provider value={{ context, setContext }}>
      {children}
    </UserCompanyContext.Provider>
  );
};
