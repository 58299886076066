import { GridCellProps } from "@progress/kendo-react-grid";
import DateUtility from "../../utilities/dateUtilities";
import { CustomGridCell } from "./CustomGridCell";

export const DateGridCell = (props: GridCellProps) => {
  const field = props.field || "";
  const value = props.dataItem[field];
  const format = props.format || "g";

  const dateValue = DateUtility.format(value, format);

  return <CustomGridCell {...props}>{dateValue.length ? dateValue : value}</CustomGridCell>;
};
