import { Button } from "@progress/kendo-react-buttons";
import { Input, RadioGroup } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useState } from "react";
import { IconToggleButton } from "../../utilities";

interface IConfigurationFiltersProps {
  commodityTypes?: Record<string, string>;
  businessTypes?: Record<number, string>;
  productTypes?: Record<string, string>;
  initialSelections?: IProductFilters;
  onChangeFilters(selections: IProductFilters): void;
}

export interface IProductFilters {
  commodities: string[];
  products: string[];
  businessType: number;
  text: string;
}

export const EMPTY_PRODUCT_FILTERS: IProductFilters = {
  commodities: [],
  products: [],
  businessType: 0,
  text: "",
};

export const ConfigurationsFilters = ({
  businessTypes,
  commodityTypes,
  productTypes,
  initialSelections,
  onChangeFilters,
}: IConfigurationFiltersProps) => {
  const [hoveredCommodity, setHoveredCommodity] = useState("");
  const [hoveredProduct, setHoveredProduct] = useState("");
  const [selections, setSelections] = useState<IProductFilters>(
    initialSelections ?? EMPTY_PRODUCT_FILTERS,
  );

  const businessTypeOptions = businessTypes
    ? Object.entries(businessTypes).map(([key, value]) => ({
        value: Number(key),
        label: value,
      }))
    : [];

  useEffect(() => {
    onChangeFilters(selections);
  }, [selections]);

  const handleCommodityClick = (type: string) => {
    updateFilter(type, "commodities");
  };

  const handleProductClick = (type: string) => {
    updateFilter(type, "products");
  };

  const handleTextChange = (text: string) => {
    const x = { ...selections };
    x.text = text;
    setSelections(x);
  };

  const handleBusinessTypeClick = (type: number) => {
    const x = { ...selections };
    x.businessType = type;
    setSelections(x);
  };

  const updateFilter = (type: string, key: "commodities" | "products") => {
    const x = { ...selections };
    x[key] = x[key].includes(type) ? x[key].filter((t: string) => t !== type) : [...x[key], type];
    setSelections(x);
  };

  const onClearClick = (e: any) => {
    e.preventDefault();
    setSelections(EMPTY_PRODUCT_FILTERS);
  };

  return (
    <>
      <Tooltip anchorElement="target" parentTitle={true}>
        {businessTypeOptions.length > 1 && (
          <div>
            <RadioGroup
              layout={"horizontal"}
              data={businessTypeOptions}
              onChange={(e) => handleBusinessTypeClick(e.value)}
              value={selections.businessType}
            />
          </div>
        )}

        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: 15 }}>
          {commodityTypes && Object.keys(commodityTypes).length > 1 && (
            <div>
              {Object.keys(commodityTypes).map((type: string, index) => {
                const title = commodityTypes[type];

                return (
                  <IconToggleButton
                    key={index}
                    item={type}
                    type={"commodity"}
                    title={title}
                    hoveredItem={hoveredCommodity}
                    selectedItemTypes={selections.commodities}
                    onClick={handleCommodityClick}
                    setHoveredItem={setHoveredCommodity}
                  />
                );
              })}
            </div>
          )}

          {productTypes && Object.keys(productTypes).length > 1 && (
            <div>
              {Object.keys(productTypes).map((key: any, index) => {
                const title = productTypes[key];

                return (
                  <IconToggleButton
                    key={index}
                    item={key}
                    type={"instrument"}
                    title={title}
                    hoveredItem={hoveredProduct}
                    selectedItemTypes={selections.products}
                    onClick={handleProductClick}
                    setHoveredItem={setHoveredProduct}
                  />
                );
              })}
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexBasis: "20%",
              alignItems: "center",
              columnGap: 5,
            }}
          >
            <Label>Search</Label>
            <Input
              value={selections.text}
              onChange={(e: any) => handleTextChange(e.target.value)}
              style={{ minWidth: 200 }}
            />
          </div>

          <div>
            <Button
              size="small"
              themeColor="primary"
              fillMode="outline"
              title="Clear Filters"
              onClick={onClearClick}
            >
              <span className="k-icon k-i-filter-clear"></span>
            </Button>
          </div>
        </div>
      </Tooltip>
    </>
  );
};
