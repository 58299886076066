import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import configApi from "../../../api/configuration";
import contractsApi from "../../../api/contracts";
import { getStructures } from "../../../api/vanilla";
import { useApi, useFetch } from "../../../hooks/useApi";
import DateUtility from "../../../utilities/dateUtilities";
import { SETTLEMENT_TYPES } from "../../../utilities/vanilla/vanillaData";
import { SettlementType } from "../../../utilities/vanilla/vanillaEnums";
import { requiredDateRangePickerValidator, requiredValidator } from "../../../validators";
import { LoadingIndicator } from "../../LoadingIndicator";
import { FormDateRangePicker, FormInput } from "../../form";
import { FormChipList } from "../../form/FormChipList";

type Props = {
  formRenderProps: FormRenderProps;
  product: any;
};

const StartVanillaQuote = ({ formRenderProps, product }: Props) => {
  const { commodity, instrument, company } = product;
  const { calendarMonthOffset } = instrument;
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);

  const getContractDatesApi = useApi(contractsApi.getDateBoundaries);
  const getConfigurationApi = useApi(configApi.getConfiguration);
  const { data: structuresData, loading: structuresLoading } = useFetch(
    getStructures,
    instrument.id,
  );
  const structures =
    structuresData?.structures.map((structure: any) => {
      return {
        text: structure.longName,
        value: structure.structureId,
        disabled: !structure.isActive,
      };
    }) || [];

  useEffect(() => {
    getConfigurationApi.request(company.id, commodity.id, instrument.id, instrument.productType);
  }, []);

  useEffect(() => {
    const data = getContractDatesApi.data;
    if (data) {
      const { minDate, maxDate } = data;
      let newMinDate = null,
        newMaxDate = null;
      if (minDate) {
        newMinDate = DateUtility.convertToDateWithOffset(data.minDate, -1 * calendarMonthOffset);
      }
      if (maxDate) {
        newMaxDate = DateUtility.convertToDateWithOffset(data.maxDate, -1 * calendarMonthOffset);
      }
      setMinDate(newMinDate);
      setMaxDate(newMaxDate);
    }
  }, [getContractDatesApi.data]);

  useEffect(() => {
    const configId = getConfigurationApi.data?.configurationId || 0;
    formRenderProps.onChange("configurationId", { value: configId });
    getContractDatesApi.request(
      commodity?.abbreviation,
      configId,
      instrument?.name?.toLowerCase() === "swap",
    );
  }, [getConfigurationApi.data]);

  useEffect(() => {
    const loading = getContractDatesApi.loading || getConfigurationApi.loading || structuresLoading;
    formRenderProps.onChange("loaded", { value: !loading });
  }, [getContractDatesApi.loading, getConfigurationApi.loading, structuresLoading]);

  const validateDateRange = (value: SelectionRange) => {
    const validationMessage = requiredDateRangePickerValidator(value);
    if (validationMessage) return validationMessage;

    if (
      (minDate && value.start && value.start < minDate) ||
      (maxDate && value.end && value.end > maxDate)
    ) {
      return `Please select dates between ${DateUtility.formatDateMY(
        minDate || "",
      )} and ${DateUtility.formatDateMY(maxDate || "")}`;
    }
  };

  const settlementTypes = SETTLEMENT_TYPES.map((item) => {
    const isDaily = item.value === SettlementType.Daily;
    const disabled = isDaily && !commodity.hasDailySettlement;

    return { ...item, disabled };
  });

  useEffect(() => {
    if (!commodity.hasDailySettlement) {
      formRenderProps.onChange("settlement", { value: SettlementType.Monthly });
    }
  }, []);

  const isSwap = formRenderProps.valueGetter("structureId") === 17;
  useEffect(() => {
    if (isSwap) {
      formRenderProps.onChange("commonStrike", { value: false });
    }
  }, [isSwap]);

  const dateLabel = calendarMonthOffset === 0 ? "Contract" : "Calendar";

  return (
    <div className="stepper-form" style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "60%" }}>
        <Field
          name={"quoteName"}
          label={"Quote Name"}
          component={FormInput}
          validator={requiredValidator}
        />
        <LoadingIndicator loading={getContractDatesApi.loading} />
        {getContractDatesApi.error && <Error>{getContractDatesApi.error}</Error>}
        {!getContractDatesApi.loading && minDate && maxDate && (
          <Field
            name={"contract"}
            label={dateLabel}
            component={FormDateRangePicker}
            startDateInputSettings={{ label: "" }}
            endDateInputSettings={{ label: "" }}
            calendarSettings={{ bottomView: "year", topView: "decade" }}
            min={minDate}
            max={maxDate}
            format={"MMM yyyy"}
            validator={validateDateRange}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Field
            name="structureId"
            label="Product"
            component={FormChipList}
            data={structures}
            selection="single"
            validator={requiredValidator}
          />
          <Field
            name="settlement"
            label="Settlement"
            component={FormChipList}
            selection="single"
            data={settlementTypes}
            validator={requiredValidator}
          />
        </div>
      </div>
    </div>
  );
};

export default StartVanillaQuote;
