import { Switch } from "@progress/kendo-react-inputs";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Error } from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import quotesApi from "../../api/quotes";
import { useFetch } from "../../hooks/useApi";
import MathUtility from "../../utilities/mathUtilities";
import { LoadingIndicator } from "../LoadingIndicator";

interface IFeeValues {
  feeName: string;
  companyName: string;
  companyRole: string;
  values: Record<string, number>;
  total: number;
}

interface IMarginsDetails {
  quoteFees: IFeeValues[];
  total: number;
  minimumFee: number;
}

interface IMarginsSummaryProps {
  quoteId: number;
  pricingId: number;
  fairValue?: number;
  totalVolume?: number;
  unitPriceDecimals?: number;
  units: string;
  quote: any;
  clientLong?: boolean;
}

export const MarginsSummary = ({
  quoteId,
  quote,
  pricingId,
  fairValue,
  totalVolume = 1,
  unitPriceDecimals: decimals = 2,
  units,
  clientLong = true,
}: IMarginsSummaryProps) => {
  const formatter = useInternationalization();
  const headerStyle = { fontWeight: "bold" };
  const totalStyle = { borderTop: "double" };
  const adjustmentStyle = { fontWeight: "bold", color: "#4cbb17" };

  const { data, loading, error } = useFetch(quotesApi.getMarginsDetails, quoteId, pricingId);
  const details = data as IMarginsDetails;

  const [quoteFees, setQuoteFees] = useState<IFeeValues[]>([]);

  useEffect(() => {
    var fees =
      details?.quoteFees.map((fee) => {
        const newFee = { ...fee };
        if (!clientLong) newFee.total = -newFee.total;
        return newFee;
      }) ?? [];

    setQuoteFees(fees);
  }, [clientLong, details]);

  const totalFees = (quoteFees ?? []).reduce(
    (sum: number, current: any) => sum + MathUtility.round(current.total || 0, 2),
    0,
  );

  const totalPrice = (fairValue || 0) + totalFees;

  const { approvedPremium, approvedBrokerPremium, submittedPremium, submittedBrokerPremium } =
    quote;

  const isInsurance = quote.productType === "Insurance";

  const showAdjustments = approvedPremium !== null && !isInsurance;

  let brokerAdjustment = null,
    riskTakerAdjustment = null;

  if (showAdjustments) {
    brokerAdjustment =
      approvedPremium - approvedBrokerPremium - submittedPremium + submittedBrokerPremium;

    riskTakerAdjustment = approvedBrokerPremium - submittedBrokerPremium;

    brokerAdjustment = MathUtility.round(brokerAdjustment, 2);
    riskTakerAdjustment = MathUtility.round(riskTakerAdjustment, 2);
  }

  const companies =
    details?.quoteFees.map((f: IFeeValues) => {
      return { name: f.companyName, role: f.companyRole };
    }) ?? [];

  const uniqueCompanies = Array.from(new Set(companies.map((a) => a.name)))
    .map((name) => {
      return companies.find((a) => a.name === name);
    })
    .sort((a: any, b: any) => (a.role > b.role ? -1 : 1));

  const [showUnitPrices, setShowUnitPrices] = useState(false);
  const [format, setFormat] = useState("");
  const [factor, setFactor] = useState(1);

  useEffect(() => {
    setFormat(showUnitPrices ? `n${decimals}` : "c2");
    setFactor(showUnitPrices ? totalVolume : 1);
  }, [decimals, showUnitPrices, totalVolume]);

  let rowIndex = 1;

  const renderFees = (company: any) => {
    const fees = quoteFees.filter((f) => f.companyName === company.name);
    const showMinFee =
      details.total < details.minimumFee && company.role === "RiskTaker" && isInsurance;

    return (
      <>
        {fees.map((fee, feeIndex) => (
          <>
            <GridLayoutItem col={2} row={rowIndex} key={`c2_${feeIndex + 2}`}>
              <span>{feeIndex === 0 ? fee.companyName : ""}</span>
            </GridLayoutItem>

            <GridLayoutItem col={3} row={rowIndex} key={`c3_${feeIndex + 2}`}>
              <span>{fee.feeName}</span>
            </GridLayoutItem>

            <GridLayoutItem
              col={4}
              row={rowIndex++}
              key={`c4_${feeIndex + 2}`}
              className="k-text-right"
            >
              <span>{formatter.formatNumber(fee.total / factor, format)}</span>
            </GridLayoutItem>
          </>
        ))}

        {showMinFee && (
          <>
            <GridLayoutItem col={5} row={rowIndex - 2} key={"minFeeLabel"} className="k-text-right">
              <span style={headerStyle}>Minimum Fee</span>
            </GridLayoutItem>

            <GridLayoutItem col={5} row={rowIndex - 1} key={"minFeeValue"} className="k-text-right">
              <span style={headerStyle}>{formatter.formatNumber(details.minimumFee, "c2")}</span>
            </GridLayoutItem>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <LoadingIndicator loading={loading} />
      <Error>{error}</Error>

      {details && !error && (
        <GridLayout
          style={{ marginBottom: "1rem" }}
          align={{ vertical: "top" }}
          gap={{ rows: 0, cols: 5 }}
          cols={[
            { width: "10%" },
            { width: "20%" },
            { width: "25%" },
            { width: "10%" },
            { width: "25%" },
            { width: "10%" },
          ]}
        >
          <GridLayoutItem col={4} row={rowIndex++} key={"amount"} className="k-text-right">
            <span style={headerStyle}>Total</span>
            {showUnitPrices && <div style={{ fontStyle: "italic" }}>{` ($/${units})`}</div>}
          </GridLayoutItem>

          {!!(fairValue && fairValue > 0) && (
            <>
              <GridLayoutItem col={3} row={rowIndex} key={"fv_label"}>
                <span>Fair Value</span>
              </GridLayoutItem>
              <GridLayoutItem col={4} row={rowIndex++} key={"fv"} className="k-text-right">
                <span>{formatter.formatNumber(fairValue / factor, format)}</span>
              </GridLayoutItem>
            </>
          )}

          {uniqueCompanies.map((com, i) => (
            <>
              <GridLayoutItem col={2} row={rowIndex++} key={"h2"}>
                <span style={headerStyle}>{com?.role} Fees</span>
              </GridLayoutItem>

              {renderFees(com)}
            </>
          ))}

          <GridLayoutItem col={3} row={rowIndex} key="total_label">
            <span style={headerStyle}>Submitted Price</span>
          </GridLayoutItem>
          <GridLayoutItem
            col={4}
            row={rowIndex}
            key={"total"}
            className="k-text-right"
            style={totalStyle}
          >
            <span>{formatter.formatNumber(totalPrice / factor, format)}</span>
          </GridLayoutItem>

          {showAdjustments && (
            <>
              <GridLayoutItem col={3} row={++rowIndex} key="bAdj_label">
                <span style={adjustmentStyle}>Broker Fee Adjustment</span>
              </GridLayoutItem>
              <GridLayoutItem
                col={4}
                row={rowIndex}
                key={"bAdj"}
                className="k-text-right"
                style={adjustmentStyle}
              >
                <span>{formatter.formatNumber((brokerAdjustment as number) / factor, format)}</span>
              </GridLayoutItem>
              <GridLayoutItem col={3} row={++rowIndex} key="rtAdj_label">
                <span style={adjustmentStyle}>Risk Taker Adjustment</span>
              </GridLayoutItem>
              <GridLayoutItem
                col={4}
                row={rowIndex}
                key={"rtAdj"}
                className="k-text-right"
                style={adjustmentStyle}
              >
                <span>
                  {formatter.formatNumber((riskTakerAdjustment as number) / factor, format)}
                </span>
              </GridLayoutItem>
              <GridLayoutItem col={3} row={++rowIndex} key="sPrice_label">
                <span style={adjustmentStyle}>Approved Price</span>
              </GridLayoutItem>
              <GridLayoutItem
                col={4}
                row={rowIndex}
                key={"sPrice"}
                className="k-text-right"
                style={{ ...adjustmentStyle, ...totalStyle }}
              >
                <span>{formatter.formatNumber((approvedPremium as number) / factor, format)}</span>
              </GridLayoutItem>
            </>
          )}

          <GridLayoutItem col={4} row={1} colSpan={2} key="switch">
            <div style={{ display: "flex", justifyContent: "right", paddingBottom: "1rem" }}>
              <span style={{ marginRight: 5 }}>Unit Prices</span>
              <Switch
                size={"small"}
                checked={showUnitPrices}
                onChange={() => setShowUnitPrices(!showUnitPrices)}
              />
            </div>
          </GridLayoutItem>
        </GridLayout>
      )}
    </>
  );
};
