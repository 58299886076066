export const TRANSACTION_DIRECTIONS = [
  { text: "Buying", value: 1 },
  { text: "Selling", value: 2 },
];

export const SETTLEMENT_TYPES = [
  { text: "Daily", value: 1 },
  { text: "Monthly", value: 2 },
];

export const STRIKE_TYPES = [
  { text: "Fixed", value: "Fixed" },
  { text: "Relative", value: "Adder" },
];
