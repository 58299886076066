import { useEffect, useState } from "react";
import allocationApi from "../api/allocation";
import productsApi from "../api/products";
import Allocation from "../components/admin/Allocation";
import { useApi, useFetch } from "./useApi";
import { useUserContext } from "./useUserContext";

export default function useAllocations() {
  const { context: user } = useUserContext();
  const {
    activeCompany: { id: companyId },
  } = user;

  const upsertAllocationApi = useApi(allocationApi.upsertAllocation);
  const deleteAllocationApi = useApi(allocationApi.deleteAllocation);

  useEffect(() => {
    if (deleteAllocationApi.data) {
      getAllocationsApi.request(companyId);
    }
  }, [deleteAllocationApi.data]);

  useEffect(() => {
    if (upsertAllocationApi.data) {
      getAllocationsApi.request(companyId);
    }
  }, [upsertAllocationApi.data]);

  const getAllocationsApi = useFetch(allocationApi.getAllocations, companyId);
  const businessTypesApi = useFetch(productsApi.getBusinessTypesByCompany, companyId);
  const commodityTypesApi = useFetch(productsApi.getCommodityTypesByCompany, companyId);

  const [editableAllocations, setEditableAllocations] = useState<Allocation[]>([]);

  const isLoading = getAllocationsApi.loading || businessTypesApi.loading || commodityTypesApi.loading;

  const isError = getAllocationsApi.error || businessTypesApi.error || commodityTypesApi.error;

  const businessTypes = businessTypesApi.data?.businessTypes || {};
  const commodityTypes = commodityTypesApi.data?.commodityTypes || {};
  let allocations = getAllocationsApi.data?.allocations || [];

  const allBusinessTypes = Object.entries(businessTypes).map(([key, value]) => {
    return { text: value as string, value: parseInt(key as string) };
  });

  const allCommodityTypes = Object.entries(commodityTypes).map(([key, value]) => {
    return { text: value as string, value: key as string };
  });

  allocations = allocations.map((allocation: Allocation) => {
    const allocationBusinessTypes = allocation.businessTypeIds.map((businessTypeId: number) => {
      return { value: businessTypeId, text: businessTypes[businessTypeId] };
    });

    const allocationCommodityTypes = allocation.commodityTypes.map((commodityType: string) => {
      return { value: commodityType, text: commodityType };
    });

    const row = {
      ...allocation,
      ...allocation.values,
      businessTypes: allocationBusinessTypes,
      commodityTypeObjects: allocationCommodityTypes,
    };

    return row;
  });

  const initialEditableAllocations = allocations
    .filter((allocation: Allocation) => allocation.companyId === companyId)
    .sort((a: Allocation, b: Allocation) => a.name.localeCompare(b.name));

  const readOnlyAllocations = allocations
    .filter((allocation: Allocation) => allocation.companyId !== companyId)
    .sort((a: Allocation, b: Allocation) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (!isLoading) {
      setEditableAllocations(initialEditableAllocations);
    }
  }, [isLoading]);

  return {
    initialEditableAllocations,
    editableAllocations,
    setEditableAllocations,
    upsertAllocation: upsertAllocationApi.request,
    deleteAllocation: deleteAllocationApi.request,
    readOnlyAllocations,
    businessTypes: allBusinessTypes,
    commodityTypes: allCommodityTypes,
    companyId,
    isLoading,
    isError,
  };
}
