import { addDays } from "@progress/kendo-date-math";
import { DateRangePickerChangeEvent, SelectionRange } from "@progress/kendo-react-dateinputs";
import { Error } from "@progress/kendo-react-labels";
import { TileLayout } from "@progress/kendo-react-layout";
import { memo, useEffect, useState } from "react";
import { QuotesGrid } from ".";
import productsApi from "../../api/products";
import quotesApi from "../../api/quotes";
import { useApi, useFetch } from "../../hooks/useApi";
import { filterObjectByKeys } from "../../utilities/objectUtilities";
import { LoadingIndicator } from "../LoadingIndicator";
import { QuotesByDateChart, QuotesChartFilter, QuotesDonutChart } from "../charts";
import { FilterComponent } from "../quotes";

const Quotes = () => {
  const today = new Date();
  const defaultDates = { start: addDays(today, -14), end: today };
  const [dates, setDates] = useState<SelectionRange>(defaultDates);

  const getQuotesApi = useApi(quotesApi.getSummary);
  const getCommoditiesApi = useFetch(productsApi.getCommodities);
  const getInstrumentsApi = useFetch(productsApi.getInstruments);
  const {
    data: comTypesData,
    loading: comTypesLoading,
    error: comTypesError,
  } = useFetch(productsApi.getCommodityTypes);
  const {
    data: prodTypesData,
    loading: prodTypesLoading,
    error: prodTypesError,
  } = useFetch(productsApi.getProductTypes);

  useEffect(() => {
    getQuotesApi.request(dates);
  }, [dates]);

  const [tileData, setTileData] = useState<Array<any>>([
    { row: 1, col: 1, colSpan: 4, rowSpan: 1 }, // Filters
    { row: 2, col: 1, colSpan: 12, rowSpan: 2 }, // Grid
    { row: 1, col: 5, colSpan: 8 }, // Chart
  ]);

  const [quotesData, setQuotesData] = useState<any[]>([]);
  const [gridData, setGridData] = useState<any[]>([]);
  const [chartFilter, setChartFilter] = useState<any>({
    category: "Commodity",
    value: "Quotes",
  });
  const [commodityTypes, setCommodityTypes] = useState<any>({});
  const [productTypes, setProductTypes] = useState<any>({});
  const [selectedCommodities, setSelectedCommodities] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setQuotesData(getQuotesApi.data?.quotes ?? []);
  }, [getQuotesApi.data]);

  useEffect(() => {
    if (comTypesData?.commodityTypes) {
      const keys = new Set<string>(quotesData.map((q) => q.commodityType));
      setCommodityTypes(filterObjectByKeys(comTypesData.commodityTypes, [...keys]));
    }
  }, [quotesData, comTypesData]);

  useEffect(() => {
    if (prodTypesData?.productTypes) {
      const keys = new Set<string>(quotesData.map((q) => q.productType));
      setProductTypes(filterObjectByKeys(prodTypesData.productTypes, [...keys]));
    }
  }, [quotesData, prodTypesData]);

  useEffect(() => {
    const filtered = quotesData
      .filter((q) => selectedProducts.length === 0 || selectedProducts.includes(q.productType))
      .filter(
        (q) => selectedCommodities.length === 0 || selectedCommodities.includes(q.commodityType),
      )
      .filter((q) => selectedStatuses.length === 0 || selectedStatuses.includes(q.status))
      .filter(
        (q) =>
          searchText.length === 0 ||
          q.companyName.toLowerCase().includes(searchText) ||
          q.quoteName.toLowerCase().includes(searchText) ||
          q.tickerCode.toLowerCase().includes(searchText) ||
          q.structure.toLowerCase().includes(searchText) ||
          q.quoteId.toString().includes(searchText) ||
          q.quotedBy.toLowerCase().includes(searchText),
      );

    filtered.forEach((f) => {
      const com = getCommoditiesApi.data?.commodities.find((i: any) => i.id == f.commodityId);
      const inst = getInstrumentsApi.data?.instruments.find((i: any) => i.id == f.instrumentId);
      f.instrumentName = inst?.name;
      f.commodityName = com?.name;
    });

    setGridData(filtered);
  }, [quotesData, selectedCommodities, selectedProducts, selectedStatuses, searchText]);

  const onDataChanged = (result: any) => {
    if (!result?.data) return;

    //Placeholder in case we want to handle data state changes
  };

  const onRefresh = () => {
    getQuotesApi.request(dates);
  };

  const handleDateChange = ({ value }: DateRangePickerChangeEvent) => {
    setDates(value);
  };

  const handleCommodityClick = (type: string) => {
    if (selectedCommodities.includes(type))
      setSelectedCommodities(selectedCommodities.filter((t) => t !== type));
    else setSelectedCommodities([...selectedCommodities, type]);
  };

  const handleProductClick = (type: string) => {
    if (selectedProducts.includes(type))
      setSelectedProducts(selectedProducts.filter((t) => t !== type));
    else setSelectedProducts([...selectedProducts, type]);
  };

  const handleStatusCheck = (e: any, value: string) => {
    let list: string[] = [...selectedStatuses];
    if (e.value) list.push(value);
    else list.splice(list.indexOf(value), 1);
    setSelectedStatuses(list);
  };

  const handleTextChange = (value: string) => setSearchText(value ? value.toLowerCase() : "");

  const handleClearFilters = (e: any) => {
    setSelectedCommodities([]);
    setSelectedProducts([]);
    setSelectedStatuses([]);
    setDates(defaultDates);
    setSearchText("");
  };

  const tiles = [
    {
      id: "quotesFilter",
      item: (
        <div style={{ margin: "1rem" }}>
          <LoadingIndicator loading={prodTypesLoading || comTypesLoading} />
          {comTypesError && <Error>{comTypesError}</Error>}
          {prodTypesError && <Error>{prodTypesError}</Error>}
          {!(prodTypesLoading || comTypesLoading) && (
            <FilterComponent
              maxDate={today}
              commodityTypes={commodityTypes}
              productTypes={productTypes}
              selectedDates={dates}
              selectedCommodityTypes={selectedCommodities}
              selectedProducts={selectedProducts}
              selectedStatuses={selectedStatuses}
              searchText={searchText}
              onDateChange={handleDateChange}
              onCommodityClick={handleCommodityClick}
              onProductClick={handleProductClick}
              onStatusCheck={handleStatusCheck}
              onClearFilters={handleClearFilters}
              onTextChange={handleTextChange}
            />
          )}
        </div>
      ),
      reorderable: false,
      resizable: false,
    },
    {
      id: "quotesGrid",
      item: (
        <div style={{ margin: "1rem" }}>
          <LoadingIndicator loading={getQuotesApi.loading} />
          {getQuotesApi.error && <Error>{getQuotesApi.error}</Error>}
          {!getQuotesApi.loading && getCommoditiesApi.data?.commodities && (
            <QuotesGrid
              quotes={gridData}
              commodities={getCommoditiesApi.data?.commodities}
              onDataChange={onDataChanged}
              onRefresh={onRefresh}
            />
          )}
        </div>
      ),
      reorderable: false,
      resizable: false,
    },
    {
      id: "quotesCharts",
      item: (
        <div style={{ margin: "1rem" }}>
          <LoadingIndicator loading={getQuotesApi.loading} />
          {gridData?.length > 0 && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h5 className="k-card-title">
                    {chartFilter.value} by {chartFilter.category}
                  </h5>
                </div>
                <div>
                  <QuotesChartFilter selected={chartFilter} onChange={setChartFilter} />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ flexBasis: "40%" }}>
                  <QuotesDonutChart
                    data={gridData}
                    selectedCategory={chartFilter.category}
                    selectedValue={chartFilter.value}
                  />
                </div>
                <div style={{ flexBasis: "60%" }}>
                  <QuotesByDateChart
                    data={gridData}
                    selectedCategory={chartFilter.category}
                    selectedValue={chartFilter.value}
                    dateRange={dates}
                  />
                </div>
              </div>
            </>
          )}
          {!getQuotesApi.loading && !gridData?.length && <h6>No chart data to display</h6>}
        </div>
      ),
      reorderable: false,
      resizable: false,
    },
  ];

  return (
    <TileLayout
      columns={12}
      positions={tileData}
      gap={{ rows: 5, columns: 5 }}
      items={tiles}
      dataItemKey={"id"}
    />
  );
};

export default memo(Quotes);
