import { parseDate } from "@progress/kendo-intl";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import configApi from "../../../api/configuration";
import contractsApi from "../../../api/contracts";
import { useApi } from "../../../hooks/useApi";
import DateUtility from "../../../utilities/dateUtilities";
import { requiredDateRangePickerValidator, requiredValidator } from "../../../validators";
import { LoadingIndicator } from "../../LoadingIndicator";
import { FormDateRangePicker, FormInput } from "../../form";

interface IInsuranceStartProps {
  formRenderProps: FormRenderProps;
  product: any;
}

export const StartQuote = ({ formRenderProps, product }: IInsuranceStartProps) => {
  const { commodity, company, instrument } = product;

  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);

  const getContractDatesApi = useApi(contractsApi.getDateBoundaries);
  const getConfigurationApi = useApi(configApi.getConfiguration);

  useEffect(() => {
    getConfigurationApi.request(company.id, commodity.id, instrument.id, instrument.productType);
  }, []);

  useEffect(() => {
    setMinDate(parseDate(getContractDatesApi.data?.minDate || ""));
    setMaxDate(parseDate(getContractDatesApi.data?.maxDate || ""));
  }, [getContractDatesApi.data]);

  useEffect(() => {
    const configurationId = getConfigurationApi.data?.configurationId || 0;
    formRenderProps.onChange("configurationId", { value: configurationId });
    getContractDatesApi.request(commodity?.abbreviation, configurationId);
  }, [getConfigurationApi.data]);

  const validateDateRange = (value: SelectionRange) => {
    const validationMessage = requiredDateRangePickerValidator(value);
    if (validationMessage) return validationMessage;

    if (
      (minDate && value.start && value.start < minDate) ||
      (maxDate && value.end && value.end > maxDate)
    ) {
      return `Please select dates between ${DateUtility.formatDateMY(minDate || "")} and ${DateUtility.formatDateMY(maxDate || "")}`;
    }
  };

  return (
    <div className="stepper-form" style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "60%" }}>
        <Field
          key={"quoteName"}
          id={"quoteName"}
          name={"quoteName"}
          label={"Quote Name"}
          component={FormInput}
          validator={requiredValidator}
        />

        <LoadingIndicator loading={getContractDatesApi.loading} />
        {getContractDatesApi.error && <Error>{getContractDatesApi.error}</Error>}
        {!getContractDatesApi.loading && minDate && maxDate && (
          <Field
            key={"contract"}
            id={"contract"}
            name={"contract"}
            label={"Contract"}
            component={FormDateRangePicker}
            startDateInputSettings={{ label: "" }}
            endDateInputSettings={{ label: "" }}
            calendarSettings={{ bottomView: "year", topView: "decade" }}
            min={minDate}
            max={maxDate}
            format={"MMM yyyy"}
            validator={validateDateRange}
          />
        )}
      </div>
    </div>
  );
};
